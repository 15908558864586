<template>
    <div id="botLink">
        <div v-if="false" class="linkType">接入渠道</div>
        <!-- 企业微信解绑对话框 -->
        <el-dialog :title="$t('botLink.unBindPrompt')" :visible.sync="dialogVisible" width="30%">
            <span
                >{{$t('botLink.unBindTip')}}</span
            >
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
                <el-button type="danger" @click="postUnbind(bindId)"
                    >{{$t('common.confirm')}}</el-button
                >
            </span>
        </el-dialog>
        <!-- 查看已绑定企业飞书 -->
        <el-dialog :title="$t('botLink.boundToEnterprise')" :close-on-click-modal="false" :visible.sync="feishuInfoDialogVisible">
            <el-form ref="”bindForm“" :rules="bindRules" :model="feishuBindForm">
                <el-form-item :label="$t('botLink.enterpriseName')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        v-model="feishuBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('botLink.enterpriseID')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                        v-model="feishuBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.agentNamePla')"
                        v-model="feishuBindForm.agentName"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="feishuInfoDialogVisible = false">{{$t('common.close')}}</el-button>
            </div>
        </el-dialog>
        <!-- 查看已绑定企业微信企业对话框 -->
        <el-dialog :title="$t('botLink.boundToEnterprise')" :close-on-click-modal="false" :visible.sync="infoDialogVisible">
            <el-form ref="”bindForm“" :rules="bindRules" :model="bindForm">
                <el-form-item :label="$t('botLink.enterpriseName')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        v-model="bindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('botLink.enterpriseID')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                        v-model="bindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="bindForm.agentId"
                        :placeholder="$t('botLink.agentIdPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.agentNamePla')"
                        v-model="bindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.secretPla')"
                        v-model="bindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="infoDialogVisible = false">{{$t('common.close')}}</el-button>
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉企业对话框 -->
        <el-dialog
            style="overflow-y: auto"
            :title="$t('botLink.boundToEnterprise')"
            :close-on-click-modal="false"
            :visible.sync="dingdinInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingBindForm"
            >
                <el-form-item :label="$t('botLink.enterpriseName')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        v-model="dingdingBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('botLink.enterpriseID')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                        v-model="dingdingBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingBindForm.agentId"
                        :placeholder="$t('botLink.agentIdPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.agentNamePla')"
                        v-model="dingdingBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.appKeyPla')"
                        v-model="dingdingBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.secretPla')"
                        v-model="dingdingBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinInfoDialogVisible = false"
                    >{{$t('common.close')}}</el-button
                >
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉群对话框 -->
        <!-- <el-dialog
            style="overflow-y: auto"
            title="已绑定信息"
            :close-on-click-modal="false"
            :visible.sync="dingdinGroupInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingGroupBindForm"
            >
                <el-form-item label="企业名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业名称"
                        v-model="dingdingGroupBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="企业ID">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入企业ID"
                        v-model="dingdingGroupBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingGroupBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                </el-form-item>
                <el-form-item label="机器人名称">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入机器人名称"
                        v-model="dingdingGroupBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入AppKey"
                        v-model="dingdingGroupBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        placeholder="请输入Secret"
                        v-model="dingdingGroupBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinGroupInfoDialogVisible = false"
                    >关 闭</el-button
                >
            </div>
        </el-dialog> -->
        <!-- 查看已绑定公众号对话框 -->
        <el-dialog :title="$t('botLink.detailsOfOfficialAccount')" :close-on-click-modal="false" :visible.sync="wechatMpShowDialogVisible">
            <el-form
                ref="”bindForm“"
                :rules="bindRules"
                :model="wechatMpBindForm"
            >
                <el-form-item :label="$t('botLink.officialAccountName')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.namePla')"
                        v-model="wechatMpBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppID">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.appIdPla')"
                        v-model="wechatMpBindForm.agentId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppSecret">
                    <el-input
                        disabled="disabled"
                        v-model="wechatMpBindForm.secret"
                        :placeholder="$t('botLink.appSecretPla')"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item label="授权URL">
          <el-input disabled="disabled" v-model="wechatMpBindForm.authUrl" placeholder="授权URL"></el-input>
        </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="wechatMpShowDialogVisible = false"
                    >{{$t('common.close')}}</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            :title="$t('botLink.authorizationTitle')"
            :close-on-click-modal="false"
            :visible.sync="wechatMpAuthDialog"
            width="30%"
        >
            <div class="authType">
                <div class="authTypeItem" @click="selectAuthType(0, $event)">
                    <img src="../../assets/images/u20440.png" />
                    <p>{{$t('botLink.thirdPartyAuthorization')}}</p>
                </div>
                <div class="authTypeItem" @click="selectAuthType(1)">
                    <img src="../../assets/images/u20444.png" />
                    <p>{{$t('botLink.developerAuthorization')}}</p>
                </div>
            </div>
        </el-dialog>
        <!-- 开发者授权微信公众号 -->
        <el-dialog :title="$t('botLink.authorizedWeChatOfficialAccount')" :close-on-click-modal="false" :visible.sync="developerDialog">
            <el-form
                ref="”bindForm“"
                :rules="bindRules"
                :model="wechatMpBindForm"
            >
                <el-form-item :label="$t('botLink.officialAccountName')">
                    <el-input
                        :placeholder="$t('botLink.namePla')"
                        v-model="wechatMpBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppID">
                    <el-input
                        :placeholder="$t('botLink.appIdPla')"
                        v-model="wechatMpBindForm.agentId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppSecret">
                    <el-input
                        v-model="wechatMpBindForm.secret"
                        :placeholder="$t('botLink.appSecretPla')"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="developerDialog = false">{{$t('common.close')}}</el-button>
                <el-button @click="bindWechatBySecret">{{$t('common.confirm')}}</el-button>
            </div>
        </el-dialog>
        <!--添加网站信息-->
        <el-dialog :title="$t('botLink.addWebsiteInformation')" :close-on-click-modal="false" :visible.sync="addWebsiteDialogVisible">
            <el-form
                ref="”bindForm“"
                :rules="bindRules"
                :model="websiteBindForm"

            >
                <el-form-item label="网站名称">
                    <span slot="label">
                        <span>{{$t('botLink.websiteName')}}</span>
                        <span class="red" style="margin-left:3px;color: red;">*</span>
                    </span>
                    <el-input
                        :placeholder="$t('botLink.websiteNamePla')"
                        v-model="websiteBindForm.websiteName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="网站域名">
                    <span slot="label">
                        <span>{{$t('botLink.websiteDomainName')}}</span>
                        <span class="red" style="margin-left:3px;color: red;">*</span>
                    </span>
                    <el-input
                        :placeholder="$t('botLink.websiteDomainNamePla')"
                        v-model="websiteBindForm.domain"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addWebsiteDialogVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" @click="addWebsiteBindV2()"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </el-dialog>
        <!-- 绑定企业对话框 -->
        <el-dialog
            :title="$t('botLink.bindEnterprise')"
            :visible.sync="dialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form ref="”bindForm“" :rules="bindRules" :model="bindForm">
                <el-form-item
                    :label="$t('botLink.bindEnterpriseName')"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="bindForm.corpName"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    :label="$t('botLink.corpIdTip')"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="bindForm.corpId"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    :label="$t('botLink.agentIdLabel')"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="bindForm.agentId"
                        :placeholder="$t('botLink.agentIdPla')"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*{{$t('botLink.agentIdTip')}}</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="bindForm.secret"
                        :placeholder="$t('botLink.secretPla')"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="getAuthUrl()"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </el-dialog>
        <!-- 钉钉群绑定钉钉及自建机器人 -->
        <!-- <el-dialog
            title="绑定钉钉及自建机器人"
            :visible.sync="dingdinggroupFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingGroupBindRules"
                :model="dingdingGroupBindForm"
            >
                <el-form-item
                    label="填写要绑定的企业名称"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.corpName"
                        placeholder="请输入企业名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    label="在钉钉管理后台【首页】中找到企业ID，填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.corpId"
                        placeholder="请输入企业ID"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="填写要绑定的机器人名称"
                    :label-width="bindingformLabelWidth"
                    prop="agentName"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.agentName"
                        placeholder="请输入机器人名称"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    label="在钉钉管理后台【应用开发】->【机器人】中点击要绑定的自建机器人，点击自建机器人可见到AgentID、AppKey和AppSecret，分别填入下方输入框中"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.agentId"
                        placeholder="请输入AgentID"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*该应用已绑定，不能重复绑定</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.appKey"
                        placeholder="请输入AppKey"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingGroupBindForm.secret"
                        placeholder="请输入Secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinggroupFormVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingdingGroup()"
                    >确 定</el-button
                >
            </div>
        </el-dialog> -->
        <!-- 钉钉绑定企业对话框 -->
        <el-dialog
            :title="$t('botLink.bindEnterprise')"
            :visible.sync="dingdingDialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingBindForm"
            >
                <el-form-item
                    :label="$t('botLink.bindEnterpriseName')"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="dingdingBindForm.corpName"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    :label="$t('botLink.bindDingCorpIdTip')"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingBindForm.corpId"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('botLink.appName')"
                    :label-width="bindingformLabelWidth"
                    prop="agentName"
                >
                    <el-input
                        v-model="dingdingBindForm.agentName"
                        :placeholder="$t('botLink.appNamePla')"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    :label="$t('botLink.dingAgentIdTip')"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingBindForm.agentId"
                        :placeholder="$t('botLink.agentIdPla')"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*{{$t('botLink.agentIdTip')}}</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingBindForm.appKey"
                        :placeholder="$t('botLink.appKeyPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingBindForm.secret"
                        :placeholder="$t('botLink.secretPla')"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdingDialogFormVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingding()"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </el-dialog>
        <!-- 钉钉单聊绑定企业对话框 -->
        <el-dialog
            :title="$t('botLink.bindEnterprise')"
            :visible.sync="dingdingSingDialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingSingBindForm"
            >
                <el-form-item
                    :label="$t('botLink.bindEnterpriseName')"
                    :label-width="bindingformLabelWidth"
                    prop="corpName"
                >
                    <el-input
                        v-model="dingdingSingBindForm.corpName"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="corpId"
                    :label="$t('botLink.bindDingCorpIdTip')"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingSingBindForm.corpId"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('botLink.appName')"
                    :label-width="bindingformLabelWidth"
                    prop="agentName"
                >
                    <el-input
                        v-model="dingdingSingBindForm.agentName"
                        :placeholder="$t('botLink.appNamePla')"
                        :autofocus="true"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    prop="agentId"
                    :label="$t('botLink.dingAgentIdTip2')"
                    :label-width="bindingformLabelWidth"
                >
                    <el-input
                        v-model="dingdingSingBindForm.agentId"
                        :placeholder="$t('botLink.agentIdPla')"
                    ></el-input>
                    <span
                        v-if="warningVisible"
                        style="
                            color: #f56c6c;
                            display: block;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                        "
                        class="warning-text"
                        >*{{$t('botLink.agentIdTip')}}</span
                    >
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="appKey"
                >
                    <el-input
                        v-model="dingdingSingBindForm.appKey"
                        :placeholder="$t('botLink.appKeyPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label-width="bindingformLabelWidth"
                    prop="secret"
                >
                    <el-input
                        v-model="dingdingSingBindForm.secret"
                        :placeholder="$t('botLink.secretPla')"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdingSingDialogFormVisible = false"
                    >{{$t('common.cancel')}}</el-button
                >
                <el-button type="primary" @click="getAuthUrlDingdingSing()"
                    >{{$t('common.confirm')}}</el-button
                >
            </div>
        </el-dialog>
        <!-- 查看已绑定钉钉单聊企业对话框 -->
        <el-dialog
            style="overflow-y: auto"
            :title="$t('botLink.boundToEnterprise')"
            :close-on-click-modal="false"
            :visible.sync="dingdinSingInfoDialogVisible"
        >
            <el-form
                ref="bindForm"
                :rules="dingdingBindRules"
                :model="dingdingSingBindForm"
            >
                <el-form-item :label="$t('botLink.enterpriseName')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseNamePla')"
                        v-model="dingdingSingBindForm.corpName"
                    ></el-input>
                </el-form-item>
                <el-form-item :label="$t('botLink.enterpriseID')">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.enterpriseIDPla')"
                        v-model="dingdingSingBindForm.corpId"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentID">
                    <el-input
                        disabled="disabled"
                        v-model="dingdingSingBindForm.agentId"
                        :placeholder="$t('botLink.agentIdPla')"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AgentName">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.agentNamePla')"
                        v-model="dingdingSingBindForm.agentName"
                    ></el-input>
                </el-form-item>
                <el-form-item label="AppKey">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.appKeyPla')"
                        v-model="dingdingSingBindForm.appKey"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Secret">
                    <el-input
                        disabled="disabled"
                        :placeholder="$t('botLink.secretPla')"
                        v-model="dingdingSingBindForm.secret"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dingdinSingInfoDialogVisible = false"
                    >{{$t('common.close')}}</el-button
                >
            </div>
        </el-dialog>
        <!-- 云之家绑定 -->
        <el-dialog
            :title="$t('botLink.bindApp')"
            :visible.sync="yunzhijiaDialogFormVisible"
            :close-on-click-modal="false"
            @open="resetStatus"
            top="6vh"
        >
          <el-form
              ref="bindForm"
              :rules="yunzhijiaBindRules"
              :model="yunzhijiaBindForm"
          >
            <el-form-item
                :label="$t('botLink.appName')"
                :label-width="bindingformLabelWidth"
                prop="corpName"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.corpName"
                  :placeholder="$t('botLink.enterpriseNamePla')"
                  :autofocus="true"
              ></el-input>
            </el-form-item>
            <el-form-item
                prop="corpId"
                :label="$t('botLink.yunZhiJiaCorpIdTip')"
                :label-width="bindingformLabelWidth"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.corpId"
                  :placeholder="$t('botLink.EID')"
              ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('botLink.appName')"
                :label-width="bindingformLabelWidth"
                prop="appName"
            >
              <el-input
                  v-model="yunzhijiaBindForm.appName"
                  :placeholder="$t('botLink.appNamePla')"
                  :autofocus="true"
              ></el-input>
            </el-form-item>
            <el-form-item
                prop="appId"
                :label="$t('botLink.yunZhiJiaAppidTip')"
                :label-width="bindingformLabelWidth"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.appId"
                  :placeholder="$t('botLink.appidPla')"
              ></el-input>
              <span
                  v-if="warningVisible"
                  style="
                              color: #f56c6c;
                              display: block;
                              text-align: left;
                              font-size: 12px;
                              line-height: 16px;
                          "
                  class="warning-text"
              >*{{$t('botLink.agentIdTip')}}</span
              >
            </el-form-item>
            <el-form-item
                :label-width="bindingformLabelWidth"
                prop="appSecret"
            >
              <el-input
                  v-model="yunzhijiaBindForm.appSecret"
                  :placeholder="$t('botLink.appSecretPla')"
              ></el-input>
            </el-form-item>
            <el-form-item
                :label-width="bindingformLabelWidth"
                prop="signKey"
            >
              <el-input
                  v-model.trim="yunzhijiaBindForm.signKey"
                  :placeholder="$t('botLink.signKeyPla')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="yunzhijiaDialogFormVisible = false"
            >{{$t('common.cancel')}}</el-button
            >
            <el-button type="primary" @click="getAuthUrlYunzhijia()"
            >{{$t('common.confirm')}}</el-button
            >
          </div>
        </el-dialog>
        <!-- 查看已绑定云之家企业对话框 -->
        <el-dialog
            style="overflow-y: auto"
            :title="$t('botLink.appBound')"
            :close-on-click-modal="false"
            :visible.sync="yunzhijiaInfoDialogVisible"
            top="6vh"
        >
          <el-form
              ref="bindForm"
              :rules="yunzhijiaBindRules"
              :model="yunzhijiaBindForm"
          >
            <el-form-item :label="$t('botLink.enterpriseName')">
              <el-input
                  disabled="disabled"
                  :placeholder="$t('botLink.enterpriseNamePla')"
                  v-model="yunzhijiaBindForm.corpName"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('botLink.registerEID')">
              <el-input
                  disabled="disabled"
                  :placeholder="$t('botLink.EID')"
                  v-model="yunzhijiaBindForm.corpId"
              ></el-input>
            </el-form-item>
            <el-form-item label="AppID">
              <el-input
                  disabled="disabled"
                  v-model="yunzhijiaBindForm.agentId"
                  :placeholder="$t('botLink.appidPla')"
              ></el-input>
            </el-form-item>
            <el-form-item label="AgentName">
              <el-input
                  disabled="disabled"
                  :placeholder="$t('botLink.agentNamePla')"
                  v-model="yunzhijiaBindForm.agentName"
              ></el-input>
            </el-form-item>
            <el-form-item label="SignKey">
              <el-input
                  disabled="disabled"
                  :placeholder="$t('botLink.signKeyPla')"
                  v-model="yunzhijiaBindForm.appKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="AppSecret">
              <el-input
                  disabled="disabled"
                  :placeholder="$t('botLink.appSecretPla')"
                  v-model="yunzhijiaBindForm.secret"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="yunzhijiaInfoDialogVisible = false"
            >{{$t('common.close')}}</el-button
            >
          </div>
        </el-dialog>
        <!-- 绑定桌面网站面板 -->
        <div class="accesChannel">
            <el-button type="text" @click="addBindWebsite()" class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <a class="url">
                        <img
                            src="../../assets/images/webchat.png"
                            class="image"
                        />
                        <p class="title">{{$t('botLink.websiteAccess')}}</p>
                        <p class="des">
                          {{$t('botLink.websiteAccessTip')}}
                        </p>
                    </a>
                </el-card>
            </el-button>
            <!--  绑定企业微信面板 -->
            <el-button type="text" @click="addBindCompany()" class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img src="../../assets/images/qiyechat.png" class="image" />
                    <p class="title">{{$t('botLink.wechat')}}</p>
                    <p class="des">
                      {{$t('botLink.wechatTip')}}
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定小程序面板 -->
            <el-button
                type="text"
                @click="addBindMiniProg()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/xiaochengxu.jpeg"
                        class="image"
                    />
                    <p class="title">{{$t('botLink.weChatMiniProgram')}}</p>
                    <p class="des">
                      {{$t('botLink.weChatMiniProgramTip')}}
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定微信公众号面板 -->
            <el-button
                type="text"
                @click="addWechatMp()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/Wechat_128px_1230528.png"
                        class="image"
                    />
                    <p class="title">{{$t('botLink.weChatOfficialAccount')}}</p>
                    <p class="des">
                      {{$t('botLink.weChatOfficialAccountTip')}}
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定钉钉面板 -->
            <el-button
                type="text"
                @click="addDingdingMp()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/dingding.jpeg"
                        class="image"
                    />
                    <p class="title">{{$t('botLink.dingDing')}}</p>
                    <p class="des">
                      {{$t('botLink.dingDingTip')}}
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定APP面板 -->
            <!-- <el-button type="text" @click="addBindAPP()" class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                  <img src="../../assets/images/resizeApi.png" class="image" />
                  <p class="title">APP </p>
                  <p class="des">可以将机器人接入到自建APP中，服务APP用户</p>
                </el-card>
            </el-button> -->
            <!-- 绑定微信客服面板 -->
            <!-- <el-button
                type="text"
                @click="addWxKfMp()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/Wechat_128px_1230528.png"
                        class="image"
                    />
                    <p class="title">微信客服</p>
                    <p class="des">
                        可以在微信内、外各个场景中接入微信客服，做好客户咨询。
                    </p>
                </el-card>
            </el-button> -->
            <!-- 绑定钉钉群面板 -->
            <el-button
                type="text"
                @click="addDingdingGroup()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/dingding.jpeg"
                        class="image"
                    />
                    <p class="title">{{$t('botLink.dingDingGroup')}}</p>
                    <p class="des">
                      {{$t('botLink.dingDingGroupTip')}}
                    </p>
                </el-card>
            </el-button>
             <!-- 绑定钉钉单聊面板 -->
            <el-button
                type="text"
                @click="addDingdingSing()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/dingding.jpeg"
                        class="image"
                    />
                    <p class="title">{{$t('botLink.dingSingleChatBot')}}</p>
                    <p class="des">
                      {{$t('botLink.dingSingleChatBotTip')}}
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定飞书面板 -->
            <el-button
                type="text"
                @click="getFeishuList"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/feishu.webp"
                        class="image"
                    />
                    <p class="title">{{$t('botLink.feiShu')}}</p>
                    <p class="des">
                      {{$t('botLink.feiShuTip')}}
                    </p>
                </el-card>
            </el-button>
            <!-- 绑定云之家 -->
            <el-button
                type="text"
                @click="adYunzhijiaMp()"
                class="cardButton"
            >
              <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                <i class="iconfont guoran-yun" style="font-size: 60px;color: #3cbaff"></i>
                <p class="title">{{$t('botLink.yunZhiJia')}}</p>
                <p class="des">
                  {{$t('botLink.yunZhiJiaTip')}}
                </p>
              </el-card>
            </el-button>
            <el-button
                type="text"
                v-if="isHasImPoewr &&  uid != 20281 && uid != 117431"
                @click="adPCIM"
                class="cardButton"
            >
              <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                <i class="iconfont guoran-guanlixitong1" style="font-size: 58px;color: #09DDD5"></i>
                <p class="title">
                    {{  isE ? $t('botLink.eChatIM') : $t('botLink.askChatIM') }}
                </p>
                <p class="des">
                  {{$t('botLink.imTip')}}
                </p>
              </el-card>
            </el-button>
            <!-- 绑定AskBot客户端 -->
            <el-button
                type="text"
                @click="addAskBotClient"
                class="cardButton">
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img
                        src="../../assets/images/fav.png"
                        class="image"/>
                    <p class="title">{{$t('botLink.askBotClient')}}</p>
                    <!-- 更好的用户体验与更强大的数字员工。 -->
                    <!-- {{loginModeOptions[0]&&this.loginModeOptions[0].corpName ? this.loginModeOptions[0].corpName : ''}} -->
                    <p class="des">{{$t('botLink.askBotClientTip')}}</p>
                </el-card>
            </el-button>
            <!-- 绑定致远OA -->
            <el-button
                type="text"
                v-if="determineMainId.isDT()"
                @click="addZhiYuanOA()"
                class="cardButton"
            >
                <el-card :body-style="{ padding: '0px' }" class="botlink-card">
                    <img src="../../assets/images/zhiyuan.png" class="zhiyuan" alt="">
                    <p class="title">{{$t('botLink.zhiYuan')}}</p>
                    <p class="des">
                      {{$t('botLink.zhiYuanTip')}}
                    </p>
                </el-card>
            </el-button>
            <div>
                <!-- 桌面网站drawer -->
                <el-drawer
                    :title="$t('botLink.websiteAccess')"
                    size="1300px"
                    :wrapperClosable="false"
                    :visible.sync="websitedrawer"
                    :close-on-press-escape="false"
                >
                    <div class="website_drawer_content">
                        <div class="access-item" style="margin-top: 0">
                            <div class="name" style="margin-top: 30px">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p>{{$t('botLink.websiteAccessDrawer.des')}}</p>
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        class="bindingbutton"
                                        v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                        @click="addWebsiteDialogVisible = true"
                                        >{{$t('botLink.websiteAccessDrawer.add')}}</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="website-bind">
                                        <el-table
                                            :data="bindWebsiteList"
                                            :empty-text="$t('botLink.websiteAccessDrawer.emptyText')"
                                            :header-cell-style="{
                                                backgroundColor: '#f2f2f2',
                                                height: '40px',
                                                color: '#000000',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                            }"
                                            style="width: 100%">
                                            <el-table-column
                                                prop="agentName"
                                                align="center"
                                                :label="$t('botLink.websiteName')">
                                            </el-table-column>
                                            <el-table-column
                                                prop="agentId"
                                                align="center"
                                                :label="$t('botLink.websiteDomainName')">
                                            </el-table-column>
                                            <el-table-column
                                                prop="agentId"
                                                align="center"
                                                :label="$t('botLink.websiteAccessDrawer.authorizationTime')">
                                                <template slot-scope="scope">
                                                    <span>{{ new Date(scope.row.createTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="needLogin"
                                                align="center"
                                                class-name="need-login"
                                                width="80"
                                                :label="$t('botLink.websiteAccessDrawer.needLogin')">
                                                <template slot-scope="scope">
                                                    <el-checkbox v-model="scope.row.needLogin" :disabled="scope.row.bindBot && scope.row.bindBot.botInfos && scope.row.bindBot.botInfos.length > 1 && scope.row.bindBot" @change="saveWebSetLoginConfig(scope.row)" @click.native="clickFn(scope.row,scope.$index)"></el-checkbox>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="corpId"
                                                align="center"
                                                :label="$t('botLink.websiteAccessDrawer.loginWay')">
                                                <template slot-scope="scope">
                                                    <div class="login-mode login-mode-select">
                                                        <img height="14px" class="icon-img" v-if="scope.row.iconType == 0" src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" alt="" srcset="">
                                                        <img height="15px" class="icon-img" v-if="scope.row.iconType == 3" src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" alt="" srcset="">
                                                        <img height="15px" class="icon-img" v-if="scope.row.iconType == 10" src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-arsenal/dev/img/feishu.19d13994.webp" alt="" srcset="">
                                                        <el-select
                                                            v-model="scope.row.corpId"
                                                            size="small"
                                                            @change="saveWebSetLoginConfig(scope.row)"
                                                            :placeholder="$t('common.selectPlaceholder')">
                                                            <el-option
                                                                v-for="item in loginModeOptions"
                                                                :key="item.corpId"
                                                                :label="item.corpName"
                                                                :value="item.corpId"
                                                            >
                                                                <img height="12px" v-if="item.corpType == 0" src="https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png" alt="" srcset="">
                                                                <img height="12px" v-if="item.corpType == 3" src="https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg" alt="" srcset="">
                                                                <img height="14px" v-if="item.corpType == 10" src="https://guoranopen-zjk.oss-cn-zhangjiakou.aliyuncs.com/cdn-arsenal/dev/img/feishu.19d13994.webp" alt="" srcset="">
                                                                <span style="display: inline-block;margin-left: 6px;color: #8492a6; font-size: 13px;">{{ item.corpName }}</span>
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                :label="$t('botLink.operation')"
                                                width="232"
                                                align="center">
                                                <template slot-scope="scope">
                                                    <div class="companyItemOperition">
                                                        <el-link
                                                            @click="deleteWebsiteBind(scope.row.id)"
                                                            type="primary"
                                                            v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                            class="unbind">
                                                            {{$t('common.deleteText')}}
                                                        </el-link>
                                                        <el-link
                                                            type="primary"
                                                            class="copy"
                                                            id="copy"
                                                            v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                            v-clipboard:copy="scope.row.websiteJsCode"
                                                            v-clipboard:success="onCopy"
                                                            v-clipboard:error="onError">
                                                          {{$t('botLink.websiteAccessDrawer.copy')}}
                                                        </el-link>
                                                        <el-link
                                                            @click="botLinkSet(scope.row)"
                                                            type="primary"
                                                            v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                            class="bot-link-set">
                                                          {{$t('botLink.set')}}
                                                        </el-link>
                                                        <el-link
                                                            @click="setWelcomeMesaage(scope.row,scope.$inde,'website')"
                                                            type="primary"
                                                            class="set-welcome-message">
                                                            {{$t('botLink.setWelcome')}}
                                                        </el-link>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="agentId"
                                                align="center"
                                                :label="$t('botLink.robotRecognition')">
                                                <template slot-scope="scope">
                                                    <div class="bindBot">
                                                        <span v-for="(cell, cellIndex) in scope.row.bindBot?scope.row.bindBot.botInfos: []" :key="cell.botId" class="bindBotName">
                                                            <span v-if="cellIndex != 0">、</span>
                                                            {{cell.name}}
                                                        </span>
                                                        <span
                                                            style="color: #366AFF"
                                                            v-if="scope.row.bindBot && scope.row.bindBot.count != 0 && RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                            class="editBindBot el-icon-s-tools"
                                                            @click="bindBotV2('website', 'edit', scope.row)">
                                                        </span>
                                                        <el-button
                                                            v-if="((scope.row.bindBot == null) || (scope.row.bindBot && scope.row.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                            size="mini"
                                                            type="primary"
                                                            @click="bindBotV2('website', 'add', scope.row)">
                                                            {{$t('botLink.bindBot')}}
                                                        </el-button >
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                v-if="isHaveKnowledge"
                                                prop="needLogin"
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.enterpriseKnowledge')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAuthority(scope.row,'website')"
                                                        type="primary"
                                                        v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                        class="bot-link-set">
                                                        {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.appRecognition')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAppRecgAuth(scope.row,'website')"
                                                        type="primary"
                                                        class="bot-link-set">
                                                      {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">2</span>
                            </div>
                            <div class="des">
                              {{$t('botLink.websiteAccessDrawer.copyTip')}}{{
                                    htmlCode
                                }}{{$t('botLink.websiteAccessDrawer.copyTip2')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">{{$t('botLink.accessCompleted')}}</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 小程序drawer -->
                <el-drawer
                    :title="$t('botLink.weChatMiniProgramDrawer.title')"
                    size="50%"
                    :wrapperClosable="false"
                    :visible.sync="xcxdrawer"
                    :close-on-press-escape="false"
                >
                    <div class="qichat_drawer_content">
                        <ul class="mini-app">
                            <li class="mini-app-header">
                                <span class="mini-app-header-name">{{$t('botLink.weChatMiniProgramDrawer.name')}}</span>
                                <span class="mini-app-header-switch">{{$t('botLink.weChatMiniProgramDrawer.switch')}}</span>
                                <span class="mini-app-header-code">{{$t('botLink.weChatMiniProgramDrawer.code')}}</span>
                            </li>
                            <li v-for="(miniBot, miniBotIndex) in miniAppBots" :key="miniBotIndex" class="mini-app-body">
                                <span class="mini-app-header-name">{{miniBot.name}}</span>
                                <span class="mini-app-header-switch">
                                    <el-switch
                                        v-model="miniBot.setting.showAppletEntrance"
                                        active-color="#13ce66"
                                        inactive-color="#dcdfe6"
                                        :disabled="!RP_Visible('OPEN_ACCESS_WX_APPLET')"
                                        @change="updateBot($event,miniBot.id)"
                                    ></el-switch>
                                </span>
                                <span class="mini-app-header-code">
                                    <el-button :disabled="!miniBot.setting.showAppletEntrance" @click="miniAppCodeHandle(miniBotIndex, miniBot.id, 'download')" type="text">{{$t('botLink.weChatMiniProgramDrawer.download')}}</el-button>
                                    <el-popover
                                      placement="right"
                                      width="300"
                                      trigger="click">
                                      <div>
                                          <el-image :src="miniBot.miniAppCodeUrl"></el-image>
                                      </div>
                                      <el-button :disabled="!miniBot.setting.showAppletEntrance" slot="reference" @click="miniAppCodeHandle(miniBotIndex, miniBot.id, 'preview')" type="text">{{$t('botLink.weChatMiniProgramDrawer.preview')}}</el-button>
                                    </el-popover>

                                </span>
                            </li>
                        </ul>
                    </div>
                </el-drawer>
                <!-- 企业微信drawer -->
                <el-drawer
                    :title="$t('botLink.wechatDrawer.title')"
                    :visible.sync="dialog"
                    ref="drawer"
                    size="70%"
                    :wrapperClosable="false"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                              {{$t('botLink.wechatDrawer.step1')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">2</span>
                            </div>
                            <div class="des">
                                <p>
                                  {{$t('botLink.wechatDrawer.step2')}}
                                </p>
                                <div class="clicklink" v-if="RP_Visible('OPEN_ACCESS_EWX')">
                                    <p class="url" id="domain">
                                        {{ authDomain }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEnd"
                                        @click="copyDomain('domain', 'copyEnd')"
                                    >
                                      {{$t('botLink.wechatDrawer.copy')}}
                                    </p>
                                </div>
                                <div class="upload-content">
                                    <div class="upload-box">
                                        <div class="upload-des">
                                          {{$t('botLink.wechatDrawer.uploadDes')}}
                                        </div>
                                        <el-upload
                                            class="upload-demo"
                                            :action="importFileUrl"
                                            name="file"
                                            :onError="uploadError"
                                            :onSuccess="uploadSuccess"
                                            :beforeUpload="beforeAvatarUpload"
                                            :show-file-list="false"
                                        >
                                            <el-button
                                                size="small"
                                                v-if="RP_Visible('OPEN_ACCESS_EWX')"
                                                type="primary"
                                                >{{$t('botLink.wechatDrawer.uploadButton')}}</el-button
                                            >
                                        </el-upload>
                                    </div>
                                    <div class="warning-text">
                                      {{$t('botLink.wechatDrawer.uploadWarning')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >3</span
                                >
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p>{{$t('botLink.wechatDrawer.step3')}}</p>
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        v-if="RP_Visible('OPEN_ACCESS_EWX')"
                                        class="bindingbutton"
                                        @click="dialogFormVisible = true"
                                        >{{$t('botLink.wechatDrawer.bind')}}</el-button
                                    >
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        v-if="RP_Visible('OPEN_ACCESS_EWX') && false"
                                        class="bindingbutton"
                                        @click="openTip"
                                        >{{$t('botLink.wechatDrawer.bind')}}</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="wechat-drawer">
                                        <el-table
                                            :data="bindList"
                                            :empty-text="$t('botLink.wechatDrawer.emptyText')"
                                            :header-cell-style="{
                                                backgroundColor: '#f2f2f2',
                                                height: '40px',
                                                color: '#000000',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                            }"
                                            style="width: 100%">
                                            <el-table-column
                                                prop="corpName"
                                                align="center"
                                                :label="$t('botLink.wechatDrawer.bindAppName')">
                                            </el-table-column>
                                            <el-table-column
                                                prop="companyappid"
                                                align="center"
                                                :label="$t('botLink.wechatDrawer.bindTime')">
                                                <template slot-scope="scope">
                                                    <span>{{ new Date(scope.row.updateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                width="232"
                                                :label="$t('botLink.operation')">
                                                <template slot-scope="scope">
                                                    <div class="companyItemOperition">
                                                        <el-link
                                                            type="primary"
                                                            @click="showBindInfo(scope.row)"
                                                            class="view">
                                                          {{$t('botLink.wechatDrawer.view')}}
                                                        </el-link>
                                                        <!-- 解绑 -->
                                                        <el-link
                                                            @click="postUnbind(scope.row.id)"
                                                            type="primary"
                                                            class="unbind"
                                                            v-if="RP_Visible('OPEN_ACCESS_EWX') && scope.row.bindType == 1">
                                                          {{$t('botLink.wechatDrawer.unBind')}}
                                                        </el-link>
                                                        <!-- 复制链接 -->
                                                        <p
                                                            class="url"
                                                            style="display: none"
                                                            id="authUrlAddress">
                                                            {{ scope.row.authUrl }}
                                                        </p>
                                                        <el-link
                                                            type="primary"
                                                            class="copy"
                                                            id="copy"
                                                            v-if="RP_Visible('OPEN_ACCESS_EWX') && scope.row.bindType == 1"
                                                            @click=" copy('authUrlAddress','copy',scope.row.authUrl)">
                                                          {{$t('botLink.wechatDrawer.copyLink')}}
                                                        </el-link>
                                                        <el-link
                                                            @click="setWelcomeMesaage(scope.row,scope.$inde,'wechat')"
                                                            type="primary"
                                                            class="set-welcome-message">
                                                            {{$t('botLink.setWelcome')}}
                                                        </el-link>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                :label="$t('botLink.robotRecognition')">
                                                <template slot-scope="scope">
                                                    <div class="bindBot">
                                                        <span v-for="(cell, cellIndex) in scope.row.bindBot?scope.row.bindBot.botInfos: []" :key="cell.botId" class="bindBotName">
                                                            <span v-if="cellIndex != 0">、</span>
                                                            {{cell.name}}
                                                        </span>
                                                        <span
                                                            style="color: #366AFF"
                                                            v-if="scope.row.bindBot && scope.row.bindBot.count != 0 && RP_Visible('OPEN_ACCESS_EWX')"
                                                            class="editBindBot el-icon-s-tools"
                                                            @click="bindBotV2('eWx', 'edit', scope.row)">
                                                        </span>
                                                        <el-button
                                                            v-if="((scope.row.bindBot == null) || (scope.row.bindBot && scope.row.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_EWX')"
                                                            size="mini"
                                                            type="primary"
                                                            @click="bindBotV2('eWx', 'add', scope.row)">
                                                            {{$t('botLink.bindBot')}}
                                                        </el-button >

                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                v-if="isHaveKnowledge"
                                                prop="needLogin"
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.enterpriseKnowledge')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAuthority(scope.row,'wechat')"
                                                        type="primary"
                                                        v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                        class="bot-link-set">
                                                        {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.appRecognition')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAppRecgAuth(scope.row,'wechat')"
                                                        type="primary"
                                                        class="bot-link-set">
                                                      {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">4</span>
                            </div>
                            <div class="des">
                              {{$t('botLink.wechatDrawer.step4')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">5</span>
                            </div>
                            <div class="des">{{$t('botLink.bindSuccess')}}</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 飞书drawer -->
                <el-drawer
                    :title="$t('botLink.feiShuDrawer.title')"
                    :visible.sync="feishuDialog"
                    ref="drawer"
                    size="85%"
                    :wrapperClosable="false"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="des">
                                <!-- <div class="binding">
                                    <p>绑定机器人</p>
                                </div> -->
                                <div class="bindingCompany">
                                    <div class="feishu-drawer">
                                        <el-table
                                            :data="bindFeishuList"
                                            :empty-text="$t('botLink.feiShuDrawer.emptyText')"
                                            :header-cell-style="{
                                                backgroundColor: '#f2f2f2',
                                                height: '40px',
                                                color: '#000000',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                            }"
                                            style="width: 100%">
                                            <el-table-column
                                                prop="corpName"
                                                align="center"
                                                :label="$t('botLink.feiShuDrawer.bindName')">
                                            </el-table-column>
                                            <el-table-column
                                                prop="corpName"
                                                align="center"
                                                :label="$t('botLink.feiShuDrawer.bindAppName')">
                                            </el-table-column>
                                            <el-table-column
                                                prop="companyItemTime"
                                                align="center"
                                                :label="$t('botLink.feiShuDrawer.bindTime')">
                                                <template slot-scope="scope">
                                                    <span>{{ new Date(scope.row.updateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                :label="$t('botLink.operation')">
                                                <template slot-scope="scope">
                                                    <div class="companyItemOperition">
                                                        <el-link
                                                            type="primary"
                                                            @click="showFeishuBindInfo(scope.row)"
                                                            class="view">
                                                          {{$t('botLink.view')}}
                                                        </el-link >
                                                        <el-link
                                                            @click="setWelcomeMesaage(scope.row,scope.$inde,'feishu')"
                                                            type="primary"
                                                            class="set-welcome-message">
                                                            {{$t('botLink.setWelcome')}}
                                                        </el-link>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                :label="$t('botLink.robotRecognition')">
                                                <template slot-scope="scope">
                                                    <div class="bindBot">
                                                        <span v-for="(cell, cellIndex) in scope.row.bindBot?scope.row.bindBot.botInfos: []" :key="cell.botId" class="bindBotName">
                                                            <span v-if="cellIndex != 0">、</span>
                                                            {{cell.name}}
                                                        </span>
                                                        <span
                                                            style="color: #366AFF"
                                                            v-if="scope.row.bindBot && scope.row.bindBot.count != 0"
                                                            class="editBindBot el-icon-s-tools"
                                                            @click="bindBotV2('eWx', 'edit', scope.row)">
                                                        </span>
                                                        <el-button
                                                            v-if="((scope.row.bindBot == null) || (scope.row.bindBot && scope.row.bindBot.count == 0))"
                                                            size="mini"
                                                            type="primary"
                                                            @click="bindBotV2('eWx', 'add', scope.row)">
                                                        {{$t('botLink.bindBot')}}</el-button>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                v-if="isHaveKnowledge"
                                                prop="needLogin"
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.enterpriseKnowledge')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAuthority(scope.row,'feishu')"
                                                        type="primary"
                                                        v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                        class="bot-link-set">
                                                        {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.appRecognition')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAppRecgAuth(scope.row,'feishu')"
                                                        type="primary"
                                                        class="bot-link-set">
                                                      {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 钉钉drawer -->
                <el-drawer
                    :title="$t('botLink.dingDrawer.title')"
                    :visible.sync="dingdingDialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="85%"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                                {{$t('botLink.dingDrawer.step1')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >2</span
                                >
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p style="margin-right: 16px">
                                      {{$t('botLink.dingDrawer.step2')}}
                                    </p>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="bindDingding"
                                        >+{{$t('botLink.dingDrawer.bind')}}</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="dingding-drawer">
                                        <el-table
                                            :data="dingdingH5AppList"
                                            :empty-text="$t('botLink.dingDrawer.emptyText')"
                                            :header-cell-style="{
                                                backgroundColor: '#f2f2f2',
                                                height: '40px',
                                                color: '#000000',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                            }"
                                            style="width: 100%">
                                            <el-table-column
                                                prop="corpName"
                                                align="center"
                                                :label="$t('botLink.dingDrawer.bindName')">
                                            </el-table-column>
                                            <el-table-column
                                                prop="agentName"
                                                align="center"
                                                :label="$t('botLink.dingDrawer.bindAppName')">
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                prop="companyItemTime"
                                                :label="$t('botLink.dingDrawer.bindTime')">
                                                <template slot-scope="scope">
                                                    <span>{{ new Date(scope.row.updateTime).Format("yyyy-MM-dd hh:mm:ss")}}</span>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                width="232"
                                                :label="$t('botLink.operation')">
                                                <template slot-scope="scope">
                                                    <div class="companyItemOperition">
                                                    <el-link
                                                        type="primary"
                                                        @click="showDingdingBindInfo(scope.row)"
                                                        class="view">
                                                        {{$t('botLink.view')}}
                                                    </el-link>
                                                    <!-- 解绑 -->
                                                    <el-link
                                                        @click="postDingdingUnbind(scope.row.id, '', scope.row)"
                                                        v-if="RP_Visible('OPEN_ACCESS_DING') && (!scope.row.thirdId || scope.row.thirdId === '')"
                                                        type="primary"
                                                        class="unbind"
                                                        >{{$t('botLink.unBind')}}</el-link
                                                    >
                                                    <!-- 复制链接 -->
                                                    <p
                                                        class="url"
                                                        style="display: none"
                                                        id="authUrlAddress">
                                                        {{ scope.row.authUrl }}
                                                    </p>
                                                    <el-link
                                                        type="primary"
                                                        class="copy"
                                                        v-if="RP_Visible('OPEN_ACCESS_DING') && (!scope.row.thirdId || scope.row.thirdId === '')"
                                                        id="copy"
                                                        @click="copyDingdingUrl('authUrlAddress','copy',scope.row.authUrl)">
                                                        {{$t('botLink.copyLink')}}
                                                    </el-link>
                                                    <el-link
                                                        @click="setWelcomeMesaage(scope.row,scope.$index,'dingding')"
                                                        type="primary"
                                                        class="set-welcome-message">
                                                        {{$t('botLink.setWelcome')}}
                                                    </el-link>
                                                </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                prop="companyItemTime"
                                                align="center"
                                                :label="$t('botLink.robotRecognition')">
                                                <template slot-scope="scope">
                                                    <div class="bindBot">
                                                        <span v-for="(cell, cellIndex) in scope.row.bindBot?scope.row.bindBot.botInfos: []" :key="cell.botId" class="bindBotName">
                                                            <span v-if="cellIndex != 0">、</span>
                                                            {{cell.name}}
                                                        </span>
                                                        <span
                                                            style="color: #366AFF"
                                                            v-if="(scope.row.bindBot && scope.row.bindBot.count != 0) && RP_Visible('OPEN_ACCESS_DING')"
                                                            class="editBindBot el-icon-s-tools"
                                                            @click="bindBotV2('eWx', 'edit', scope.row)">
                                                        </span>
                                                        <el-button
                                                            v-if="((scope.row.bindBot == null) || (scope.row.bindBot && scope.row.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_DING')"
                                                            size="mini"
                                                            type="primary"
                                                            @click="bindBotV2('eWx', 'add', scope.row)">
                                                            {{$t('botLink.bindBot')}}
                                                        </el-button>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                v-if="isHaveKnowledge"
                                                prop="needLogin"
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.enterpriseKnowledge')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAuthority(scope.row,'dingding')"
                                                        type="primary"
                                                        v-if="RP_Visible('OPEN_ACCESS_WEBSITE')"
                                                        class="bot-link-set">
                                                        {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                            <el-table-column
                                                align="center"
                                                class-name="need-login"
                                                width="90"
                                                :label="$t('botLink.appRecognition')">
                                                <template slot-scope="scope">
                                                    <el-link
                                                        @click="setAppRecgAuth(scope.row,'dingding')"
                                                        type="primary"
                                                        class="bot-link-set">
                                                        {{$t('botLink.set')}}
                                                    </el-link>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">
                              {{$t('botLink.dingDrawer.step3')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">4</span>
                            </div>
                            <div class="des">
                                <p>
                                  {{$t('botLink.dingDrawer.step4')}}：
                                </p>
                                <div class="clicklink">
                                    <p class="url" id="dingtalkWhite">
                                        {{ dingtalkWhite }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEndIp"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="
                                            copyDomain(
                                                'dingtalkWhite',
                                                'copyEndIp'
                                            )
                                        "
                                    >
                                      {{$t('botLink.dingDrawer.copy')}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">5</span>
                            </div>
                            <div class="des">
                                <p>
                                  {{$t('botLink.dingDrawer.step5')}}：
                                </p>
                                <div class="clicklink">
                                    <p class="url" id="domainDing">
                                        {{ openDomain }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEndDing"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="copyDomain('domainDing', 'copyEndDing')"
                                    >
                                      {{$t('botLink.dingDrawer.copy')}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">6</span>
                            </div>
                            <div class="des">
                                <p>
                                  {{$t('botLink.dingDrawer.step6')}}：
                                </p>
                                <div class="clicklink">
                                    <p>
                                        <img
                                            src="./../../assets/images/WechatIMG162.jpeg"
                                            alt=""
                                            srcset=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">7</span>
                            </div>
                            <div class="des">{{$t('botLink.bindSuccess')}}</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 钉钉单聊drawer -->
                <el-drawer
                    :title="$t('botLink.dingSingleChatBotDrawer.title')"
                    :visible.sync="dingdingSingDialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">
                              {{$t('botLink.dingSingleChatBotDrawer.step1')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >2</span
                                >
                            </div>
                            <div class="des">
                                <div class="binding">
                                    <p style="margin-right: 16px">
                                        {{$t('botLink.dingSingleChatBotDrawer.step2')}}
                                    </p>
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="bindDingdingSing"
                                        >+{{$t('botLink.dingSingleChatBotDrawer.bind')}}</el-button
                                    >
                                </div>
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                              {{$t('botLink.boundToEnterprise')}}
                                            </div>
                                            <div class="companyappid">
                                              {{$t('botLink.dingSingleChatBotDrawer.boundToBot')}}
                                            </div>
                                            <div class="companyItemTime">
                                              {{$t('botLink.dingSingleChatBotDrawer.bindTime')}}
                                            </div>
                                            <div class="companyItemOperition">
                                              {{$t('botLink.operation')}}
                                            </div>
                                            <div class="bindBot">
                                              {{$t('botLink.bindBot')}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="itemContent">
                                        <div
                                            class="companyItem"
                                            v-for="item in dingdingSingH5AppList"
                                            :key="item.id"
                                        >
                                            <div class="companyItemName">
                                                {{ item.corpName }}
                                            </div>
                                            <div class="companyappid">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <div class="companyItemOperition">
                                                <el-link
                                                    type="primary"
                                                    @click="
                                                        showDingdingSingBindInfo(
                                                            item
                                                        )
                                                    "
                                                    class="view"
                                                    >{{$t('botLink.view')}}</el-link
                                                >
                                                <!-- 解绑 -->
                                                <el-link
                                                    @click="
                                                        postDingdingSingUnbind(
                                                            item.id
                                                        )
                                                    "
                                                    type="primary"
                                                    class="unbind"
                                                    >{{$t('botLink.unBind')}}</el-link
                                                >
                                                <!-- 复制链接 -->
                                                <p
                                                    class="url"
                                                    style="display: none"
                                                    id="authUrlAddress"
                                                >
                                                    {{ item.authUrl }}
                                                </p>
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    id="copy"
                                                    @click="
                                                        copyDingdingUrl(
                                                            'authUrlAddress',
                                                            'copy',
                                                            item.authUrl
                                                        )
                                                    "
                                                    >{{$t('botLink.copyLink')}}</el-link
                                                >
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #366AFF"
                                                    v-if="(item.bindBot && item.bindBot.count != 0)"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0))"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >{{$t('botLink.bindBot')}}</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            dingdingSingH5AppList == null ||
                                            dingdingSingH5AppList.length == 0
                                        "
                                    >
                                        <br />
                                        <p
                                            class="null"
                                            style="text-align: center"
                                        >
                                          {{$t('botLink.dingSingleChatBotDrawer.emptyText')}}
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">
                              {{$t('botLink.dingSingleChatBotDrawer.step3')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">4</span>
                            </div>
                            <div class="des">
                                <p>
                                  {{$t('botLink.dingSingleChatBotDrawer.step4')}}：
                                </p>
                                <div class="clicklink">
                                    <p class="url" id="dingtalkWhite">
                                        {{ dingtalkWhite }}
                                    </p>
                                    <p
                                        class="copy"
                                        id="copyEndIp"
                                        v-if="RP_Visible('OPEN_ACCESS_DING')"
                                        @click="
                                            copyDomain(
                                                'dingtalkWhite',
                                                'copyEndIp'
                                            )
                                        "
                                    >
                                      {{$t('botLink.copy')}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">5</span>
                            </div>
                            <div class="des">
                                <p>
                                    {{$t('botLink.dingSingleChatBotDrawer.step5')}}
                                </p>
                                <div class="clicklink">
                                    <p>
                                        <img
                                            src="./../../assets/images/dingdingSing6.png"
                                            width="100%"
                                            height="auto"
                                            alt=""
                                            srcset=""
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">6</span>
                            </div>
                            <div class="des">{{$t('botLink.bindSuccess')}}</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 钉钉群drawer -->
                <el-drawer
                    title="接入钉钉群"
                    :visible.sync="dingdingGroupDialog"
                    ref="dingdingGroupDialog"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <dingding-group
                        ref="dingdinggroup"
                        @bindBotV2="bindBotV2"
                        @editDingdingGroupList="editDingdingGroupList"
                    ></dingding-group>
                </el-drawer>
                <el-drawer
                    :title="$t('botLink.dingGroupListDrawer.drawerTitle')"
                    :visible.sync="bindedDingdingGroupDialog"
                    ref="bindedDingdingGroupDialog"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    @close="closeDdgList"
                    :close-on-press-escape="false"
                >
                    <binded-dingdinggroup
                        ref="bindeddingdinggroup"
                        @bindBotV2="bindBotV2"
                        @getGroupTemplateList="getGroupTemplateList"
                        :dingdingGroupInfoStr="dingdingGroupInfoStr"
                        :tamplateId="dingdingGroupTamplateId"
                        :replyVisible="dingdingGroupReplyVisible"
                    ></binded-dingdinggroup>
                </el-drawer>
                <!-- 微信公众号drawer -->
                <el-drawer
                    :title="$t('botLink.weChatOfficialAccountDrawer.title')"
                    :visible.sync="wechatmpdialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <div class="qichat_drawer_content">
                        <div class="access-item" style="margin-top: 0px">
                            <div class="name">
                                <span
                                    class="stepNamber"
                                    style="margin-top: 28px"
                                    >1</span
                                >
                            </div>
                            <div class="des">
                                <div
                                    class="binding"
                                    data-authtype="1"
                                    ref="authTypeInfo"
                                >
                                    <p>{{$t('botLink.weChatOfficialAccountDrawer.step1')}}</p>
                                    <el-button
                                        type="primary"
                                        size="medium"
                                        v-if="RP_Visible('OPEN_ACCESS_PWX')"
                                        class="bindingbutton"
                                        @click="bindWechatByThirdAuth"
                                        >{{$t('botLink.weChatOfficialAccountDrawer.authorization')}}</el-button
                                    >
                                </div>
                                <!-- <p style="color: #d9001b;font-size: 12px;margin-bottom: 5px;">
                                    注意：您的公众号必须是认证的微信订阅号或服务号，一个微信公众号只能绑定一个机器人，绑定第二个机器人时之前绑定的机器人会自动解绑
                                </p> -->
                                <div class="bindingCompany">
                                    <div class="itemHeader">
                                        <div class="companyItem">
                                            <div class="companyItemName">
                                              {{$t('botLink.weChatOfficialAccountDrawer.authorized')}}
                                            </div>
                                            <div class="companyItemTime">
                                              {{$t('botLink.weChatOfficialAccountDrawer.authorizedTime')}}
                                            </div>
                                            <!-- <div class="companyappid">授权方式</div> -->
                                            <div class="companyItemOperition">
                                              {{$t('botLink.operation')}}
                                            </div>
                                            <div class="companyItemOperition need-login">
                                              {{$t('botLink.weChatOfficialAccountDrawer.userLogin')}}
                                            </div>
                                            <div class="bindBot">
                                              {{$t('botLink.bindBot')}}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            wechatMpbindList == null ||
                                            wechatMpbindList.length == 0
                                        "
                                    >
                                        <p
                                            class="null"
                                            style="text-align: center"
                                        >
                                          {{$t('botLink.emptyText')}}
                                        </p>
                                    </div>
                                    <div
                                        class="itemContent"
                                        v-show="
                                            wechatMpbindList != null &&
                                            wechatMpbindList.length > 0
                                        "
                                    >
                                        <div
                                            class="companyItem"
                                            v-for="(
                                                item, index
                                            ) in wechatMpbindList"
                                            :key="item.id"
                                        >
                                            <div class="companyItemName">
                                                {{ item.agentName }}
                                            </div>
                                            <div class="companyItemTime">
                                                {{
                                                    new Date(
                                                        item.updateTime
                                                    ).Format(
                                                        "yyyy-MM-dd hh:mm:ss"
                                                    )
                                                }}
                                            </div>
                                            <!-- <div class="companyappid">{{item.bindType==0?'第三方':'开发者'}}</div> -->
                                            <div class="companyItemOperition">
                                                <!-- <el-link type="primary" @click="showWechatMpBindInfo(item)" class="view">查看</el-link> -->
                                                <!-- 解绑 -->
                                                <el-link
                                                    @click="
                                                        deleteWechatMpBind(
                                                            item.id
                                                        )
                                                    "
                                                    type="primary"
                                                    class="unbind"
                                                    v-if="RP_Visible('OPEN_ACCESS_PWX')"
                                                    >{{$t('botLink.unBind')}}</el-link
                                                >
                                                <!-- 复制链接 -->
                                                <p
                                                    class="url"
                                                    style="display: none"
                                                    id="authUrlAddress"
                                                >
                                                    {{ item.authUrl }}
                                                </p>
                                                <el-link
                                                    type="primary"
                                                    class="copy"
                                                    v-if="RP_Visible('OPEN_ACCESS_PWX')"
                                                    id="copy"
                                                    @click="
                                                        copy(
                                                            'authUrlAddress',
                                                            'copy',
                                                            item.authUrl
                                                        )
                                                    "
                                                    >{{$t('botLink.copyLink')}}</el-link
                                                >
                                                <el-link
                                                    @click="setWelcomeMesaage(item,index,'wechatAccount')"
                                                    type="primary"
                                                    class="set-welcome-message">
                                                  {{$t('botLink.setWelcome')}}
                                                </el-link>
                                            </div>
                                            <div class="companyItemOperition need-login">
                                                <el-checkbox
                                                    v-if="item.serviceType != 0"
                                                    :disabled="!RP_Visible('OPEN_ACCESS_PWX')"
                                                    @change="
                                                        changeAllowPushMsg(
                                                            $event,
                                                            item
                                                        )
                                                    "
                                                    v-model="item.allowPushMsg"
                                                ></el-checkbox>
                                                <div
                                                    @click="showtips"
                                                    v-if="item.serviceType == 0"
                                                    style="
                                                        margin: 0 auto;
                                                        width: 12px;
                                                        height: 12px;
                                                        border: 1px solid
                                                            #dcdfe6;
                                                    "
                                                ></div>
                                            </div>
                                            <div class="bindBot">
                                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                                    > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                                >
                                                <span
                                                    style="color: #366AFF"
                                                    v-if="item.bindBot && item.bindBot.count != 0 && RP_Visible('OPEN_ACCESS_PWX')"
                                                    class="editBindBot el-icon-s-tools"
                                                    @click="bindBotV2('eWx', 'edit', item)"
                                                ></span>
                                                <el-button
                                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_PWX')"
                                                    size="mini"
                                                    type="primary"
                                                    @click="bindBotV2('eWx', 'add', item)"
                                                    >{{$t('botLink.bindBot')}}</el-button
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">2</span>
                            </div>
                            <div class="des">
                                {{$t('botLink.weChatOfficialAccountDrawer.step2')}}
                            </div>
                        </div>
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">3</span>
                            </div>
                            <div class="des">{{$t('botLink.accessCompleted')}}</div>
                        </div>
                    </div>
                </el-drawer>
                <!-- 接入微信客服drawer -->
                <el-drawer
                    :title="$t('botLink.weChatCustomerServiceDrawer.title')"
                    :visible.sync="wxKfDialog"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                    <WxKf ref="wxkf" @bindBotV2Children="bindBotV2Children"/>
                </el-drawer>
                <!-- 云之家drawer -->
                <el-drawer
                    :title="$t('botLink.yunZhiJiaDrawer.title')"
                    :visible.sync="yunzhijiaDrawer"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="qichat"
                    :close-on-press-escape="false"
                >
                  <div class="qichat_drawer_content">
                    <div class="access-item">
                      <div class="name">
                        <span class="stepNamber">1</span>
                      </div>
                      <div class="des">
                        {{$t('botLink.yunZhiJiaDrawer.step1')}}
                      </div>
                    </div>
                    <div class="access-item">
                      <div class="name">
                        <span
                            class="stepNamber"
                            style="margin-top: 28px"
                        >2</span
                        >
                      </div>
                      <div class="des">
                        <div class="binding">
                          <p style="margin-right: 16px">
                            {{$t('botLink.yunZhiJiaDrawer.step2')}}
                          </p>
                          <el-button
                              type="primary"
                              size="mini"
                              @click="bindYunzhijia"
                          >+{{$t('botLink.yunZhiJiaDrawer.bind')}}</el-button
                          >
                        </div>
                        <div class="bindingCompany">
                          <div class="itemHeader">
                            <div class="companyItem">
                              <div class="companyItemName">
                                {{$t('botLink.boundToEnterprise')}}
                              </div>
                              <div class="companyappid">
                                {{$t('botLink.yunZhiJiaDrawer.bindAppName')}}
                              </div>
                              <div class="companyItemTime">
                                {{$t('botLink.yunZhiJiaDrawer.bindTime')}}
                              </div>
                              <div class="companyItemOperition">
                                {{$t('botLink.operation')}}
                              </div>
                              <div class="bindBot">
                                {{$t('botLink.bindBot')}}
                              </div>
                            </div>
                          </div>
                          <div class="itemContent">
                            <div
                                class="companyItem"
                                v-for="item in yunzhijiaList"
                                :key="item.id"
                            >
                              <div class="companyItemName">
                                {{ item.corpName }}
                              </div>
                              <div class="companyappid">
                                {{ item.agentName }}
                              </div>
                              <div class="companyItemTime">
                                {{
                                  new Date(
                                      item.updateTime
                                  ).Format(
                                      "yyyy-MM-dd hh:mm:ss"
                                  )
                                }}
                              </div>
                              <div class="companyItemOperition">
                                <el-link
                                    type="primary"
                                    @click="showYunzhijiaBindInfo(item)"
                                    class="view"
                                >{{$t('botLink.view')}}</el-link
                                >
                                <!-- 解绑 -->
                                <el-link
                                    @click="postDingdingUnbind(item.id,'yunzhijia', item)"
                                    type="primary"
                                    class="unbind"
                                >{{$t('botLink.unBind')}}</el-link
                                >
                                <!-- 复制链接 -->
                                <p
                                    class="url"
                                    style="display: none"
                                    id="authUrlAddress"
                                >
                                  {{ item.authUrl }}
                                </p>
                                <el-link
                                    type="primary"
                                    class="copy"
                                    id="copy"
                                    @click="copyDingdingUrl('authUrlAddress','copy',item.authUrl)"
                                >{{$t('botLink.copyLink')}}</el-link
                                >
                                <el-link
                                    @click="setWelcomeMesaage(item,index,'feishu')"
                                    type="primary"
                                    class="set-welcome-message">
                                  {{$t('botLink.setWelcome')}}
                                </el-link>
                              </div>
                              <div class="bindBot">
                                <span v-for="(cell, cellIndex) in item.bindBot?item.bindBot.botInfos: []" :key="cell.botId" class="bindBotName"
                                > <span v-if="cellIndex != 0">、</span>{{cell.name}}</span
                                >
                                <span
                                    style="color: #366AFF"
                                    v-if="(item.bindBot && item.bindBot.count != 0) && RP_Visible('OPEN_ACCESS_DING')"
                                    class="editBindBot el-icon-s-tools"
                                    @click="bindBotV2('eWx', 'edit', item)"
                                ></span>
                                <el-button
                                    v-if="((item.bindBot == null) || (item.bindBot && item.bindBot.count == 0)) && RP_Visible('OPEN_ACCESS_DING')"
                                    size="mini"
                                    type="primary"
                                    @click="bindBotV2('eWx', 'add', item)"
                                >{{$t('botLink.bindBot')}}</el-button
                                >
                              </div>
                            </div>
                          </div>
                          <div
                              class="itemContent"
                              v-show="yunzhijiaList == null || yunzhijiaList.length == 0"
                          >
                            <br />
                            <p
                                class="null"
                                style="text-align: center"
                            >
                              {{$t('botLink.emptyText')}}
                            </p>
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="access-item">
                      <div class="name">
                        <span class="stepNamber">3</span>
                      </div>
                      <div class="des">
                        {{$t('botLink.yunZhiJiaDrawer.step3')}}
                      </div>
                    </div>
                    <div class="access-item">
                      <div class="name">
                        <span class="stepNamber">4</span>
                      </div>
                      <div class="des">{{$t('botLink.yunZhiJiaDrawer.step4')}}</div>
                    </div>
                  </div>
                </el-drawer>
                <!-- AskChat即时通讯IMdrawer -->
                <el-drawer
                    :title="isE() ? $t('botLink.eChatIm') : $t('botLink.askBotIm') "
                    :visible.sync="pcImVisable"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="askchat"
                    :close-on-press-escape="false"
                >
                    <div class="askchat_drawer_content">
                        <p class="title">{{$t('botLink.humanMachineCollaboration')}}</p>

                        <!--     IM绑定机器人ui更改       -->
                        <div class="bind-askbot-client-bind-bot">
                            <div class="bind-askbot-client-bind-bot-title">
                                <div class="bind-askbot-client-bind-bot-title-left">{{$t('botLink.bindBot')}}</div>
                                <div>{{$t('botLink.consultationScope')}}</div>
                            </div>
                            <div class="bind-askbot-client-bind-bot-content-old">
                                <template v-if="bindImBots.length > 0">
                                    <div class="bind-askbot-client-bind-bot-item condition-bind-askbot-client-bind-bot"  v-for="(item,Index) in bindImBots" :key="Index">
                                        <div class="del-bind-bot">
                                            <i
                                                style="cursor: pointer;color: #366AFF;"
                                                class="iconfont guoran-tongyichicun-16-09-shanchu2"
                                                @click="bindImClickDelete(Index)">
                                            </i>
                                        </div>
                                        <div class="choose-bind-bot choose-bind-bot-min">
                                            <el-select placeholder="请选择机器人" v-model="item.botId" @change="(event) =>{ return changeImBindBot(event,Index) } ">
                                                <el-option
                                                    v-for="(bot, botIndex) in noBindBots"
                                                    :key="botIndex"
                                                    :disabled="bindImBots.some(b =>{ return b.botId == bot.id})"
                                                    :label="bot.name"
                                                    :value="bot.id">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <!-- IM新版本选择咨询范围---增加了条件 -->
                                        <div class="right-consultation-scope">
                                            <div class="right-consultation-scope-top" v-for="(conditionsItem,conditionsIndex) in item.conditions" :key="conditionsIndex">
                                                <div class="right-consultation-scope-top-content">
                                                    <div class="select-cell-attr select-cell-inner">
                                                        <el-popover
                                                            :ref="'condition-popover'+conditionsIndex+index"
                                                            placement="bottom-start"
                                                            width="300"
                                                            v-model="conditionsItem.visible"
                                                            popper-class="choose-conditions-poprver"
                                                            :visible-arrow="false">
                                                            <div
                                                                :class="['choose-user-attrible one-column-ellipsis']"
                                                                slot="reference">
                                                                <span v-if="conditionsItem.type === 'ALL'">{{$t('botLink.allPerson')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{$t('botLink.staffOrDept')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'label'">{{$t('botLink.label')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'personalJobLevel'">{{$t('botLink.personalJobLevel')}}</span>
                                                                <span v-else>{{showConditionName(conditionsItem.key)}}</span>
                                                            </div>
                                                            <el-tree
                                                                style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                                                                :data="userAttributeOptions"
                                                                :props="{
                                                                    children: 'children',
                                                                    label: 'label',
                                                                    id:'value',
                                                                }"
                                                                default-expand-all
                                                                node-key="id"
                                                                @node-click="(data) => onNodeClickCondition(data,conditionsItem,item,'IM')"
                                                                ref="userAttributeTree">
                                                                <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']" slot-scope="{node,data}">
                                                                    <span class="custom-tree-node-left">
                                                                        <span class="data-label">{{ $t('botLink.' + data.value) }}</span>
                                                                    </span>
                                                                </span>
                                                            </el-tree>
                                                        </el-popover>
                                                    </div>
                                                    <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL'">
                                                        <el-select
                                                            size="small"
                                                            v-model="conditionsItem.relation"
                                                            placeholder="请选择类型"
                                                            @change="saveImBindBots">
                                                            <el-option
                                                                v-for="item in attrRelations"
                                                                :key="item.value"
                                                                :label="item.name"
                                                                :value="item.value">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                    <!-- 弹框选择组织架构 -->
                                                    <div
                                                        @click="setActiveTreeIndex(item,index,conditionsItem,conditionsIndex)"
                                                        class="select-cell-attrv select-cell-inner one-column-ellipsis"
                                                        v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL'">
                                                        <!-- <span
                                                            v-if="type.length != 0 && userAttributeNameValue.length == 0"
                                                            class="select-cell-attrv-placeholder">
                                                            请选择值
                                                        </span> -->
                                                        <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
                                                            <template v-if="conditionsItem.expect && conditionsItem.expect.length > 0">
                                                                <span class="have-checked-intent"  style="cursor: pointer;color:#606266;" v-for="(tagCell, tagCellIndex) in conditionsItem.expect"  :key="tagCellIndex">
                                                                    <span v-if="isOpenData(tagCell.name)">
                                                                        <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'" :openid="tagCell.name"></open-data>
                                                                    </span>
                                                                    <span v-else>{{tagCell.name }}</span>
                                                                    <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                                                                </span>
                                                            </template>
                                                            <div class="placeholder-text" v-else style="cursor: pointer;color:#606266;">{{$t('common.selectPlaceholder')}}</div>
                                                        </template>
                                                            <!-- 下拉选择自定义字段的值 -->
                                                        <el-select
                                                            class="choose-tag-select"
                                                            v-else
                                                            size="small"
                                                            v-model="conditionsItem.expect"
                                                            filterable
                                                            multiple
                                                            collapse-tags
                                                            :placeholder="$t('botLink.inputOrSelect')"
                                                            @change="saveImBindBots">
                                                            <el-option
                                                                v-for="item in tagsOptions"
                                                                :key="item.id"
                                                                :label="item.label"
                                                                :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>
                                                <div :class="['del-ondition',isFwh ? 'ml' : '']">
                                                    <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delCondition(item,conditionsIndex,'IM')"></i>
                                                </div>
                                            </div>
                                            <div class="add-condition-bot-btn">
                                                <el-button @click="addCondition(item,conditionsItem,'IM')" size="small" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                                                <span  @click="addCondition(item,conditionsItem,'IM')">{{$t('botLink.addCondition')}}</span>
                                            </div>

                                        </div>

                                    </div>
                                </template>
                                <div v-else class="bind-askbot-client-bind-no-data">{{$t('botLink.noBindBot')}}</div>
                            </div>
                        </div>
                        <div class="add-bind-bot-btn">
                            <el-button @click="addBindImBots" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                            <span  @click="addBindImBots" >{{$t('botLink.bindBot')}}</span>
                        </div>
                    </div>
                </el-drawer>
                <!-- 接入致远OAdrawer -->
                <el-drawer
                    :title="$t('botLink.zhiyuanDrawer.title')"
                    :visible.sync="zhiyuanDrawer"
                    ref="drawer"
                    :wrapperClosable="false"
                    size="70%"
                    custom-class="askchat_zhiyuan"
                    :close-on-press-escape="false"
                >
                    <div class="askchat_drawer_content">
                        <!-- <p class="title">人机协同</p> -->
                        <div class="bind_list_head">
                            <div class="bind_left">{{$t('botLink.bindBot')}}</div>
                        </div>
                        <!--     IM绑定机器人ui更改       -->
                        <div class="bind-askbot-client-bind-bot">
                            <div class="bind-askbot-client-bind-bot-title">
                                <div class="bind-askbot-client-bind-bot-title-left">{{$t('botLink.bindBot')}}</div>
                                <div>{{$t('botLink.consultationScope')}}</div>
                            </div>
                            <div class="bind-askbot-client-bind-bot-content-old">
                                <template v-if="bindZhiYuanBots.length > 0">
                                    <div class="bind-askbot-client-bind-bot-item condition-bind-askbot-client-bind-bot"  v-for="(item,Index) in bindZhiYuanBots" :key="Index">
                                        <div class="del-bind-bot">
                                            <i
                                                style="cursor: pointer;color: #366AFF;"
                                                class="iconfont guoran-tongyichicun-16-09-shanchu2"
                                                @click="bindZhiYuanClickDelete(Index)">
                                            </i>
                                        </div>
                                        <div class="choose-bind-bot choose-bind-bot-min">
                                            <el-select placeholder="请选择机器人" v-model="item.botId" @change="(event) =>{ return changeZhiYuanBindBot(event,Index) } ">
                                                <el-option
                                                    v-for="(bot, botIndex) in noBindBots"
                                                    :key="botIndex"
                                                    :disabled="bindZhiYuanBots.some(b =>{ return b.botId == bot.id})"
                                                    :label="bot.name"
                                                    :value="bot.id">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="right-consultation-scope">
                                            <div class="right-consultation-scope-top" v-for="(conditionsItem,conditionsIndex) in item.conditions" :key="conditionsIndex">
                                                <div class="right-consultation-scope-top-content">
                                                    <div class="select-cell-attr select-cell-inner">
                                                        <el-popover
                                                            :ref="'condition-popover'+conditionsIndex+index"
                                                            placement="bottom-start"
                                                            width="300"
                                                            v-model="conditionsItem.visible"
                                                            popper-class="choose-conditions-poprver"
                                                            :visible-arrow="false">
                                                            <div
                                                                :class="['choose-user-attrible one-column-ellipsis']"
                                                                slot="reference">
                                                                <span v-if="conditionsItem.type === 'ALL'">{{$t('botLink.allPerson')}}</span>
                                                                <span v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{$t('botLink.staffOrDept')}}</span>
                                                            </div>
                                                            <el-tree
                                                                style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                                                                :data="userAttributeOptions"
                                                                :props="{
                                                                    children: 'children',
                                                                    label: 'label',
                                                                    id:'value',
                                                                }"
                                                                default-expand-all
                                                                node-key="id"
                                                                @node-click="(data) => onNodeClickCondition(data, conditionsItem, item, 'zhiyuan')"
                                                                ref="userAttributeTree">
                                                                <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']" slot-scope="{data}">
                                                                    <span class="custom-tree-node-left">
                                                                        <span class="data-label">{{ $t('botLink.' + data.value) }}</span>
                                                                    </span>
                                                                </span>
                                                            </el-tree>
                                                        </el-popover>
                                                    </div>
                                                    <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL'">
                                                        <el-select
                                                            size="small"
                                                            v-model="conditionsItem.relation"
                                                            placeholder="请选择类型"
                                                            @change="saveZhiYuanBindBots">
                                                            <el-option
                                                                v-for="item in attrRelations"
                                                                :key="item.value"
                                                                :label="item.name"
                                                                :value="item.value">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                    <!-- 弹框选择组织架构 -->
                                                    <div
                                                        @click="setActiveTreeIndex(item,index,conditionsItem,conditionsIndex)"
                                                        class="select-cell-attrv select-cell-inner one-column-ellipsis"
                                                        v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL'">
                                                        <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
                                                            <template v-if="conditionsItem.expect && conditionsItem.expect.length > 0">
                                                                <span class="have-checked-intent"  style="cursor: pointer;color:#606266;" v-for="(tagCell, tagCellIndex) in conditionsItem.expect"  :key="tagCellIndex">
                                                                    <span v-if="isOpenData(tagCell.name)">
                                                                        <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'" :openid="tagCell.name"></open-data>
                                                                    </span>
                                                                    <span v-else>{{tagCell.name }}</span>
                                                                    <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                                                                </span>
                                                            </template>
                                                            <div class="placeholder-text" v-else style="cursor: pointer;color:#606266;">{{$t('common.selectPlaceholder')}}</div>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div :class="['del-ondition',isFwh ? 'ml' : '']">
                                                    <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delCondition(item,conditionsIndex,'zhiyuan')"></i>
                                                </div>
                                            </div>
                                            <div class="add-condition-bot-btn">
                                                <el-button @click="addCondition(item,conditionsItem,'zhiyuan')" size="small" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                                                <span  @click="addCondition(item,conditionsItem,'zhiyuan')">{{$t('botLink.addCondition')}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-else class="bind-askbot-client-bind-no-data">{{$t('botLink.noBindBot')}}</div>
                            </div>
                        </div>
                        <div class="add-bind-bot-btn">
                            <el-button @click="addBindZhiYuanBots" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                            <span  @click="addBindZhiYuanBots" >{{$t('botLink.bindBot')}}</span>
                        </div>
                        <!-- <div class="add-bind-bot-btn">
                            <el-button @click="addBindImBots" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                            <span  @click="addBindImBots" >绑定机器人</span>
                        </div> -->
                    </div>
                </el-drawer>
                <!-- APPdrawer -->
                <el-drawer :wrapperClosable="false" :title="$t('botLink.appTitle')" size="50%" :visible.sync="APPdrawer" :close-on-press-escape="false">
                    <div class="qichat_drawer_content">
                        <div class="access-item">
                            <div class="name">
                                <span class="stepNamber">1</span>
                            </div>
                            <div class="des">{{$t('botLink.appTip')}}</div>
                        </div>
                    </div>
                </el-drawer>
                <!--AskBot客户端drawer -->
                <el-drawer
                    :title="$t('botLink.askbotClientDrawer.title')"
                    size="900px"
                    :wrapperClosable="false"
                    :destroy-on-close="true"
                    :visible.sync="askbotClientDrawer">
                    <bindAskbotClient
                        :botList="noBindBots"
                        :combineNoBindBots="combineNoBindBots"
                        ref="bindAskbotClient"
                        @addWelMessage="setWelcomeMesaage"
                        :userAttributeOptions="userAttributeOptions"
                        :attrRelations="attrRelations"
                        :tagsOptions="tagsOptions"
                        :orgTree="orgTree">
                    </bindAskbotClient>
                </el-drawer>
                <!-- 网站接入 企微 钉钉 飞书 云之家 的绑定机器人 -->
                <popup @closeEvent="bindBotDialogVisible = false" :popWidth="1200" v-if="bindBotDialogVisible" :class="['bind-bot-popup',isShowConsult ? '' : '']">
                    <div slot="popup-name">{{$t('botLink.bindBot')}}</div>
                    <div slot="popup-con" class="member-contanier">
                        <div class="bindBotContent">
                             <div class="bind-askbot-client-bind-bot">
                            <div class="bind-askbot-client-bind-bot-title">
                                <div class="bind-askbot-client-bind-bot-title-left">{{$t('botLink.bindBot')}}</div>
                                <div v-if="isShowConsult">{{$t('botLink.consultationScope')}}</div>
                            </div>
                            <div class="bind-askbot-client-bind-bot-content">
                              <bind-bot
                                  :userAttributeOptions="userAttributeOptions"
                                  :attrRelations="attrRelations"
                                  :tagsOptions="tagsOptions"
                                  :bindBotList="bindBots"
                                  :appCombineNoBindBots="combineNoBindBots"
                                  :noAskbotBind="true"
                                  :orgTree="orgTree"
                                  @openStaffPopup="openStaffPopup"
                                  @delBindBot="delBindBot"
                              ></bind-bot>
                              <template v-if="false">
                                <template v-if="bindBots.length > 0">
                                    <div :class="['bind-askbot-client-bind-bot-item',isFwh ? 'old-bind-askbot-client-bind-bot' : 'condition-bind-askbot-client-bind-bot']"  v-for="(item,index) in bindBots" :key="index">
                                        <div v-if="!isFwh" class="del-bind-bot">
                                            <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delBindBot(item,index,'')"></i>
                                        </div>

                                        <div :class="['choose-bind-bot',!isShowConsult ? 'choose-bind-bot-max' : 'choose-bind-bot-min']">
                                            <!-- <el-select v-model="item.botId" no-match-text='无匹配数据' filterable placeholder="请选择机器人"  @change="handleCheckedBotsChange($event,item)">
                                                <el-option
                                                    v-for="bot in noBindBots"
                                                    :key="bot.id"
                                                    :label="bot.name"
                                                    :value="bot.id"
                                                    :disabled="bot.isChecked">
                                                </el-option>
                                            </el-select> -->
                                            <!-- combineNoBindBots -->
                                            <el-cascader v-model="item.botId" :options="combineNoBindBots" :props="optionProps" @change="handleCheckedBotsChange($event, item)" filterable>
                                                <!-- <template slot-scope="{ node, data }">
                                                    <span>{{ data.name }}</span>
                                                    <span v-if="node.children != null"> ({{ data.children.length }}) </span>
                                                </template> -->
                                            </el-cascader>
                                        </div>
                                        <template v-if="isShowConsult">
                                            <!-- 旧版本选择咨询范围 -->
                                            <div class="input-divs-fuh" @click.stop="setActiveTreeIndex(item,index)" v-if="isFwh">
                                                <div v-if="item.items && item.items.length > 0"
                                                    class="one-column-ellipsis bind-bot-one-column-ellipsis">
                                                    <span class="have-checked-intent" v-for="(tagCell, tagCellIndex) in item.items"  :key="tagCellIndex">
                                                        <span v-if="isOpenData(tagCell.name)">
                                                            <open-data :type="tagCell.type == 1? 'departmentName': 'userName'" :openid="tagCell.name"></open-data>
                                                        </span>
                                                        <span v-else>{{tagCell.name }}</span>
                                                        <span v-if="tagCellIndex + 1 < item.items.length">,</span>
                                                    </span>
                                                </div>
                                                <div class="placeholder-text" v-else>{{$t('botLink.all')}}</div>
                                                <i class="el-icon-arrow-down"></i>
                                            </div>
                                            <!-- 网站接入 企微 钉钉 飞书 云之家 的绑定机器人  新版本选择咨询范围---增加了条件 -->
                                            <div class="right-consultation-scope" v-else>
                                                <div class="right-consultation-scope-top" v-for="(conditionsItem,conditionsIndex) in item.conditions" :key="conditionsIndex">
                                                   <div class="right-consultation-scope-top-content">
                                                        <div class="select-cell-attr select-cell-inner">
                                                            <el-popover
                                                                :ref="'condition-popover'+conditionsIndex+index"
                                                                placement="bottom-start"
                                                                width="300"
                                                                v-model="conditionsItem.visible"
                                                                popper-class="choose-conditions-poprver"
                                                                :visible-arrow="false">
                                                                <div
                                                                    :class="['choose-user-attrible one-column-ellipsis']"
                                                                    slot="reference">
                                                                    <span v-if="conditionsItem.type === 'ALL'">{{$t('botLink.allPerson')}}</span>
                                                                    <span v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{$t('botLink.staffOrDept')}}</span>
                                                                    <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'label'">{{$t('botLink.label')}}</span>
                                                                    <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'personalJobLevel'">{{$t('botLink.personalJobLevel')}}</span>
                                                                    <span v-else>{{showConditionName(conditionsItem.key)}}</span>
                                                                </div>
                                                                <el-tree
                                                                    style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                                                                    :data="userAttributeOptions"
                                                                    :props="{
                                                                        children: 'children',
                                                                        label: 'label',
                                                                        id:'value',
                                                                    }"
                                                                    default-expand-all
                                                                    node-key="id"
                                                                    @node-click="(data) => onNodeClickCondition(data,conditionsItem,item)"
                                                                    ref="userAttributeTree">
                                                                    <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']" slot-scope="{node,data}">
                                                                        <span class="custom-tree-node-left">
                                                                            <span class="data-label">{{ $t('botLink.' + data.value) }}</span>
                                                                        </span>
                                                                    </span>
                                                                </el-tree>
                                                            </el-popover>
                                                        </div>
                                                        <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL'">
                                                            <el-select
                                                                size="small"
                                                                v-if="conditionsItem.key !== '入职时间'"
                                                                v-model="conditionsItem.relation"
                                                                placeholder="请选择类型">
                                                                <el-option
                                                                    v-for="item in attrRelations"
                                                                    :key="item.value"
                                                                    :label="item.name"
                                                                    :value="item.value">
                                                                </el-option>
                                                            </el-select>
                                                            <el-select
                                                                size="small"
                                                                v-else
                                                                v-model="conditionsItem.relation"
                                                                placeholder="请选择类型">
                                                                <el-option
                                                                    v-for="item in dateTimeAttrRelations"
                                                                    :key="item.value"
                                                                    :label="item.name"
                                                                    :value="item.value">
                                                                </el-option>
                                                            </el-select>
                                                        </div>
                                                        <!-- 弹框选择组织架构 -->
                                                        <!-- {{conditionsItem.source}}{{conditionsItem.formType}} -->
                                                        <div

                                                            class="select-cell-attrv select-cell-inner one-column-ellipsis"
                                                            v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL'">
                                                            <!-- <span
                                                                v-if="type.length != 0 && userAttributeNameValue.length == 0"
                                                                class="select-cell-attrv-placeholder">
                                                                请选择值
                                                            </span> -->
                                                            <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
                                                                <div v-if="conditionsItem.expect && conditionsItem.expect.length > 0" @click="setActiveTreeIndex(item,index,conditionsItem,conditionsIndex)">
                                                                    <template>
                                                                        <span class="have-checked-intent"  style="cursor: pointer;color:#606266;" v-for="(tagCell, tagCellIndex) in conditionsItem.expect"  :key="tagCellIndex">
                                                                            <span v-if="isOpenData(tagCell.name)">
                                                                                <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'" :openid="tagCell.name"></open-data>
                                                                            </span>
                                                                            <span v-else>{{tagCell.name }}</span>
                                                                            <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                                                                        </span>
                                                                    </template>
                                                                </div>

                                                                <div @click="setActiveTreeIndex(item,index,conditionsItem,conditionsIndex)" class="placeholder-text" v-else style="cursor: pointer;color:#606266;">请选择</div>
                                                            </template>
                                                             <!-- 下拉选择自定义字段的值 -->
                                                            <template v-if="conditionsItem.type === 'CUSTOMIZE'">
                                                                <!-- 暂时方案 -->
                                                                <el-input
                                                                    v-if="conditionsItem.key != 'label' && conditionsItem.key != 'personalJobLevel' && conditionsItem.key != '入职时间'"
                                                                    v-model="conditionsItem.expect"
                                                                    size="small"
                                                                    class="choose-tag-select"
                                                                    placeholder="请输入内容">
                                                                </el-input>
                                                                <el-date-picker
                                                                    v-else-if="conditionsItem.key == '入职时间' && (conditionsItem.relation == 'BEFORE' || conditionsItem.relation == 'AFTER')"
                                                                    v-model="conditionsItem.expect"
                                                                    type="date"
                                                                    class="choose-tag-select"
                                                                    size="small"
                                                                    placeholder="选择日期">
                                                                </el-date-picker>
                                                                <el-date-picker
                                                                    v-else-if="conditionsItem.key == '入职时间' && (conditionsItem.relation == 'BETWEEN' || conditionsItem.relation == 'NOT_BETWEEN')"
                                                                    v-model="conditionsItem.expect"
                                                                    size="small"
                                                                    type="daterange"
                                                                    class="choose-tag-select"
                                                                    range-separator="至"
                                                                    start-placeholder="开始日期"
                                                                    end-placeholder="结束日期">
                                                                </el-date-picker>
                                                                <el-select
                                                                    class="choose-tag-select"
                                                                    size="small"
                                                                    v-else
                                                                    v-model="conditionsItem.expect"
                                                                    filterable
                                                                    multiple
                                                                    collapse-tags
                                                                    placeholder="输入或选择值">
                                                                    <el-option
                                                                        v-for="item in tagsOptions"
                                                                        :key="item.id"
                                                                        :label="item.label"
                                                                        :value="item.id">
                                                                    </el-option>
                                                                </el-select>
                                                            </template>
                                                        </div>
                                                   </div>
                                                    <div :class="['del-ondition',isFwh ? 'ml' : '']">
                                                        <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delCondition(item,conditionsIndex)"></i>
                                                    </div>
                                                </div>
                                                <div class="add-condition-bot-btn">
                                                    <el-button @click="addCondition(item,conditionsItem)" size="small" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                                                    <span  @click="addCondition(item,conditionsItem)">{{$t('botLink.addCondition')}}</span>
                                                </div>

                                            </div>
                                        </template>
                                        <div v-if="isFwh" :class="['del-bind-bot',isFwh ? 'ml' : '']">
                                            <i  class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delBindBot(item,index,'')"></i>
                                        </div>
                                    </div>
                                </template>
                                <div v-else class="bind-askbot-client-bind-no-data">{{$t('botLink.noBindBot')}}</div>
                              </template>
                                <div v-if="activeCommonEntry == 1 && askEntry != 1" @click="showMnDdLimitTip" class="bind-askbot-client-bind-mode"></div>
                                <div v-if="activeCommonEntry != 1 || askEntry == 1" class="add-bind-bot-btn">
                                    <el-button @click="addBindBot" type="primary" icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
                                    <span  @click="addBindBot" >{{$t('botLink.bindBot')}}</span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-if="activeCommonEntry != 1 || askEntry == 1" slot="dialog-footer">
                        <el-button  @click="cancelNewBindBot" plain  class="cancel-btn">{{$t('common.cancel')}}</el-button>
                        <el-button type="primary"  @click="submitNewBindBot" class="confirm-btn">{{$t('common.save')}}</el-button>
                    </div>
                </popup>
                <!-- 绑定机器人时选择可咨询范围 -->
                <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-staff-customer">
                    <div slot="popup-name">{{$t('botLink.selectConsultationScope')}}</div>
                    <div slot="popup-con" class="member-contanier">
                        <div class="choose-style" v-if="isFwh">
                            <div>{{$t('botLink.dialogMemberTip')}}</div>
                            <div class="radio-box">
                                <el-radio-group  v-model="bindBotChooseStyle" @change="changeRadioType" style="width:100%">
                                    <el-radio label="all" >{{$t('botLink.all')}}</el-radio>
                                    <el-radio label="self" >{{$t('botLink.custom')}}</el-radio>
                                </el-radio-group>

                            </div>
                        </div>
                        <StaffCustomerIm
                            v-if="!isFwh || bindBotChooseStyle === 'self'"
                            :clientValue="checkClientId"
                            :selectIdsP="selectIds"
                            :memberInfo4Client="{
                                clientInfo: activeBindBotsItems
                            }"
                            :checkedList="activeBindBotsItems"
                            @onNodeClick="onNodeClickFn"
                            :isMultiple="true"
                            ref="bindBotStaffCustomer"
                            :dataType="staffCustomerDataType"
                            :insertIm="true"
                        ></StaffCustomerIm>
                    </div>
                    <div slot="dialog-footer">
                        <el-button  @click="dialogMemberTree=false" plain  class="cancel-btn">{{$t('common.cancel')}}</el-button>
                        <el-button type="primary"  @click="preserStaff"  class="confirm-btn">{{$t('common.confirm')}}</el-button>
                    </div>
                </popup>
                <!-- <setWelcomeMessage :welcomeMessageDrawer.sync="welcomeMessageDrawer"></setWelcomeMessage> -->
                <ChatFlowComponent
                    ref="ChatFlowComponent"
                    :isPart="0"
                    :intentIds="['','']"
                    :drawerSize="1000"
                    :isBotLink="true"
                    @saveWelcomeMessage="saveWelcomeMessage"
                    @checkWelcomType="checkWelcomType"
                />
                <!-- 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用 -->
                <popup @closeEvent="dialogCheckDelBot = false" v-if="dialogCheckDelBot" class="delbot-relation-popup" :haveTips="true">
                    <div slot="popup-name">{{$t('botLink.dialogCheckDelBotTitle')}}</div>
                    <div slot="popup-tip">{{$t('botLink.dialogCheckDelBotTip')}}</div>
                    <div slot="popup-con" class="member-contanier">
                        <div v-if="relationIntents.length > 0 " class="intent-relation-content">
                            <div class="title">
                                <span class="handle-title">{{$t('botLink.handleTitle')}}</span>
                                <span class="relation-title">{{$t('botLink.relationTitle')}}</span>
                            </div>
                            <div
                                v-for="(item, index) in relationIntents"
                                :key="index"
                                class="relation-content">
                                <div class="handle-name">{{item.bot.name}}</div>
                                <div class="relation-name">
                                    <div
                                        v-for="(relationIntentItem, rIndex) in item.relationSettings"
                                        :key="rIndex"
                                        @click="gotoChatFlow(item,relationIntentItem)"
                                        class="relation-name-cell">
                                        {{relationIntentItem.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div slot="dialog-footer">
                        <el-button style="width: 100px" @click="dialogCheckDelBot = false" plain class="cancel-btn">{{$t('common.close')}}</el-button>
                    </div>
                </popup>
                <!------end------------->
                <popup @closeEvent="dialogMemberTreeIM = false" v-if="dialogMemberTreeIM" class="choose-staff-customer">
                    <div slot="popup-name">{{$t('botLink.selectConsultationScope')}}</div>
                    <div slot="popup-con" class="member-contanier">
                        <div class="choose-style">
                            <div>{{$t('botLink.dialogMemberTip')}}</div>
                            <div class="radio-box">
                                <el-radio v-model="IMchooseStyle" label="all" >{{$t('botLink.all')}}</el-radio>
                                <el-radio v-model="IMchooseStyle" label="self" >{{$t('botLink.custom')}}</el-radio>
                            </div>
                        </div>
                        <StaffCustomerIm
                            v-if="IMchooseStyle === 'self'"
                            :clientValue="checkClientId"
                            :selectIdsP="selectIds"
                            :memberInfo4Client="{
                                clientInfo: activeBindBotsItems
                            }"
                            :checkedList="activeBindBotsItems"
                            @onNodeClick="ImNodeClick"
                            :isMultiple="true"
                            ref="staffCustomerIm"
                            dataType="all"
                            :insertIm="true"
                        ></StaffCustomerIm>
                    </div>
                    <div slot="dialog-footer">
                        <el-button style="width: 100px" @click="dialogMemberTreeIM = false" plain size="small">{{$t('common.cancel')}}</el-button>
                        <el-button style="width: 100px" type="primary"  @click="ImPreserStaff" size="small">{{$t('common.confirm')}}</el-button>
                    </div>
                </popup>
                <!-- 权限设置 start -->
                <popup @closeEvent="authoritySetVisible = false" v-if="authoritySetVisible" class="authority-set-popup">
                    <div slot="popup-name">{{$t('botLink.setAuthority')}}</div>
                    <div slot="popup-con" class="member-contanier">
                        <div class="authority-set-top">
                             <div class="evaluate-table-switch">
                                <el-switch
                                    @click.native.prevent="changeKnowledgeSearch('switch')"
                                    :width="42"
                                    v-model="enableKnowledgeSearch"
                                    active-color="#366AFF"
                                    inactive-color="#E2E2E2">>
                                </el-switch>
                                <span
                                    class="switch-open-icon"
                                    @click="changeKnowledgeSearch('open')"
                                    v-if="enableKnowledgeSearch">
                                    <i class="iconfont guoran-a-16-17"></i>
                                </span>
                                <span
                                    class="switch-close-icon"
                                    @click="changeKnowledgeSearch('close')"
                                    v-if="!enableKnowledgeSearch">
                                    <i class="arsenal_icon arsenalcuo1"></i>
                                </span>
                        </div>
                        <span class="bind-askbot-client-tips-text">{{$t('botLink.enterpriseKnowledgeSearch')}}</span>
                        </div>
                        <identification-condition ref="identification"></identification-condition>
                        <template v-if="false">
                          <div class="bind-askbot-client-bind-bot no-border">
                            <div class="bind-askbot-client-bind-bot-title-set-authority">可使用范围</div>
                          </div>
                          <div class="input-divs-set-authority need-mt" @click="openStaffPopup('','','knowledge')">
                            <div v-if="memberInfo4Client && memberInfo4Client.clientInfo && memberInfo4Client.clientInfo.length > 0"
                                 class="one-column-ellipsis  knowledge-one-column-ellipsis">
                                  <span class="have-checked-intent" v-for="(item, index) in memberInfo4Client.clientInfo"
                                        :key="index">
                                      <!-- 0:部门，1:员工，2:上下游部门，3:上下游成员 -->
                                      <span class="data-label" v-if="item.selfType === 0 || item.selfType === 1 || item.ewechat">
                                          <span v-if="!item.ewechat">{{ item.label }}</span>
                                          <span v-else>
                                              <open-data :type="item.selfType == 0 ? 'departmentName' : 'userName'" :openid="item.label"></open-data>
                                          </span>
                                      </span>
                                      <span v-else>{{ item.name }}</span>
                                      <span v-if="index + 1 < memberInfo4Client.clientInfo.length">,</span>
                                  </span>

                            </div>
                            <div class="placeholder-text" v-else>全部</div>
                            <i class="el-icon-arrow-down"></i>
                          </div>
                        </template>
                    </div>
                    <div slot="dialog-footer">
                        <el-button style="width: 100px" @click="authoritySetVisible = false" plain size="small">{{$t('common.cancel')}}</el-button>
                        <el-button style="width: 100px" type="primary"  @click="authoritySetConfirm" size="small">{{$t('common.confirm')}}</el-button>
                    </div>
                </popup>
                <popup @closeEvent="AuthoritySetCancel" v-if="dialogMemberTreesAuthoritySetVisible" class="choose-staff-customer">
                    <div slot="popup-name">{{$t('botLink.selectConsultationScope')}}</div>
                    <div slot="popup-con" class="member-contanier">
                         <div class="choose-style">
                            <div>{{$t('botLink.dialogMemberTip')}}</div>
                            <div class="radio-box">
                                <el-radio v-model="chooseStyle" label="all" >{{$t('botLink.all')}}</el-radio>
                                <el-radio v-model="chooseStyle" label="self" >{{$t('botLink.custom')}}</el-radio>
                            </div>
                        </div>
                        <StaffCustomerIm
                            v-if="chooseStyle === 'self'"
                            :memberInfo4Client="memberInfo4Client"
                            @onNodeClick="AuthoritySetNodeClick"
                            :isMultiple="true"
                            ref="staffCustomerIm"
                            dataType="staff"
                        ></StaffCustomerIm>
                    </div>
                    <div slot="dialog-footer">
                        <el-button style="width: 100px" @click="AuthoritySetCancel" plain size="small">{{$t('common.cancel')}}</el-button>
                        <el-button style="width: 100px" type="primary"  @click="AuthoritySetPreserStaff" size="small">{{$t('common.confirm')}}</el-button>
                    </div>
                </popup>
                <!-- 权限设置 end -->
                <popup @closeEvent="closeWelcomeMessage" v-if="welcomeMessageVisiable" class="welcome_message_type">
                    <div slot="popup-name">{{$t('botLink.setWelcome')}}</div>
                    <div slot="popup-con" class="member-contanier">
                        <p class="popup_title">{{$t('botLink.welcomeMode')}}</p>
                        <div class="welcome_content">
                            <div class="welcome_type" :class="welcomeMessageType == 'askbot' ? 'welcomeActive' : ''" @click="welcomeMessageType = 'askbot'">
                                <!-- <section class="welcome_left">
                                    <i class="iconfont"></i>
                                </section> -->
                                <section class="welcome_right">
                                    <p class="welcome_right_title">{{$t('botLink.welcomeTip1')}}</p>
                                    <p class="welcome_right_tip">{{$t('botLink.welcomeTip2')}}</p>
                                </section>
                            </div>
                            <div class="welcome_type" :class="welcomeMessageType == 'knowledge' ? 'welcomeActive' : ''" @click="welcomeMessageType = 'knowledge'">
                                <!-- <section class="welcome_left">
                                    <i class="iconfont"></i>
                                </section> -->
                                <section class="welcome_right">
                                    <p class="welcome_right_title">{{$t('botLink.welcomeTip3')}}</p>
                                    <p class="welcome_right_tip">{{$t('botLink.welcomeTip4')}}</p>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div slot="dialog-footer">
                        <el-button style="width: 100px" @click="closeWelcomeMessage" plain size="small">{{$t('common.cancel')}}</el-button>
                        <el-button style="width: 100px" type="primary"  @click="sureWelcomeType" size="small">{{$t('common.confirm')}}</el-button>
                    </div>
                </popup>
            </div>
        </div>
        <el-drawer
            title="应用智能识别"
            size="800px"
            :wrapperClosable="true"
            :visible.sync="appAuthConfigVisible">
            <AppAuthconfig></AppAuthconfig>
        </el-drawer>
    </div>
</template>
<script>
import WxKf from "./component/BotlinkWXkefu.vue"
import DingdingGroup from './component/DingdingGroup.vue';
import BindedDingdinggroup from "./component/DingdinggroupList.vue";
import OpenData from "@/components/openData";
import popup from "../../components/popupNew.vue";
import StaffCustomer from './component/SelectStaff.vue';
import StaffCustomerIm from '@/components/staffCustomer.vue'
import bindAskbotClient from './component/bindAskbotClient.vue'
import setWelcomeMessage from './component/setWelcomeMessage.vue';
import ChatFlowComponent from "../ChatFlow/FlowComponent.vue";
import AppAuthconfig from "./component/AppAuthconfig.vue";
import BindBot from "./component/bindBot";
import IdentificationCondition from "../../components/identificationCondition.vue";
export default {
    components:{
        IdentificationCondition,
      BindBot,
        OpenData,
        WxKf,
        DingdingGroup,
        BindedDingdinggroup,
        StaffCustomer,
        popup,
        StaffCustomerIm,
        bindAskbotClient,
        setWelcomeMessage,
        ChatFlowComponent,
        AppAuthconfig
    },
    data() {
        return {
            // 选择可咨询范围
            dialogMemberTree: false,
            dialogMemberTreeIM: false,
            checkClientId: [],
            selectIds: [], // 已选中的组织架构id
            activeBindBotsItems: [],
            activeCorpId: "",

            isFwh: false, // 当前是否在绑定微信公众号
            bindBotDialogVisible: false,
            checkedBots: [],
            noBindBots: [],

            combineNoBindBots: [],

            bindActiveAppItem: {},
            bindBots: [],
            activeTreeIndex: null,
            defaultCheckedKeys: [5],
            orgTree: [],
            defaultExpandAll: false,
            bindBotsAppType: "",
            defaultProps: {
                children: "children",
                label: "name",
            },
            optionProps: {disabled: 'isChecked', label: "name", value: 'id', emitPath: false},
            xcxdrawer: false,
            miniAppBots: [],
            isOpenMiniprogram: false,
            miniProgramUrl: "",
            warningVisible: false,
            activePageInfo: {
                // 当前页跳转信息
                code: "",
                state: "",
            },
            websitedrawer: false,
            htmlCode: "</body>",
            bindWebsiteList: [],
            addWebsiteDialogVisible: false,
            websiteBindForm: { websiteName: "", domain: "", botId: "" },
            table: false,
            dialog: false,
            loading: false,
            drawer: false,
            formLabelWidth: "80px",
            bindingformLabelWidth: "",
            timer: null,
            dialogFormVisible: false,
            authDomain: "",
            openDomain: "",
            authUrl: "",
            dialogVisible: false,
            bindList: [],
            bindForm: {
                agentId: "",
                bindType: "",
                corpId: "",
                corpName: "",
                secret: "",
            },
            bindRules: {
                corpName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpId: [
                    {
                        required: true,
                        message: "请输入企业ID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为32个字符", trigger: "blur" },
                ],
                agentId: [
                    {
                        required: true,
                        message: "请输入AgentID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为64个字符", trigger: "blur" },
                ],
                secret: [
                    {
                        required: true,
                        message: "请输入secret",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
            },
            infoDialogVisible: false,
            fileList: [],
            importFileUrl: location.origin + "/api//bind/ewechat/uploadFile",
            settingList: {
                id: "",
                //name: "第1个机器",
                setting: {
                    showAppletEntrance: true,
                },
            },
            APPdrawer: false,
            wechatmpdialog: false,
            wechatMpbindList: [],
            wechatMpBindForm: {},
            wechatMpShowDialogVisible: false,
            wechatMpAuthDialog: false,
            developerDialog: false,
            publicThirdAuthType: "1",
            // 微信客服
            wxKfDialog: false,
            wxKfAppList: [],
            // 钉钉
            dingdingDialog: false,
            dingdingH5AppList: [],
            dingdingDialogFormVisible: false,
            dingdingBindForm: {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
                agentName: "",
            },
            dingdingBindRules: {
                agentName: [
                    {
                        required: true,
                        message: "请输入应用名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpId: [
                    {
                        required: true,
                        message: "请输入企业ID",
                        trigger: "blur",
                    },
                    { max: 36, message: "长度最长为36个字符", trigger: "blur" },
                ],
                agentId: [
                    {
                        required: true,
                        message: "请输入AgentID",
                        trigger: "blur",
                    },
                    { max: 32, message: "长度最长为64个字符", trigger: "blur" },
                ],
                secret: [
                    {
                        required: true,
                        message: "请输入secret",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                appKey: [
                    {
                        required: true,
                        message: "请输入appKey",
                        trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
            },
            dingdinInfoDialogVisible: false,
            dingtalkWhite: "",
            // 钉钉单聊
            dingdingSingDialog: false,
            dingdingSingH5AppList: [],
            dingdingSingDialogFormVisible: false,
            dingdingSingBindForm: {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
                agentName: "",
            },
            dingdinSingInfoDialogVisible: false,
            dingSingtalkWhite: "",
            // 钉钉群
            dingdingGroupDialog: false,
            bindedDingdingGroupDialog: false,
            dingdingGroupInfoStr: "",
            dingdingGroupTamplateId: "",
            dingdingGroupReplyVisible: true,
            isGroupList: false,
            // 飞书
            feishuDialog: false,
            bindFeishuList: [],
            feishuBindForm: {
                bindType: "",
                corpId: "",
                corpName: "",
            },
            feishuInfoDialogVisible: false,
            loginMode:"",
            loginModeOptions:[],
            channel: "E_WECHAT",
            zhiyuanDrawer:false,
            // showNumWeb:1,
            yunzhijiaDrawer:false,
            yunzhijiaDialogFormVisible:false,
            yunzhijiaBindRules: {
                appName: [
                    {
                    required: true,
                    message: "请输入应用名称",
                    trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpName: [
                    {
                    required: true,
                    message: "请输入企业名称",
                    trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                corpId: [
                    {
                    required: true,
                    message: "请输入注册ID",
                    trigger: "blur",
                    },
                    { max: 36, message: "长度最长为36个字符", trigger: "blur" },
                ],
                appId: [
                    {
                    required: true,
                    message: "请输入App Id",
                    trigger: "blur",
                    },
                    { max: 32, message: "长度最长为64个字符", trigger: "blur" },
                ],
                appSecret: [
                    {
                    required: true,
                    message: "请输入App Secret",
                    trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
                signKey: [
                    {
                    required: true,
                    message: "请输入Sign Key",
                    trigger: "blur",
                    },
                    { max: 64, message: "长度最长为64个字符", trigger: "blur" },
                ],
            },
            yunzhijiaBindForm: {
                appId:"",
                appName:"",
                appSecret:"",
                contactSecret:"",
                corpId:"",
                corpName:"",
                signKey:""
            },
            yunzhijiaList:[],
            yunzhijiaInfoDialogVisible:false,
            isShowConsult:false, // 可咨询范围展示与否
            pcImVisable: false,
            insertImBots:[],
            bindImBots:[],
            bindZhiYuanBots:[],
            uid:null,
            askbotClientDrawer:false, // 接入askbot客户端drawer
            isHaveKnowledge:false, // 判断有没有企业知识系统权限
            drawerSetType:"", // website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书   IM
            activeSetAuthorityItem:{},
            authoritySetVisible:false,
            enableKnowledgeSearch:false,
            memberInfo4Client: {
                clientInfo: [], // 选中数据
            },
            chooseStyle:'all',
            dialogMemberTreesAuthoritySetVisible:false,
            bindBotChooseStyle:'all',
            staffCustomerDataType:'staff',
            bindBotActiveItem:{}, // 绑定机器人的active
            IMchooseStyle:"all",
            welcomeMessageDrawer:false, // 欢迎语drawer
            activeSetWelcomeMessageData:{},
            setWelcomeMessageType:'', // website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书   IM  wechatAccount 微信公众号
            bindBotType:'', // IM  other(除im外的其他)
            bindBotsCopy:[],
            dialogCheckDelBot:false,
            relationIntents:[],
            openDrawerType:"", // 打开配置弹框  WEBSITE网站 E_WECHAT企微   P_WECHAT公众号  DING钉钉   FEISHU飞书,  YUN_ZHI_JIA云之家
            userAttributeOptions:[{
                value:"ALL",
                label:"所有人",
            },{
                value:"STAFF",
                label:"员工",
                children:[{
                    value:"STAFF_OR_DEPARTMENT",
                    label:"员工 / 部门"
                },{
                    value:"CUSTOMIZE",
                    label:"标签",
                    key: 'label',
                    type: "SELECT"
                }]
            }],
            relation: "CONTAIN_ANY",
            attrRelations: [
                { name: "为空", value: "IS_NULL" },
                { name: "不为空", value: "IS_NOT_NULL" },
                { name: "包含任意", value: "CONTAIN_ANY" },
                { name: "不包含任意", value: "NOT_CONTAIN_ANY" },
            ],
            dateTimeAttrRelations: [
                { name: "早于", value: "BEFORE" },
                { name: "晚于", value: "AFTER" },
                { name: "介于", value: "BETWEEN" },
                { name: "不介于", value: "NOT_BETWEEN" },
            ],
            tagsOptions:[],
            activeConditionsIndex:0,
            checkedList:[],
            activeCommonEntry: 0,
            askEntry: 0,
            appAuthConfigVisible:false,
            welcomeMessageVisiable:false,
            welcomeMessageType: 'askbot',
            welcomeMessageDate: null,
        };
    },
    computed:{
        isHasImPoewr() {
            return sessionStorage.getItem('isHasImPoewr')
        },

    },
    methods: {
        showConditionName(conditionKey){
            let name = "";
            this.userAttributeOptions.forEach(item => {
                console.log(item)
                if(item.children){
                    item.children.forEach(cell => {
                        if(cell.key == conditionKey) {
                            name = cell.label;
                        }
                    })
                }
            })
            return name;
        },
        judgeMain(){
            this.FetchGet(this.requestUrl.bindV2.getCustomizeFields).then((res) => {
                if (res.code == 0) {
                    if(res.data && res.data.length > 0){

                        //  是否展示标签 是否展示 个人职级  用于过滤是否需要请求相关的option接口，减少请求次数
                        // let isTaoboLabel = false;
                        let isMengniuPersonalJobLevel = false;
                        let isTongdun = false;

                        res.data.forEach(dataItem => {
                            // 韬博定制功能转通用
                            // 滔搏-标签
                            // if(dataItem.enName === 'label'){
                            //     isTaoboLabel = true;
                            //     this.userAttributeOptions.forEach(item => {
                            //         if(item.value === 'STAFF'){
                            //             item.children.push({
                            //                 value:"CUSTOMIZE",
                            //                 label:dataItem.name,
                            //                 key: 'label',
                            //                 type: "SELECT",
                            //                 source: "taobo"
                            //             })
                            //         }
                            //     })
                            // } else
                            if (dataItem.enName === 'personalJobLevel'){ // 蒙牛-personalJobLevel_个人职级
                                isMengniuPersonalJobLevel = true;
                                this.userAttributeOptions.forEach(item => {
                                    if(item.value === 'STAFF'){
                                        item.children.push({
                                            value: "CUSTOMIZE",
                                            label: dataItem.name,
                                            key: 'personalJobLevel',
                                            type: "SELECT",
                                            source: "mengniu"
                                        })
                                    }
                                })
                            } else {
                                isTongdun = true;
                                this.userAttributeOptions.forEach(item => {
                                    if(item.value === 'STAFF'){
                                        item.children.push({
                                            value: "CUSTOMIZE",
                                            label: dataItem.name,
                                            key: dataItem.enName,
                                            type: dataItem.type,
                                            source: "tongdun"
                                        })
                                    }
                                })
                            }
                        })
                        // 韬博定制功能转通用

                        if(isMengniuPersonalJobLevel){
                            this.getPersonalJobLevelOptions();
                        }
                    }
                }
            })
            // this.getTagsOptions();
        },
        delCondition(item,conditionsIndex,type){
            item.conditions.splice(conditionsIndex,1)
            if(type === 'IM'){
                this.saveImBindBots()
            }
            if(type === 'zhiyuan') {
                this.saveZhiYuanBindBots()
            }
        },
        // 添加条件
        addCondition(item,conditionsItem,type){
            item.conditions.push({
                visible:false,
                type:'ALL',
                expect:[],
                key:"",
                relation:"CONTAIN_ANY",
            })
            if(type === 'IM'){
                this.saveImBindBots()
            }
            if(type === 'zhiyuan') {
                this.saveZhiYuanBindBots()
            }
        },
        // 获取自定义标签数据
        getTagsOptions(corpId, callBack){
            this.FetchGet(this.requestUrl.bindV2.getTagsOptions + "?corpId=" + corpId).then((res) => {
                if (res.code == 0) {
                    this.tagsOptions = res.data;
                    if (callBack) {
                        let tagListIds = [];
                        if (this.tagsOptions && this.tagsOptions.length != 0) {
                            tagListIds = this.tagsOptions.map(item => item.id);
                        }

                        callBack(tagListIds);
                    }
                }
            })
        },
        // 获取个人职级数据
        getPersonalJobLevelOptions(){
            this.FetchGet(this.requestUrl.bindV2.getPersonalJobLevelOptions + '?fieldName=personalJobLevel').then((res) => {
                if (res.code == 0) {
                    let arr = [];
                    res.data.forEach(item => {
                        arr.push({
                            id: item,
                            label: item
                        })
                    })
                    this.tagsOptions = arr;
                }
            })
        },
        // 绑定机器人时的可咨询范围增加条件 start
        onNodeClickCondition(data,conditionsItem,item,type){
            conditionsItem.visible = false;
            if(data.value === "STAFF") {
                conditionsItem.visible = true;
                return false
            };
            conditionsItem.type = data.value;
            conditionsItem.key = '';
            conditionsItem.expect = [];
            if(data.key !== '入职时间'){
                conditionsItem.relation = 'CONTAIN_ANY';
            } else {
                conditionsItem.relation = 'BEFORE';
                conditionsItem.expect = '';
            }

            // item.items = [];
            if(data.value === 'CUSTOMIZE'){
                conditionsItem.key = data.key;
                conditionsItem.source = data.source;
                conditionsItem.formType = data.type;
            }
            if(type === 'IM'){
                this.saveImBindBots()
            }
            if(type === "zhiyuan") {
                this.saveZhiYuanBindBots();
            }
        },
        // 绑定机器人时的可咨询范围增加条件 end
        // mengniu主体下钉钉渠道所有的操作都不允许操作，点击时给提示：请联系服务商进行配置
        // 绑定机器人能进到绑定页面，但是不允许绑定机器人、修改已绑定机器人、修改可咨询范围
        showMnDdLimitTip(){
            this.$message.warning('请联系服务商进行配置');
        },
        setAppRecgAuth(data, type) {
            console.log(data, type);
            this.appAuthConfigVisible = true;
        },
        // 是否有知识管理权限
        getUserCompanyModule(){
            this.FetchGet(this.requestUrl.company.getUserCompanyModule).then(res =>{
                if(res.code == '0' && res.data) {
                    res.data.modules.forEach(item => {
                        if(item.moduleCode === "KNOWLEDGE"){
                            this.isHaveKnowledge = true;
                        }
                    })
                }
            })
        },
        addAskBotClient(){
            this.FetchGet(this.requestUrl.bindV2.getTagsOptions).then((res) => {
                if (res.code == 0 && res.data && res.data.length > 0) {
                    this.tagsOptions = res.data;
                }
                this.askbotClientDrawer = true;
                this.$nextTick(() => {
                    this.$refs.bindAskbotClient.getClientBindInfo((item) =>{
                        if(this.openDrawerType == 'WEBCLIENT') {
                            this.welcomeMessageDate = JSON.parse(JSON.stringify(item))
                            this.setAskbotWelcomeMessage(item);
                        }
                    });
                })
            })

        },
        // 修改企业知识智能搜索开关
        changeKnowledgeSearch(value){
            if(value === 'open'){
                this.enableKnowledgeSearch = false;
            } else if (value === 'close'){
                this.enableKnowledgeSearch = true;
            }
        },
         // 设置知识管理权限
        setAuthority(item,type){
            if(!(this.dingdingDialog && item.commonEntry == 1) || this.askEntry == 1){
                this.drawerSetType = type;
                item.staffList = [];
                this.activeSetAuthorityItem = item;
                this.authoritySetVisible = true;
                this.enableKnowledgeSearch = item.extInfo && item.extInfo.enableKnowledgeSearch ? item.extInfo.enableKnowledgeSearch : false;
                this.memberInfo4Client.clientInfo = item.extInfo && item.extInfo.knowledgeSearchItems ? item.extInfo.knowledgeSearchItems : [];
                this.memberInfo4Client.clientInfo.forEach(item => {
                    item.label = item.name;
                    // type 1：部门 0：成员
                    // selfType  0 部门  1 员工
                    item.selfType = item.type == 1 ? 0 : 1
                })
                this.activeSetAuthorityItem.staffList =  this.memberInfo4Client.clientInfo
                console.log(this.memberInfo4Client.clientInfo,'this.memberInfo4Client.clientInfo');
                this.$nextTick(() => {
                    this.$refs.identification.getTagsOptions(item.corpId, (tagListIds) => {
                        this.$nextTick(() => {
                          if (item.knowledgeScope){
                            this.enableKnowledgeSearch = item.knowledgeScope.enableKnowledgeSearch;
                            if (item.knowledgeScope.conditions){

                                // 脏数据处理 回显过滤已删除标签
                                let conditions = JSON.parse(JSON.stringify(item.knowledgeScope.conditions));
                                conditions.forEach(conditionCell => {
                                    if(conditionCell.key == 'label'){
                                        if (conditionCell.expect && conditionCell.expect.length != 0) {
                                            conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                                            conditionCell.expect = [...new Set(conditionCell.expect)];
                                        } else {
                                            conditionCell.expect = [];
                                        }
                                    }
                                })

                                this.$refs.identification.pluginCondition = conditions
                            }
                            if (item.knowledgeScope.knowledgeScopeConditions){
                              this.$refs.identification.$refs.dataSet.dataSetCondition = JSON.parse(JSON.stringify(item.knowledgeScope.knowledgeScopeConditions))
                            }
                            if (item.knowledgeScope.pluginToolInfo){
                              this.$refs.identification.pluginToolInfo = JSON.parse(JSON.stringify(item.knowledgeScope.pluginToolInfo))
                            }
                          }
                        })
                    });
                })


            } else {
                this.showMnDdLimitTip();
            }
        },
        openStaffPopup(){
            this.activeSetAuthorityItem.staffList = JSON.parse(JSON.stringify(this.memberInfo4Client.clientInfo));
            this.dialogMemberTreesAuthoritySetVisible = true;
            this.chooseStyle = this.activeSetAuthorityItem.staffList.length > 0 ? 'self' : 'all'
        },
        AuthoritySetNodeClick(node,staffType,item,row) {
            // website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书   IM
            let obj = JSON.parse(JSON.stringify(node))
            obj.memberId = obj.id
            if(staffType == 'updown') {
                obj.source = 'UPDOWN'
                // organizationId 和 id  一个人可以在多个组织架构下
                if(node.checked) {
                    this.activeSetAuthorityItem.staffList.push(obj)
                } else {
                    this.activeSetAuthorityItem.staffList = this.activeSetAuthorityItem.staffList.filter(client =>{
                        return client.id != obj.id
                    })
                }
            }  else {
                obj.source = 'MEMBER'
                if(node.checked) {
                    obj.ewechat = obj.staffType === 'staff-t' ? true : false;
                    this.activeSetAuthorityItem.staffList.push(obj)
                } else {
                    this.activeSetAuthorityItem.staffList = this.activeSetAuthorityItem.staffList.filter(client =>{
                        return client.id != obj.id
                    })
                }
            }
        },
        AuthoritySetCancel(){
            this.activeSetAuthorityItem.staffList = JSON.parse(JSON.stringify(this.memberInfo4Client.clientInfo)) || [];
            this.dialogMemberTreesAuthoritySetVisible = false
        },
        AuthoritySetPreserStaff() {
            if (this.chooseStyle === 'all') {
                this.activeSetAuthorityItem.staffList = [];
            }
            this.activeSetAuthorityItem.staffList.forEach(item => {
                if(!item.label){
                    item.label = item.name;
                }
            })
            this.memberInfo4Client.clientInfo = JSON.parse(JSON.stringify(this.activeSetAuthorityItem.staffList)) || [];
            this.dialogMemberTreesAuthoritySetVisible = false

        },
        // 网站接入/企微/钉钉/飞书的权限设置
        authoritySetConfirm(){
            //  website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书
            if (this.drawerSetType !== 'IM'){
                if(this.drawerSetType === 'website'){
                    this.saveWebSet(this.activeSetAuthorityItem);
                } else  if(this.drawerSetType === 'wechat' || this.drawerSetType === 'dingding' || this.drawerSetType === 'feishu'){
                    let  knowledgeSearchItems = []
                     if(this.activeSetAuthorityItem.staffList.length > 0){
                        this.activeSetAuthorityItem.staffList.forEach(v => {
                            knowledgeSearchItems.push({
                                "id":v.id, // 范围对应ID
                                "type":v.selfType === 1 || v.selfType === 3 ? 0 : 1, // 范围对应类型 1：部门 0：成员
                                "name":v.name || v.label, // 范围对应名称
                                "source" :v.selfType === 2 || v.selfType === 3 ? 'UPDOWN' : 'MEMBER', // 数据来源 MEMBER（员工），UPDOWN（上下游）
                                ewechat:v.staffType === 'staff-t' ? true : v.ewechat
                            })
                        })
                    }
                    this.FetchPost(this.requestUrl.bindV2.knowledgeScope,{
                      "bindId": this.activeSetAuthorityItem.id,
                      "enableKnowledgeSearch": this.enableKnowledgeSearch,
                      "knowledgeScopeConditions": this.$refs.identification.$refs.dataSet.dataSetCondition,
                      "conditions": this.$refs.identification.pluginCondition,
                      "pluginToolInfo": null
                    }).then(res => {
                      console.log('knowledgeScope',res)
                      if (res.code == 0){
                        this.authoritySetVisible = false;
                        this.$message.success('信息更新成功');
                        if(this.drawerSetType === 'wechat'){
                          this.getBindList()
                        } else if (this.drawerSetType === 'dingding'){
                          this.getDingdingBindList();
                        } else if (this.drawerSetType === 'feishu'){
                          this.getFeishuBindList();
                        }
                      }
                    })
                    //20240336隐掉下面代码，使用新接口保存企业知识权限
                    // this.FetchPost(this.requestUrl.bindV2.addBindBot,{
                    //     bindCode: this.activeSetAuthorityItem.bindCode,
                    //     bindId: this.activeSetAuthorityItem.id,
                    //     botInfos: this.activeSetAuthorityItem.bindBot && this.activeSetAuthorityItem.bindBot.botInfos ? this.activeSetAuthorityItem.bindBot.botInfos : [],
                    //     knowledgeSearchItems:knowledgeSearchItems,
                    //     enableKnowledgeSearch:this.enableKnowledgeSearch
                    // }).then((res) => {
                    //     if (res.code === "0") {
                    //         this.authoritySetVisible = false;
                    //         this.$message.success('信息更新成功');
                    //         if(this.drawerSetType === 'wechat'){
                    //             this.getBindList()
                    //         } else if (this.drawerSetType === 'dingding'){
                    //             this.getDingdingBindList();
                    //         } else if (this.drawerSetType === 'feishu'){
                    //             this.getFeishuBindList();
                    //         }
                    //     }
                    // })
                } else  if(this.drawerSetType === 'feishu'){
                    this.submitNewBindBot();
                }
            } else if(this.drawerSetType === 'IM'){
                this.bindImBots[this.activeTreeIndex].items = this.activeBindBotsItems.map(item =>{
                    if(!item.name) {
                        item.name = item.label
                    }
                    return item
                })
                if(this.bindImBots[this.activeTreeIndex].botId) {
                    this.saveImBindBots()
                }
            }
        },
        //------本地改动start
          // 设置/获取欢迎语
        setWelcomeMesaage(data){
            console.log('设置/获取欢迎语', data);
            this.welcomeMessageDate = JSON.parse(JSON.stringify(data))
            this.FetchGet(this.requestUrl.bindV2.selectUnifySetGreeting + '?bindCode=' + data.bindCode).then((res) => {
                if (res.code === "0") {
                    let tampActions = res.data && res.data.setting && res.data.setting.unifySetStartNodeActions ? res.data.setting.unifySetStartNodeActions : [];
                    if(tampActions.length == 0) {
                        this.welcomeMessageVisiable = true
                    } else {
                        if(tampActions.some(action =>{ return action.type == 'welcome_knowledge_file' || action.type == 'welcome_llm_card' })) {
                            this.$router.push({
                                path: '/main/knowledgeWelcomeMessage',
                                query:{
                                    bindCode: this.welcomeMessageDate.bindCode,
                                    agentName: this.welcomeMessageDate.agentName,
                                    wechatType: this.welcomeMessageDate.wechatType
                                }
                            })
                        } else {
                            this.setAskbotWelcomeMessage(this.welcomeMessageDate)
                        }
                    }
                }
            })
        },
        setAskbotWelcomeMessage(data) {
            this.welcomeMessageDate = JSON.parse(JSON.stringify(data))
            if(!(this.dingdingDialog && data.commonEntry == 1) || this.askEntry == 1){
                this.activeSetWelcomeMessageData.bindCode = data.bindCode;
                // this.activeSetWelcomeMessageData = item;
                // this.setWelcomeMessageType = type;
                this.welcomeMessageDrawer = true;
                let botIdList = [];
                data.bindBot.botInfos.forEach(v => {
                    botIdList.push(v.botId)
                })
                // this.$refs.ChatFlowComponent.getAccountGroupList(); //获取用户分组list
                this.$refs.ChatFlowComponent.getAllEntityByBot(); // 获取实体
                this.$refs.ChatFlowComponent.getWebhookBaseList(); //  获取webhookbaselist
                this.$refs.ChatFlowComponent.drawer = true;
                this.$refs.ChatFlowComponent.nodeId = "start";
                this.$refs.ChatFlowComponent.intentId = 0;
                this.$refs.ChatFlowComponent.activeNodeName = "开始";
                this.$refs.ChatFlowComponent.skillId = 0;
                this.$refs.ChatFlowComponent.isStartOrEnd = true;
                this.$refs.ChatFlowComponent.getIntentListByBotId('',{},botIdList);
                this.FetchGet(this.requestUrl.bindV2.selectUnifySetGreeting + '?bindCode=' + data.bindCode).then((res) => {
                    if (res.code === "0") {
                        let tampActions = res.data && res.data.setting && res.data.setting.unifySetStartNodeActions ? res.data.setting.unifySetStartNodeActions : [];
                        tampActions && tampActions.forEach((action, index) => {
                            if (!("effectiveTime" in action) || action.effectiveTime == null) {
                                action.effectiveTime = {
                                    range: "PERMANENT",
                                    startTime: "",
                                    endTime: "",
                                    repeat: ["1", "2","3","4","5","6", "7",],
                                    filterHolidays: true,
                                    balanceHolidays: true,
                                    timeRange: [
                                        new Date(),
                                        new Date(
                                            "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                                        ),
                                    ],
                                };
                            }
                        });
                        tampActions = tampActions.filter(action =>{
                            return action.type != 'welcome_knowledge_file' && action.type != 'welcome_llm_card'
                        })
                        this.$refs.ChatFlowComponent.actionList = tampActions;
                        this.$refs.ChatFlowComponent.actionListCopy = JSON.parse(JSON.stringify(tampActions));
                        this.$refs.ChatFlowComponent.rowDrop();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    this.$message.error("获取欢迎语失败！");
                });
            } else {
                this.showMnDdLimitTip();
            }
        },
       // 保存欢迎语
        saveWelcomeMessage(data){
            this.FetchPost(this.requestUrl.bindV2.unifySetGreeting,{
                bindCode:this.activeSetWelcomeMessageData.bindCode,
                unifySetStartNodeActions:data
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success('保存成功');
                    this.$refs.ChatFlowComponent.actionListCopy = JSON.parse(JSON.stringify( this.$refs.ChatFlowComponent.actionList));
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 跳转到欢迎语
        gotoChatFlow(item,relationIntentItem){
        //    let obj = {
        //        bindCode: this.bindActiveAppItem.bindCode,
        //        bindBot:{
        //            botInfos:Array.from([...JSON.parse(JSON.stringify(this.bindBotsCopy)),...JSON.parse(JSON.stringify(this.bindBots))])
        //        }
        //    }
            this.openDrawerType = item.wechatType;
            window.open('/#/main/link?openDrawerType='+this.openDrawerType + '&bindCode='+this.bindActiveAppItem.bindCode, "_blank");
        },
       // 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用
        checkStartNodeFn(){
            this.relationIntents = []
            let botIds = []
            this.bindBots.forEach(newItem => {
                botIds.push(newItem.botId)
            })
            let apiKeys = [];
            this.bindBotsCopy.forEach(oldItem => {
                if(botIds.indexOf(oldItem.botId) === -1 || botIds.length === 0){
                    apiKeys.push( oldItem.apiKey)
                }
            });
            if(apiKeys.length > 0){
                return this.FetchPost(this.requestUrl.bindV2.checkStartNode + '?bindCode=' + this.bindActiveAppItem.bindCode,apiKeys).then((res) => {
                    if (res.code === "0") {
                        this.relationIntents = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        addBindBot(){
            if(!this.isFwh){
                this.bindBots.push({
                    apiKey: [],
                    botId: '',
                    name: '',
                    scope: 0,
                    items: [],
                    botType:0,
                    conditions:[{
                        visible:false,
                        type:'ALL',
                        expect:[],
                        key:"",
                        relation:"CONTAIN_ANY",
                    }]
                })
            } else {
                this.bindBots.push({
                    apiKey: [],
                    botId: '',
                    name: '',
                    scope: 0,
                    items: [],
                })
            }
            console.log(this.bindBots);
        },
        delBindBot(item,index,type){
            this.bindBotType = type;
            let copyItem = JSON.parse(JSON.stringify(item))
            this.bindBots.splice(index,1);
            this.handleCheckedBotsChange(copyItem.botId,copyItem,'del')
        },
        // 删除页面参数
        replaceUrl(param){
          // 删除全部参数
            // let path = this.$route.path //先获取路由路径
            // this.$router.replace(path) //再跳转路由路径，query参数没带过去，所以被清除了

            // 删除某个参数
            let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 先拷贝一个一模一样的对象
            delete newQuery[param] //再删除page
            this.$router.replace({ query: newQuery }) //再把新的替换了
        },
        //------本地改动end
        clickFn(item,index){
            if(item.bindBot && item.bindBot.botInfos && item.bindBot.botInfos.length > 1 && item.bindBot){
                this.$message.warning('只能允许绑定一个机器人，请先解绑机器人');
            }
        },
        // 网站接入--设置
        botLinkSet(item){
            // this.showNumWeb = sessionStorage.setItem('showNumWeb',1);
            this.$router.push({
                name: "botLinkSet",
                query: {
                    webId: item.id,
                    webName: item.agentName,
                    bindCode:item.bindCode
                },
            });
        },
        lookMore(){
            console.log('lookMore');
        },
        onNodeClickFn(node,staffType,item,row){
            // console.log(node,staffType,'node');
            let obj = JSON.parse(JSON.stringify(node))
            obj.name = obj.label || obj.name;
            let AddObj = {
                id:obj.id,
                name:obj.name,
                type:obj.type,
            }
            if(staffType == 'updown') {
                if(node.checked) {
                    // this.bindBotActiveItem.items.push(obj)
                    this.checkedList.push(AddObj)
                } else {
                    // this.bindBotActiveItem.items = this.bindBotActiveItem.items.filter(client =>{
                    //     return client.id != obj.id
                    // })
                    this.checkedList.forEach((v, i) => {
                        if (obj.id == v.id) {
                            this.checkedList.splice(i, 1)
                        }
                    })
                }
            }  else {
                obj.source = 'MEMBER'
                if(node.checked) {
                    if(this.isFwh){
                        this.checkedList.push(AddObj)
                        // this.bindBotActiveItem.items.push(AddObj)
                    } else {
                        this.checkedList.push(AddObj)
                        // this.bindBotActiveItem.conditions[this.activeConditionsIndex].expect.push(AddObj);
                    }
                } else {
                    this.checkedList.forEach((v, i) => {
                        if (obj.id == v.id) {
                            this.checkedList.splice(i, 1)
                        }
                    })
                    if(this.isFwh){
                        // this.bindBotActiveItem.items = this.bindBotActiveItem.items.filter(client =>{
                        //     return client.id != obj.id
                        // })

                    } else {
                        // let bindBotActiveItemCopy = JSON.parse(JSON.stringify(this.bindBotActiveItem));
                        // if(this.bindBotActiveItem.conditions[this.activeConditionsIndex].type === "STAFF_OR_DEPARTMENT"){
                        //     this.bindBotActiveItem.conditions[this.activeConditionsIndex].expect = [];
                        // }
                        // bindBotActiveItemCopy.conditions.forEach(conditionsItem => {
                        //     conditionsItem.expect.forEach(expectItem => {
                        //         if(expectItem.id != obj.id){
                        //             if(conditionsItem.type === "STAFF_OR_DEPARTMENT"){
                        //                 this.bindBotActiveItem.conditions[this.activeConditionsIndex].expect.push(expectItem);
                        //             }

                        //         }
                        //     })
                        // })
                    }

                }
            }
            if(this.isFwh){
                this.bindBotActiveItem.scope = this.bindBotActiveItem.items.length > 0 ? 1 : 0;
            }
        },
        changeRadioType(){
            this.$nextTick(() => {
                if(this.staffCustomerDataType === 'updown' && this.bindBotChooseStyle === 'self'){
                    this.$refs.bindBotStaffCustomer.activeName = 'upDown'
                }
            })
        },
        preserStaff(){
            if (this.bindBotChooseStyle === 'all' && this.isFwh) {
                this.bindBotActiveItem.items = [];
                this.bindBotActiveItem.scope = 0;
            }
            this.dialogMemberTree = false;
            if(this.pcImVisable){
                this.saveImBindBots();
            }
            if(this.zhiyuanDrawer) {
                this.saveZhiYuanBindBots();
            }
            this.activeBindBotsItems = this.checkedList;
            if(this.isFwh){
                this.bindBotActiveItem.items = JSON.parse(JSON.stringify(this.checkedList)) || [];
            } else {
                this.bindBotActiveItem.conditions[this.activeConditionsIndex].expect = JSON.parse(JSON.stringify(this.checkedList)) || [];
            }

        },
        ImPreserStaff() {
            if (this.IMchooseStyle === 'all') {
                this.bindImBots[this.activeTreeIndex].items = [];
                this.bindImBots[this.activeTreeIndex].scope = 0;
            } else {
                this.bindImBots[this.activeTreeIndex].items = this.activeBindBotsItems.map(item =>{
                    if(!item.name) {
                        item.name = item.label
                    }
                    return item
                })
            }
            this.dialogMemberTreeIM = false
            if(this.bindImBots[this.activeTreeIndex].botId) {
                this.saveImBindBots()
            }
        },
        ImNodeClick(node,staffType,item,row) {
            // website 网站接入     wechat 企微    dingding 钉钉     feishu 飞书   IM
            let obj = JSON.parse(JSON.stringify(node))
            obj.memberId = obj.id
            if(staffType == 'updown') {
                obj.source = 'UPDOWN'
                // organizationId 和 id  一个人可以在多个组织架构下
                if(node.checked) {
                    if(this.drawerSetType !== 'IM'){
                       this.activeSetAuthorityItem.staffList.push(obj)
                    } else if (this.drawerSetType === 'IM'){
                        this.activeBindBotsItems.push(obj)
                    }
                } else {
                    if (this.drawerSetType === 'IM'){
                        this.activeBindBotsItems = this.activeBindBotsItems.filter(client =>{
                            return client.id != obj.id
                        })
                    } else  {
                        this.activeSetAuthorityItem.staffList = this.activeSetAuthorityItem.staffList.filter(client =>{
                            return client.id != obj.id
                        })
                    }

                }
            }  else {
                obj.source = 'MEMBER'
                if(node.checked) {
                    if (this.drawerSetType === 'IM'){
                        this.activeBindBotsItems.push(obj)
                    } else {
                        // if(this.drawerSetType === 'website'){
                            this.activeSetAuthorityItem.staffList.push(obj)
                        // }
                    }
                } else {
                    if (this.drawerSetType === 'IM'){
                        this.activeBindBotsItems = this.activeBindBotsItems.filter(client =>{
                            return client.id != obj.id
                        })
                    } else {
                        this.activeSetAuthorityItem.staffList = this.activeSetAuthorityItem.staffList.filter(client =>{
                            return client.id != obj.id
                        })
                    }
                }
            }
            // if(this.bindImBots[this.activeTreeIndex]) {
            //     this.bindImBots[this.activeTreeIndex].items = list;
            //     this.bindImBots = [...this.bindImBots];
            // }
        },
        saveImBindBots(type) {
            // let flag = false
            let bindArr = [...this.bindImBots].filter(item =>{
                // if(item.scope == 1 && (!item.items || item.items.length == 0)) {
                //     flag = true
                // }
                console.log(item,'=====');
                return item.botId
            })
            // if(flag) {
            //     this.$message.warning('请选择组织架构人员')
            //     return
            // }
            let conditionsFlag = true;
            this.bindImBots.forEach(item => {
                conditionsFlag = item.conditions && item.conditions.length > 0 ? true : false;
            })
            if(!conditionsFlag) {
                this.$message.warning('请至少保留一个条件');
                return false;
            }
            this.FetchPost(
                this.requestUrl.bindV2.addBindBot,
                {
                    bindCode: this.bindActiveAppItem.bindCode,
                    bindId: this.bindActiveAppItem.id,
                    botInfos: bindArr
                }
            ).then(res =>{
                if(res.code == '0' && res.data) {
                    if(type) {
                        this.$message.success('解除绑定成功')
                    } else {
                        this.$message.success('绑定成功')
                    }
                }
            })
        },
        saveZhiYuanBindBots(type) {
            let bindArr = [...this.bindZhiYuanBots].filter(item =>{
                return item.botId
            })
            let conditionsFlag = true;
            this.bindZhiYuanBots.forEach(item => {
                conditionsFlag = item.conditions && item.conditions.length > 0 ? true : false;
            })
            if(!conditionsFlag) {
                this.$message.warning('请至少保留一个条件');
                return false;
            }
            this.FetchPost(
                this.requestUrl.bindV2.addBindBot,
                {
                    bindCode: this.bindActiveAppItem.bindCode,
                    bindId: this.bindActiveAppItem.id,
                    botInfos: bindArr
                }
            ).then(res =>{
                if(res.code == '0' && res.data) {
                    if(type == 'del') {
                        this.$message.success('解除绑定成功')
                    } else {
                        this.$message.success('绑定成功')
                    }
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
        saveWebSetLoginConfig(item){
            let objData = {
                domain: item.agentId,
                websiteName: item.agentName,
                botId:item.botId,
                needLogin:item.needLogin,
                channel:0,
                corpId:item.corpId,
            };
            if(this.loginModeOptions.length === 0){
                objData.channel = null;
            } else {
                this.loginModeOptions.forEach((v,i) => {
                    if(v.corpId == item.corpId){
                        objData.corpName = v.corpName;
                        objData.channel = Number(v.corpType)
                    }
                })
            }
            this.FetchPut(this.requestUrl.websiteBind.updateWebInfo,item.id,objData).then((res) => {
                if (res.code === "0") {
                    this.$message.success('信息更新成功');
                    this.authoritySetVisible = false;
                    this.getBindWebsiteListV2();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 保存设置
        saveWebSet(item) {
            let objData = {
                domain: this.websiteBindForm.domain,
                websiteName: this.websiteBindForm.websiteName,
                botId:item.botId,
                needLogin:item.needLogin,
                channel:0,
                corpId:item.corpId,
                knowledgeSearchItems:[],
                enableKnowledgeSearch:this.enableKnowledgeSearch
            };
            if(this.loginModeOptions.length === 0){
                objData.channel = null;
            } else {
                this.loginModeOptions.forEach((v,i) => {
                    if(v.corpId == item.corpId){
                        objData.corpName = v.corpName;
                        objData.channel = Number(v.corpType)
                    }
                })
            }
          this.FetchPost(this.requestUrl.bindV2.knowledgeScope,{
            "bindId": this.activeSetAuthorityItem.id,
            "enableKnowledgeSearch": this.enableKnowledgeSearch,
            "knowledgeScopeConditions": this.$refs.identification.$refs.dataSet.dataSetCondition,
            "conditions": this.$refs.identification.pluginCondition,
            "pluginToolInfo": null
          }).then(res => {
            console.log('knowledgeScope',res)
            if (res.code == 0){
              this.authoritySetVisible = false;
              this.$message.success('信息更新成功');
              this.getBindWebsiteListV2();
            }
          })
            //20240336隐掉改代码使用新接口实现企业知识权限设置
            // if(this.activeSetAuthorityItem.staffList && this.activeSetAuthorityItem.staffList.length > 0){
            //     this.activeSetAuthorityItem.staffList.forEach(v => {
            //         objData.knowledgeSearchItems.push({
            //             "id":v.id, // 范围对应ID
            //             "type":v.selfType === 1 || v.selfType === 3 ? 0 : 1, // 范围对应类型 1：部门 0：成员
            //             "name":v.name || v.label, // 范围对应名称
            //             "source" :v.selfType === 2 || v.selfType === 3 ? 'UPDOWN' : 'MEMBER', // 数据来源 MEMBER（员工），UPDOWN（上下游）
            //             ewechat:v.staffType === 'staff-t' ? true : v.ewechat
            //         })
            //     })
            // }
            // this.FetchPut(this.requestUrl.websiteBind.updateWebInfo,item.id,objData).then((res) => {
            //     if (res.code === "0") {
            //         this.$message.success('信息更新成功');
            //         this.authoritySetVisible = false;
            //         this.getBindWebsiteListV2();
            //     } else {
            //         this.$message.error(res.message);
            //     }
            // });
        },
        // 获取主体下的公司
        getCorps4main() {
            this.FetchGet(this.requestUrl.websiteBind.corps4main).then((res) => {
                if (res && res.code === "0") {
                    this.loginModeOptions = res.data || [];
                    this.loginMode = this.loginModeOptions[0].corpId;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        // 关闭钉钉群list信息
        closeDdgList(){
            console.log("+++++++++++++++++");
            this.$refs.dingdinggroup.getDingdingGroupBindList();
        },
      //部门是否使用通讯录组件
      isOpenData(value){
       /* const res = /^\d+$/;
        console.debug('value',res.test(value))*/
        let bool = false
        if (this.orgTree.length > 1){
          if (this.orgTree[0].originData&&
              this.orgTree[0].originData.wechatBotDTO&&
              this.orgTree[0].originData.wechatBotDTO.bindType===0&&
              this.orgTree[0].originData.wechatBotDTO.wechatType===0){
            bool = true
          }
          else if (this.orgTree[1].originData&&
              this.orgTree[1].originData.wechatBotDTO&&
              this.orgTree[1].originData.wechatBotDTO.bindType===0&&
              this.orgTree[1].originData.wechatBotDTO.wechatType===0){
            bool = true
          }
        }
        else if (this.orgTree.length===1){
          if (this.orgTree[0].originData&&
              this.orgTree[0].originData.wechatBotDTO&&
              this.orgTree[0].originData.wechatBotDTO.bindType===0&&
              this.orgTree[0].originData.wechatBotDTO.wechatType===0){
            bool = true
          }
        }

        return bool
      },
        getGroupTemplateList(){
            this.$refs.dingdinggroup.getDingdingGroupBindList('isGroupList');
        },
        editDingdingGroupList(id, itemStr){
            this.bindedDingdingGroupDialog = true;
            // this.dingdingGroupDialog = false;
            console.log(JSON.parse(itemStr))
            this.dingdingGroupInfoStr = itemStr;
            this.dingdingGroupTamplateId = id;
            this.dingdingGroupReplyVisible = JSON.parse(itemStr).replyVisible;
            this.$nextTick(() => {
                this.$refs.bindeddingdinggroup.getDingdingGroupBindList(id);
            })
        },
        // 获取部门树
        getDepartmentTree(){
        //   let url = this.requestUrl.bindV2.departmentTree+'?fillOriginData=true'
        //     this.FetchGet(url).then((res) => {
        //         if (res.code === "0") {
        //             let arr = res.data;
        //             if (arr[0] && arr[0].total && arr[0].total > 300) {
        //                 this.defaultExpandAll = false;
        //             } else {
        //                 this.defaultExpandAll = true;
        //             }
        //             const formatDepartmentTree = (arr) => {
        //                 arr.forEach((item) => {
        //                     item.name = item.label;
        //                     item.checked = false;
        //                     if (item.children && item.children != 0) {
        //                         item.children = formatDepartmentTree(item.children.filter(cell => cell.type!=0 ))
        //                     }
        //                 })
        //                 return arr;
        //             }
        //             this.orgTree = formatDepartmentTree(arr.filter(item => item.type!=0 ));
        //         } else {
        //           this.$message.error(res.message);
        //         }
        //     });
        let userId = localStorage.getItem("_uid");
        let url = "/portal-api/department/corpListLazy" + "?userId=" + userId;
        this.FetchGet(url).then(res => {
            this.orgTree = res;
        })
        },
        // 获取上下游数据
        getOrganization(){
          let url = this.requestUrl.wechatKF.selectOrganization;
            this.FetchGet(url).then((res) => {
                if (res.code === "0") {
                    let arr = res.data;
                    this.defaultExpandAll = true;
                    const formatDepartmentTree = (arr) => {
                        arr.forEach((item) => {
                            item.checked = false;
                            if (item.children && item.children != 0) {
                                item.children = formatDepartmentTree(item.children)
                            }
                        })
                        return arr;
                    }
                    this.orgTree = formatDepartmentTree(arr);
                } else {
                  this.$message.error(res.message);
                }
            });
        },
        changeItemScope(e){
            console.log(this.bindBots);
            this.bindBots = [...this.bindBots];
        },
        closeBindBotDialog(){
            this.getBotList();
        },
        // 获取机器人列表
        getBotList() {
            let combineNoBindBots = [
                {
                    name: "意图机器人",
                    id: "0",
                    children: [],
                },
                {
                    name: "知识机器人",
                    id: "1",
                    children: [],
                }
            ];
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.noBindBots = res.data.list;
                    this.noBindBots.forEach(bot => {
                        bot.isChecked = false;
                        bot.scope = 0;
                        bot.botType = 0; // 区分 意图机器人 0 知识机器人 10
                        bot = JSON.parse(JSON.stringify(bot));
                    })
                    combineNoBindBots[0].children = this.noBindBots;
                    // this.FetchGet('/tag-api/bot?mainId=' + localStorage.getItem('_mainId'), {
                    //     page: 1,
                    //     size: 1000,
                    // }).then((res) => {
                    //     console.log(res.bots)
                    //     let tampBots = [...res.bots];
                    //     tampBots.forEach(bot => {
                    //         bot.isChecked = false;
                    //         bot.scope = 0;
                    //         bot.botType = 1; // 区分 意图机器人 0 知识机器人 1
                    //         bot = JSON.parse(JSON.stringify(bot));
                    //     })
                    //     combineNoBindBots[1].children = tampBots;
                    //     this.combineNoBindBots = [...combineNoBindBots];

                    // });
                    this.FetchGet('/api/bot/list/llm').then((res) => {
                        let tampBots = [...res.data];
                        let tampKnBots = [];
                        tampBots.forEach(bot => {
                            bot.bot.isChecked = false;
                            bot.bot.scope = 0;
                            bot.bot.botType = 10; // 区分 意图机器人 0 知识机器人 1
                            bot.bot.llmBot = bot.llmBot;
                            bot.bot.name = bot.llmBot ? bot.llmBot.llmBot.name : '';
                            tampKnBots.push(JSON.parse(JSON.stringify(bot.bot)))
                            // bot = JSON.parse(JSON.stringify(bot.bot));
                        })
                        console.log(tampBots)
                        combineNoBindBots[1].children = tampKnBots;
                        this.combineNoBindBots = [...combineNoBindBots];
                    });
              console.log(this.noBindBots,'noBindBotsnoBindBots');
            } else {
              this.$message.error(res.message);
            }
            this.loading = false;
          });
        },
        bindBotV2(appType, handleType, item, isGroupList){ // appType: website wechatGZ handleType: add edit
            console.log(appType, handleType, item, isGroupList);
            this.activeCommonEntry = item.commonEntry;
            // this.activeCommonEntry = 0; // 放开限制用于测试
            this.activeCorpId = item.corpId;
            this.bindBotsAppType = appType;
            this.bindActiveAppItem = item;
            this.bindBotDialogVisible = true;
            this.bindBots = [...[]];
            this.noBindBots.forEach(item => {
                item.isChecked = false;
            })
            if (isGroupList) {
                this.isGroupList = true;
            } else {
                this.isGroupList = false;
            }
            if (handleType == 'edit') {
                item.bindBot.botInfos.forEach(_item => {
                    if(_item.conditions){
                        _item.conditions.forEach(_conditionsItem => {
                            _conditionsItem.visible = false;
                        })
                    } else {
                        _item.conditions = [{
                            visible:false,
                            type:'ALL',
                            expect:[],
                            key:"",
                            relation:"CONTAIN_ANY",
                        }]

                    }
                })
                this.bindBots = JSON.parse(JSON.stringify(item.bindBot.botInfos));
                this.bindBotsCopy = JSON.parse(JSON.stringify(this.bindBots));

                this.getTagsOptions( item.corpId, (tagListIds) => {
                    this.bindBots.forEach((botCell, index) => {
                        let flag = false;
                        this.noBindBots.forEach((cell, cellIndex) => {
                            if (botCell.botId == cell.id || cell.botId == botCell.botId) {
                                cell.isChecked = true;
                                flag = true;
                            }
                        })
                        if(!flag && botCell.botType != 10){
                            this.noBindBots.push({
                                name: botCell.name,
                                id: botCell.botId
                            })
                        }

                        // 条件回显
                        botCell.conditions.forEach(conditionCell => {
                            if(conditionCell.type == "CUSTOMIZE") {
                                if(conditionCell.key == 'label'){
                                    if (conditionCell.expect && conditionCell.expect.length != 0) {
                                        conditionCell.expect = conditionCell.expect.filter(cell => tagListIds.includes(cell));
                                        conditionCell.expect = [...new Set(conditionCell.expect)];
                                    } else {
                                        conditionCell.expect = [];
                                    }
                                }

                                if(conditionCell.key != 'label' && conditionCell.key != 'personalJobLevel' && conditionCell.key != '入职时间'){
                                    conditionCell.expect = conditionCell.expect[0];
                                }
                                if(conditionCell.key == '入职时间'){
                                    if(conditionCell.relation == 'BEFORE' || conditionCell.relation == 'AFTER'){
                                        conditionCell.expect = conditionCell.expect[0];
                                    }
                                }
                            }
                        })
                    })
                });


            }
        },
        // 微信客服接入组件化整理
        bindBotV2Children(obj){ // appType: website wechatGZ handleType: add edit
            let appType = obj.appType;
            let handleType = obj.handleType;
            let item = obj.item;
            this.bindBotV2(appType, handleType, item)
        },
        changeCheckedOrg(node, data, index) {
            if (data.checked) {
                this.bindBots[index].items.unshift(data);
            } else {
                this.bindBots[index].items.forEach((cell, cellIndex) => {
                    if (cell.id == data.id) {
                        this.bindBots[index].items.splice(cellIndex, 1);
                    }
                });
            }
            this.bindBots[index].items = [...this.bindBots[index].items];
            this.bindBots = [...this.bindBots];
        },
        handleCloseOrganization(index, tagCellIndex, type) {
            if(type == 'Im') {
                this.bindImBots[index].items.splice(tagCellIndex, 1);
                return
            }
            this.initShowOrTree(
                [this.bindBots[index].items[tagCellIndex].id],
                false,
                "close"
            );
            this.bindBots[index].items.splice(tagCellIndex, 1);
        },
        initShowOrTree(handleIdArr, isChecked, type) {
            const formOrgTree = (arr, handleIdArr, isChecked) => {
                if (type == "close") {
                    arr.forEach((item) => {
                        if (handleIdArr.indexOf(item.id) != -1) {
                            item.checked = isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        } else {
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        }
                    });
                } else {
                    arr.forEach((item) => {
                        if (handleIdArr.indexOf(item.id) != -1) {
                            item.checked = isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        } else {
                            item.checked = !isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        }
                    });
                }

                return arr;
            };
            this.orgTree = [
                ...formOrgTree(this.orgTree, handleIdArr, isChecked),
            ];
        },
        setActiveTreeIndex(item,index,conditionsItem,conditionsIndex) {
            this.activeConditionsIndex = conditionsIndex;
            this.bindBotActiveItem = item;
            // item.staffList = item.items;
            if(!(this.dingdingDialog && this.activeCommonEntry == 1) || this.askEntry == 1){
                let arr = [];
                if(conditionsItem){
                    this.checkedList = JSON.parse(JSON.stringify(conditionsItem.expect));
                    conditionsItem.expect.forEach((expectItem) => {
                        arr.push(item.id);
                    })
                    this.activeBindBotsItems = conditionsItem.expect;
                } else {
                    if (item.items == null) {
                        item.items = [];
                    }
                    item.items.forEach((_item) => {
                        arr.push(_item.id);
                    })
                    this.activeBindBotsItems = item.items;
                    this.checkedList = JSON.parse(JSON.stringify(item.items));
                }
                setTimeout(() => {
                    if (this.isFwh) {
                        this.initShowOrTree(arr, true, "init");
                    }
                    this.activeTreeIndex = index;
                    this.selectIds = [...arr];
                    this.staffCustomerDataType = this.isFwh ? 'updown' : 'staff'
                    this.bindBotChooseStyle = this.bindBotActiveItem.items && this.bindBotActiveItem.items.length > 0 ? 'self' : 'all'
                    this.dialogMemberTree = true;
                    this.$nextTick(() => {
                        if(this.staffCustomerDataType === 'updown'){
                            this.$refs.bindBotStaffCustomer.activeName = 'upDown'
                        }
                    })
                }, 100);
            } else {
                this.showMnDdLimitTip();
            }

        },
        setIMActiveTreeIndex(index) {
            let arr = [];
            if (this.bindImBots[index].items == null) {
                this.bindImBots[index].items = [];
            }
            this.bindImBots[index].items.forEach((item) => {
                arr.push(item.id);
            })
            setTimeout(() => {
                if (this.isFwh) {
                    this.initShowOrTree(arr, true, "init");
                }
                this.activeTreeIndex = index;
                this.selectIds = [...arr];
                this.activeBindBotsItems =  [...this.bindImBots[index].items];
                if (!this.isFwh) {
                    this.IMchooseStyle = this.activeBindBotsItems.length > 0 ? 'self' : 'all';
                    this.dialogMemberTreeIM = true;
                    this.drawerSetType = 'IM'
                }
            }, 100);
        },
        selectOrg(data, checked, indeterminate) {
            let treeId = "tree" + this.activeTreeIndex;
            this.bindBots[this.activeTreeIndex].items = this.$refs[
                treeId
            ][0].getCheckedNodes();
        },
        // 关闭绑定机器人
        cancelNewBindBot() {
            this.bindBotDialogVisible = false;
        },
        // 提交绑定机器人
        async submitNewBindBot() {
            await this.checkStartNodeFn();
            if(this.relationIntents.length > 0) {
                this.dialogCheckDelBot = true;
                this.bindBotDialogVisible = false;
                return false;
            };
            let botsArr = [];
            let conditionsFlag = true;
            this.bindBots.forEach(bot => {
                let conditionsCopy = []
                if ((this.bindBotsAppType == 'website' && !this.bindActiveAppItem.needLogin) || this.bindBotsAppType == 'wxServ') {
                    botsArr.push({
                        apiKey: bot.apiKey,
                        botId: bot.id || bot.botId,
                        name: bot.name,
                        scope: 0,
                        botType: bot.botType || 0
                    })
                } else {
                    let itemsCopy = [];
                    //  selfType 0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                    if(this.bindBotsAppType === 'eWx'){
                        bot.items && bot.items.forEach(itemsValue => {
                            let obj = {
                                id:itemsValue.id,
                                type:itemsValue.selfType ? itemsValue.selfType === 2 ? 1 : 0 : itemsValue.type, // //  范围对应类型1：部门 0：成员
                                name:itemsValue.name,
                                source:'UPDOWN'
                            }
                            itemsCopy.push(obj)
                        })

                    }
                    if(!this.isFwh){
                        if(bot.conditions && bot.conditions.length > 0){
                            bot.conditions.forEach(conditionsItem => {
                                if(conditionsItem.type == 'ALL'){
                                    conditionsCopy.push({
                                        expect:[],
                                        type:conditionsItem.type,
                                        key:"",
                                        relation:null,
                                    })
                                } else if(conditionsItem.type == 'STAFF_OR_DEPARTMENT'){
                                    conditionsCopy.push({
                                        expect:conditionsItem.expect,
                                        type:conditionsItem.type,
                                        key:"",
                                        relation:conditionsItem.relation,
                                    })
                                } else if(conditionsItem.type == 'CUSTOMIZE'){
                                    if(conditionsItem.key != 'label' && conditionsItem.key != 'personalJobLevel' && conditionsItem.key !== '入职时间'){
                                        conditionsCopy.push({
                                            expect: Array.isArray(conditionsItem.expect)?conditionsItem.expect:[conditionsItem.expect],
                                            type: conditionsItem.type,
                                            key: conditionsItem.key,
                                            relation: conditionsItem.relation,
                                        })
                                    } else if( conditionsItem.key == '入职时间'){
                                        if(conditionsItem.relation == 'BEFORE' || conditionsItem.relation == 'AFTER'){
                                            conditionsCopy.push({
                                                expect: [conditionsItem.expect],
                                                type: conditionsItem.type,
                                                key: conditionsItem.key,
                                                relation: conditionsItem.relation,
                                            })
                                        } else {
                                            conditionsCopy.push({
                                                expect: conditionsItem.expect,
                                                type: conditionsItem.type,
                                                key: conditionsItem.key,
                                                relation: conditionsItem.relation,
                                            })
                                        }
                                    } else {
                                        conditionsCopy.push({
                                            expect: conditionsItem.expect,
                                            type: conditionsItem.type,
                                            key: conditionsItem.key,
                                            relation: conditionsItem.relation,
                                        })
                                    }
                                }
                            })
                        } else {
                            conditionsFlag = false
                        }
                      let obj = {
                        apiKey: bot.apiKey,
                        botId: bot.id || bot.botId,
                        name: bot.name,
                        scope: bot.scope,
                        items: this.bindBotsAppType === 'eWx' ? itemsCopy : bot.items,
                        conditions:conditionsCopy,
                        botType: bot.botType || 0,
                        plugins:bot.plugins ? bot.plugins.map((res) => {
                          let obj = {
                            id:res.id,
                            name: res.name,
                            scope:res.scope,
                            conditions:res.conditions.map(ress => {
                              let conditions = {
                                expect:[],
                                type:ress.type,
                                key:"",
                                relation:null,
                              }
                              if(ress.type == 'ALL'){
                                conditions.type = ress.type
                              } else if(ress.type == 'STAFF_OR_DEPARTMENT'){
                                conditions.expect = ress.expect
                                conditions.type = ress.type
                                conditions.relation = ress.relation
                              } else if(ress.type == 'CUSTOMIZE'){
                                conditions.expect = ress.expect
                                conditions.type = ress.type
                                conditions.key =  ress.key;
                                conditions.relation = ress.relation
                              }
                              return conditions
                            })
                          }
                          return obj
                        }) : []
                      }
                        botsArr.push(obj)
                    } else {
                        botsArr.push({
                            apiKey: bot.apiKey,
                            botId: bot.id || bot.botId,
                            name: bot.name,
                            scope: bot.scope,
                            items: this.bindBotsAppType === 'eWx' ? itemsCopy : bot.items,
                            conditions:conditionsCopy,
                            botType: bot.botType || 0
                        })
                    }

                }

            })
            if (botsArr.length == 0) {
                this.$message.error("请至少绑定一个机器人");
            } else {
                let flag = true;
                botsArr.forEach(item => {
                    // if (item.scope == 1) {
                    //     console.log(item.items);
                    //     if (item.items == null || item.items.length == 0) {
                    //         this.$message.error("请选择组织结构！");
                    //         flag = false;
                    //     }
                    // }
                    if(item.botId === ''){
                        this.$message.error("请选择要绑定的机器人！");
                        flag = false;
                    }
                })
                if (this.bindBotsAppType == 'website' && botsArr.length > 1) {
                    if(!this.bindActiveAppItem.needLogin){
                        this.$message.error("只有需要登录的网站才能绑定多个机器人以及分配咨询机器人权限");
                        flag = false;
                    } else {
                        flag = true;
                    }
                }
                if(!conditionsFlag) {
                    this.$message.warning('请至少保留一个条件');
                    return false;
                }
                if(!flag || !conditionsFlag) return;
                this.FetchPost(
                this.requestUrl.bindV2.addBindBot,
                {
                    bindCode: this.bindActiveAppItem.bindCode,
                    bindId: this.bindActiveAppItem.id,
                    botInfos: botsArr,
                    enableKnowledgeSearch:this.enableKnowledgeSearch
                }
                ).then((res) => {
                        if (res.code === "0") {
                            this.$message.success('保存成功');
                            this.bindBotDialogVisible = false;
                            if (this.bindBotsAppType == 'website') {
                                this.getBindWebsiteListV2();
                            } else {
                                // 钉钉群
                                if (this.dingdingGroupDialog) {
                                    this.$refs.dingdinggroup.getDingdingGroupBindList();
                                }
                                if (this.isGroupList) {
                                    this.$refs.bindeddingdinggroup.getDingdingGroupBindList();
                                    this.$refs.dingdinggroup.getDingdingGroupBindList('isGroupList');
                                }
                                // 飞书
                                if (this.feishuDialog) {
                                    this.getFeishuBindList();
                                }
                                this.getBindList();
                                this.getDingdingBindList();
                                this.getDingdingSingBindList();
                                this.getBindWechatList();
                                this.getYunzhijiaBindList();
                                this.getImBindList();
                                // 微信客服
                                if (this.wxKfDialog) {
                                    this.$refs.wxkf.getWxKfAppList();
                                }
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                        this.loading = false;
                    });
            }

        },
        // 选择待选机器人
        handleCheckedBotsChange(value, item, type) {
            console.log(value, item, type)
            let ids = []
            let botType = "";
            if(type !== 'del'){
                this.noBindBots.forEach((v, i) => {
                    if (value == v.id && !v.isChecked) {
                        item.apiKey = v.apiKey;
                        item.name = v.name;
                        item.botId = value;
                        item.isChecked = true;
                        setTimeout(() => {
                            this.checkedBots = [];
                        }, 200);
                    }

                });

                // 新增 知识机器人
                this.combineNoBindBots.forEach(cBots => {
                    cBots.children.forEach( v =>{
                        if (value == v.id && !v.isChecked) {
                            item.apiKey = v.apiKey || "";
                            item.name = v.name;
                            item.botId = value;
                            item.isChecked = true;

                            botType = v.botType;

                            setTimeout(() => {
                                this.checkedBots = [];
                            }, 200);
                        }
                    })
                })
            }
            this.bindBots.forEach(item => {
                console.log(item);
                ids.push(item.botId)
                if(item.botId == value){
                    item.botType = botType;
                }
            })
            this.noBindBots.forEach((allItem,allIndex) => {
                if(ids.indexOf(allItem.id) !== -1){
                    allItem.isChecked = true
                } else {
                    allItem.isChecked = false
                }
            })

            // 新增 知识机器人
            // this.combineNoBindBots.forEach(cBots => {
            //     cBots.children.forEach( allItem =>{
            //         if(ids.indexOf(allItem.id) !== -1){
            //             allItem.isChecked = true
            //         } else {
            //             allItem.isChecked = false
            //         }
            //     })
            // })
        },
        // 取消选择机器人
        deleteBindBotToNo(index) {
            this.bindBots[index].id = this.bindBots[index].botId?this.bindBots[index].botId:this.bindBots[index].id;
            this.noBindBots.push(this.bindBots[index]);
            this.bindBots.splice(index, 1);
            let tampArr = JSON.parse(JSON.stringify(this.noBindBots))
            this.noBindBots = [...[]];
            this.noBindBots = tampArr;
        },
        // 绑定钉钉单聊
        bindDingdingSing(){
            this.dingdingSingDialogFormVisible = true;
            this.dingdingSingBindForm = {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
            };
        },
        // 绑定钉钉
        bindDingding() {
            this.dingdingDialogFormVisible = true;
            this.dingdingBindForm = {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
            };
        },
        // 绑定钉钉群
        bindDingdingGroup() {
            this.dingdinggroupFormVisible = true;
            this.dingdingGroupBindForm = {
                agentId: "",
                corpId: "",
                corpName: "",
                secret: "",
                appKey: "",
            };
        },
        //云之家绑定
        bindYunzhijia(){
          this.yunzhijiaDialogFormVisible = true;
          this.yunzhijiaBindForm = {
            appId:"",
            appName:"",
            appSecret:"",
            contactSecret:"",
            corpId:"",
            corpName:"",
            signKey:""
          }
        },
        // 钉钉单聊绑定企业
        getAuthUrlDingdingSing() {
            this.dingdingSingDialogFormVisible = true;
            if (
                this.dingdingSingBindForm.agentId &&
                this.dingdingSingBindForm.corpId &&
                this.dingdingSingBindForm.corpName &&
                this.dingdingSingBindForm.secret &&
                this.dingdingSingBindForm.appKey &&
                this.dingdingSingBindForm.agentName
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingSingBind.getDingtalkSingAuthUrl,
                    {
                        agentId: this.dingdingSingBindForm.agentId.trim(),
                        corpId: this.dingdingSingBindForm.corpId.trim(),
                        corpName: this.dingdingSingBindForm.corpName.trim(),
                        appSecret: this.dingdingSingBindForm.secret.trim(),
                        appKey: this.dingdingSingBindForm.appKey,
                        agentName: this.dingdingSingBindForm.agentName,
                        isCheckAgent: false,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dingdingSingDialogFormVisible = false;
                        this.getDingdingSingBindList();
                        this.dingdingSingBindForm.agentId = "";
                        this.dingdingSingBindForm.corpId = "";
                        this.dingdingSingBindForm.corpName = "";
                        this.dingdingSingBindForm.secret = "";
                        this.dingdingSingBindForm.appKey = "";
                        this.dingdingSingBindForm.agentName = "";
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
        // 钉钉绑定企业
        getAuthUrlDingding() {
            this.dingdingDialogFormVisible = true;
            // let botId = this.$route.query.id;
            // let apiKey = this.$route.query.apiKey;
            if (
                this.dingdingBindForm.agentId &&
                this.dingdingBindForm.corpId &&
                this.dingdingBindForm.corpName &&
                this.dingdingBindForm.secret &&
                this.dingdingBindForm.appKey &&
                this.dingdingBindForm.agentName
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingBind.getDingtalkAuthUrl,
                    {
                        agentId: this.dingdingBindForm.agentId.trim(),
                        corpId: this.dingdingBindForm.corpId.trim(),
                        corpName: this.dingdingBindForm.corpName.trim(),
                        appSecret: this.dingdingBindForm.secret.trim(),
                        appKey: this.dingdingBindForm.appKey,
                        agentName: this.dingdingBindForm.agentName,
                        isCheckAgent: false,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dingdingDialogFormVisible = false;
                        this.getDingdingBindList();
                        this.dingdingBindForm.agentId = "";
                        this.dingdingBindForm.corpId = "";
                        this.dingdingBindForm.corpName = "";
                        this.dingdingBindForm.secret = "";
                        this.dingdingBindForm.appKey = "";
                        this.dingdingBindForm.agentName = "";
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
        // 钉钉群绑定企业
        getAuthUrlDingdingGroup() {
            this.dingdinggroupFormVisible = true;
            // let botId = this.$route.query.id;
            // let apiKey = this.$route.query.apiKey;
            if (
                this.dingdingGroupBindForm.agentId &&
                this.dingdingGroupBindForm.corpId &&
                this.dingdingGroupBindForm.corpName &&
                this.dingdingGroupBindForm.secret &&
                this.dingdingGroupBindForm.appKey &&
                this.dingdingGroupBindForm.agentName
            ) {
                this.FetchPost(
                    this.requestUrl.dingdingBind.getDingtalkAuthUrl, // todo 替换为钉钉群
                    {
                        agentId: this.dingdingGroupBindForm.agentId.trim(),
                        corpId: this.dingdingGroupBindForm.corpId.trim(),
                        corpName: this.dingdingGroupBindForm.corpName.trim(),
                        appSecret: this.dingdingGroupBindForm.secret.trim(),
                        appKey: this.dingdingGroupBindForm.appKey,
                        agentName: this.dingdingGroupBindForm.agentName,
                        isCheckAgent: false,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dingdinggroupFormVisible = false;
                        this.getDingdingGroupBindList();
                        this.dingdingGroupBindForm.agentId = "";
                        this.dingdingGroupBindForm.corpId = "";
                        this.dingdingGroupBindForm.corpName = "";
                        this.dingdingGroupBindForm.secret = "";
                        this.dingdingGroupBindForm.appKey = "";
                        this.dingdingGroupBindForm.agentName = "";
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },

        //云之家绑定企业
        getAuthUrlYunzhijia(){
          if (this.yunzhijiaBindForm.corpName &&
              this.yunzhijiaBindForm.corpId &&
              this.yunzhijiaBindForm.appId &&
              this.yunzhijiaBindForm.appName &&
              this.yunzhijiaBindForm.appSecret &&
              this.yunzhijiaBindForm.signKey){
            this.FetchPost(this.requestUrl.dingdingBind.getYunzhijiaAuthUrl,this.yunzhijiaBindForm).then(res=>{
              console.debug('res',res)
              if (res.code == '0'){
                this.authUrl = res.data;
                this.$message.success("绑定成功");
                this.getYunzhijiaBindList();
                this.yunzhijiaDialogFormVisible = false;
              }
            })
          } else {
            this.$message({
              message:"绑定信息不能为空,请检查",
              type:"error",
              duration:2000
            })
          }
        },
        // 查看钉钉单聊已绑定企业信息
        showDingdingSingBindInfo(item) {
            this.dingdinSingInfoDialogVisible = true;
            this.dingdingSingBindForm = item;
        },
        // 查看钉钉已绑定企业信息
        showDingdingBindInfo(item) {
            this.dingdinInfoDialogVisible = true;
            this.dingdingBindForm = item;
        },
        // 查看钉钉群已绑定企业信息
        showDingdingGroupBindInfo(item) {
            this.dingdinGroupInfoDialogVisible = true;
            this.dingdingGroupBindForm = item;
        },
        //查看云之家已绑定企业信息
        showYunzhijiaBindInfo(item){
          console.debug('item',item)
          this.yunzhijiaInfoDialogVisible = true;
          this.yunzhijiaBindForm = item;
        },
        // 钉钉单聊应用解绑
        postDingdingSingUnbind(id) {
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getDingdingSingBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 钉钉应用解绑
        postDingdingUnbind(id,type, item) {
            if(item.commonEntry != 1 || this.askEntry == 1){
                this.$confirm(
                    "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
                )
                    .then((_) => {
                        this.FetchPost(this.requestUrl.dingdingBind.unBind, {
                            bindId: id,
                        }).then((res) => {
                            if (res.code === "0") {
                                this.$message.success("解绑成功");
                                if (type === 'yunzhijia'){
                                  this.getYunzhijiaBindList();
                                } else {
                                  this.getDingdingBindList();
                                }
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    })
                    .catch((_) => {});
            } else {
                this.showMnDdLimitTip();
            }
        },
        // 钉钉群应用解绑
        postDingdingGroupUnbind(id) {
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.dingdingBind.unBind, { // todo 改为钉钉群
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getDingdingGroupBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        // 获取钉钉单聊绑定列表
        getDingdingSingBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "9").then((res) => {
                if (res.code === "0") {
                    this.dingdingSingH5AppList = [...res.data];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取钉钉绑定列表
        getDingdingBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "3").then((res) => {
                if (res.code === "0") {
                    res.data.forEach(item => {
                        if(this.openDrawerType === 'DING' && this.$route.query.bindCode === item.bindCode){
                            this.setAskbotWelcomeMessage(item);
                            this.replaceUrl('bindCode')
                        }
                    })
                    this.dingdingH5AppList = [...res.data];

                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取钉钉群绑定列表
        getDingdingGroupBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "3").then((res) => { // todo 改为请求钉钉群
                if (res.code === "0") {
                    this.dingdingGroupAppList = [...res.data];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取Ip地址
        getBindDingdingIp() {
            this.FetchGet(
                this.requestUrl.dingdingBind.dingtalkWhitelist,
                {}
            ).then((res) => {
                if (res.code === "0") {
                    this.dingtalkWhite = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取云之家绑定列表
        getYunzhijiaBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "13").then((res) => {
                if (res.code === "0") {
                    res.data.forEach(item => {
                        if(this.openDrawerType === 'YUN_ZHI_JIA' && this.$route.query.bindCode === item.bindCode){
                            this.setAskbotWelcomeMessage(item);
                            this.replaceUrl('bindCode')
                        }
                    })
                    this.yunzhijiaList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        getzhiyuanBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "22").then((res) => {
                if (res.code === "0") {
                    if(!res.data[0].bindBot || !res.data[0].bindBot.botInfos || res.data[0].bindBot.botInfos.length == 0) {
                        this.bindZhiYuanBots =  [{
                            items:[],
                            scope:0,
                            apiKey:'',
                            botId: '',
                            name: '',
                            botProfilePhoto: null,
                            conditions:[{
                                    visible:false,
                                    type:'ALL',
                                    expect:[],
                                    key:"",
                                    relation:"CONTAIN_ANY",
                                }]
                        }]
                    } else {
                        res.data[0].bindBot && res.data[0].bindBot.botInfos.forEach(botInfosItem => {
                            if(!botInfosItem.conditions){
                                botInfosItem.conditions = [{
                                    visible:false,
                                    type:'ALL',
                                    expect:[],
                                    key:"",
                                    relation:"CONTAIN_ANY",
                                }]
                            }
                        })
                        this.bindZhiYuanBots = res.data[0].bindBot.botInfos
                    }
                    this.bindBotsAppType = 'eWx'
                    this.activeCorpId = res.data[0].corpId;
                    this.bindActiveAppItem = res.data[0]
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取Im绑定列表
        getImBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "20").then((res) => {
                if (res.code === "0") {

                    if(res.data && res.data.length > 0) {
                        if(!res.data[0].bindBot.botInfos || res.data[0].bindBot.botInfos.length == 0) {
                            this.bindImBots =  [{
                                items:[],
                                scope:0,
                                apiKey:'',
                                botId: '',
                                name: '',
                                botProfilePhoto: null,
                                conditions:[]
                            }]
                        } else {
                            res.data[0].bindBot.botInfos.forEach(botInfosItem => {
                                if(!botInfosItem.conditions){
                                    botInfosItem.conditions = [{
                                        visible:false,
                                        type:'ALL',
                                        expect:[],
                                        key:"",
                                        relation:"CONTAIN_ANY",
                                    }]
                                }
                            })
                            this.bindImBots = res.data[0].bindBot.botInfos
                        }
                        this.bindBotsAppType = 'eWx'
                        this.activeCorpId = res.data[0].corpId;
                        this.bindActiveAppItem = res.data[0]
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        addDingdingMp() {
            this.isFwh = false;
            // this.getDepartmentTree();
            this.dingdingDialog = true;
            this.getDingdingBindList();
            this.getBindDingdingIp();
        },
        // 点击钉钉群面板
        addDingdingGroup(){
            this.isFwh = false;
            // this.getDepartmentTree();
            this.dingdingGroupDialog = true;
            this.$nextTick(() => {
                this.$refs.dingdinggroup.getDingdingGroupBindList();
            })
            // this.getBindDingdingIp();
        },
        // 接入钉钉单聊
        addDingdingSing() {
            this.isFwh = false;
            // this.getDepartmentTree();
            this.dingdingSingDialog = true;
            this.getDingdingSingBindList();
            this.getBindDingdingIp();
        },
        // 点击云之家
        adYunzhijiaMp(){
            this.isFwh = false;
            //   this.getDepartmentTree();
            this.yunzhijiaDrawer = true;
            this.getYunzhijiaBindList();
        },
        addZhiYuanOA() {
            this.isFwh = false;
            this.zhiyuanDrawer = true;
            this.getzhiyuanBindList();
        },
        adPCIM() {
            // this.isFwh = false;
            this.pcImVisable = true;
            // this.getDepartmentTree();
            this.getImBindList()
        },
        addBindImBots() {
            this.bindImBots.push({
                items:[],
                scope:0,
                apiKey:'',
                botId: '',
                name: '',
                botProfilePhoto: null,
                conditions:[{
                    visible:false,
                    type:'ALL',
                    expect:[],
                    key:"",
                    relation:"CONTAIN_ANY",
                }]
            })
            this.saveImBindBots()
        },
        addBindZhiYuanBots() {
            this.bindZhiYuanBots.push({
                items:[],
                scope:0,
                apiKey:'',
                botId: '',
                name: '',
                botProfilePhoto: null,
                conditions:[{
                    visible:false,
                    type:'ALL',
                    expect:[],
                    key:"",
                    relation:"CONTAIN_ANY",
                }]
            })
            this.saveZhiYuanBindBots()
        },
        bindImClickDelete(index) {
            this.bindImBots.splice(index, 1)
            if(this.bindImBots.length == 0) {
                this.addBindZhiYuanBots()
            }
            this.saveImBindBots('del')
        },
        bindZhiYuanClickDelete(index) {
            this.bindZhiYuanBots.splice(index, 1)
            if(this.bindZhiYuanBots.length == 0) {
                this.addBindImBots()
            }
            this.saveZhiYuanBindBots("del")
        },
        changeImBindBot(value, index) {
            let bot = this.noBindBots.find(item =>{
                return item.id == value
            })
            if(bot) {
                // this.bindImBots[index].items = bot
                this.bindImBots[index].botId = bot.id
                this.bindImBots[index].name = bot.name
                this.bindImBots[index].apiKey = bot.apiKey
                this.bindImBots[index].botProfilePhoto = bot.botProfilePhoto
                this.bindImBots[index].scope = 0
                this.saveImBindBots()
            }
        },
        changeZhiYuanBindBot(value, index) {
            let bot = this.noBindBots.find(item =>{
                return item.id == value
            })
            if(bot) {
                // this.bindImBots[index].items = bot
                this.bindZhiYuanBots[index].botId = bot.id
                this.bindZhiYuanBots[index].name = bot.name
                this.bindZhiYuanBots[index].apiKey = bot.apiKey
                this.bindZhiYuanBots[index].botProfilePhoto = bot.botProfilePhoto
                this.bindZhiYuanBots[index].scope = 0
                this.saveZhiYuanBindBots()
            }
        },
        changeImBindScope(value, item) {
            if(value == 0 && item.botId) {
                item.items = []
                this.saveImBindBots()
            }
        },
        // 微信客服弹窗
        addWxKfMp(){
            this.wxKfDialog = true;
        },
        // 钉钉应用复制链接
        copyDingdingUrl(urlType, copyclass, url) {
            var Url2 = document.getElementById(urlType).innerText;
            console.log(Url2);
            var copyclass = document.getElementById(copyclass);
            console.log(copyclass);
            var oInput = document.createElement("input");
            // oInput.value = Url2;
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success("复制成功！");
        },
        showtips() {
            this.$message.warning(
                "该公众号为订阅号，只有认证的服务号才可推送消息！"
            );
        },
        // 更改推送
        changeAllowPushMsg($event, item) {
            console.log($event, item);
            this.FetchPost(
                this.requestUrl.wechatBind.changeAllowPushMsg +
                    item.id +
                    "?status=" +
                    $event
            ).then((res) => {
                if (res.code === "0") {
                    console.log(res.data);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //获取机器人设置状态
        getBotInfo() {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 1000,
            }).then((res) => {
              if (res.code === "0") {
                this.miniAppBots = res.data.list;
                this.miniAppBots.forEach(bot => {
                    if (bot.setting.showAppletEntrance == null) {
                        bot.setting.showAppletEntrance = false;
                    }
                })
              } else {
                this.$message.error(res.message);
              }
              this.loading = false;
            });
        },
        //提交小程序设置
        updateBot(e, id) {
            let update = {
                botSetting: {
                    showAppletEntrance: e,
                },
            };
            console.log(update);
            this.FetchPut(
                this.requestUrl.bot.updateBot,
                id,
                update
            ).then((res) => {
                if (res.code === "0") {
                    this.getBotInfo();
                    this.$message.success("更新成功！");
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 下载/预览小程序码
        miniAppCodeHandle(index, id, type){
            this.FetchGet(this.requestUrl.miniProgramBind.wxMiniCode, {
                bid: id,
            }).then((res) => {
                if (res.code === "0") {
                    if (type == "download") {
                        window.location = res.data;
                    } else {
                        this.miniAppBots[index].miniAppCodeUrl = res.data;
                        this.miniAppBots = [...this.miniAppBots];
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 批量上传
        // 上传成功后的回调
        uploadSuccess(response, file, fileList) {
            this.$message.success("上传成功");
            console.log(response, file, fileList);
            // if (response.code === "0") {
            //   this.progressDialogVisible = true;
            //   this.uploadRequestUUID = response.data;
            //   this.reqProcessTimer = setInterval(() => {
            //     this.requestUploadProcess();
            //   }, 2000);
            // }
        },
        requestUploadProcess() {
            if (
                this.progressDialogVisible == null ||
                this.progressDialogVisible === false
            ) {
                clearInterval(this.reqProcessTimer);
                return;
            }
            this.FetchGet(
                this.requestUrl.intentQuestion.batchuploadIntentQProcess,
                {
                    uid: this.uploadRequestUUID,
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.uploadProgress = res.data;
                    if (res.data == 100) {
                        this.progressDialogVisible = false;
                        clearInterval(this.reqProcessTimer);
                        this.currentPage = 1;
                        this.getIntentQList();
                    }
                } else {
                    this.$message.error(res.message);
                    this.progressDialogVisible = false;
                }
            });
        },
        // 上传错误
        uploadError(response, file, fileList) {
            if (response.code !== "0") {
                this.$message.error("上传失败，请重试!");
            }
        },
        // 上传前对文件的大小的判断
        beforeAvatarUpload(file) {
            console.log(file.name);
            const extension = file.name.split(".")[1] === "txt";
            // const extension2 = file.name.split(".")[1] === "xlsx";
            // const extension3 = file.name.split(".")[1] === "doc";
            // const extension4 = file.name.split(".")[1] === "docx";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!extension) {
                this.$message.error("上传模板只能是 txt 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传模板大小不能超过 2MB!");
            }
            return extension && isLt2M;
        },
        resetStatus() {
            this.warningVisible = false;
        },
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            clearTimeout(this.timer);
        },
        handleClose(done) {
            this.$confirm("还有未保存的工作哦确定关闭吗？")
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },
        // 查看绑定信息
        showBindInfo(item) {
            this.infoDialogVisible = true;
            this.bindForm = item;
        },
         // 查看绑定信息
        showFeishuBindInfo(item) {
            this.feishuInfoDialogVisible = true;
            this.feishuBindForm = item;
        },
        // 复制域名
        copyDomain(urlType, copyclass) {
            var Url2 = document.getElementById(urlType).innerText;
            var copyclass = document.getElementById(copyclass);
            var oInput = document.createElement("input");
            oInput.value = Url2;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success("复制成功！");
            oInput.className = "oInput";
            oInput.style.display = "none";
            copyclass.className = "copyEnd";
            copyclass.innerHTML = "已复制";
            setTimeout(function () {
                copyclass.className = "copy";
                copyclass.innerHTML = "复制";
            }, 3000);
        },
        // 复制链接
        copy(urlType, copyclass, url) {
            console.log(url);
            var Url2 = document.getElementById(urlType).innerText;
            console.log(Url2);
            var copyclass = document.getElementById(copyclass);
            console.log(copyclass);
            var oInput = document.createElement("input");
            // oInput.value = Url2;
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            document.execCommand("Copy"); // 执行浏览器复制命令
            this.$message.success("复制成功！");
        },
        getAuthDomain() {
            let botId = this.$route.query.id;
            this.FetchGet(this.requestUrl.eWechatBind.getAuthDomain, {
                botId: botId,
            }).then((res) => {
                if (res.code === "0") {
                    this.authDomain = res.data;
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        getOpenDomain() {
            let botId = this.$route.query.id;
            this.FetchGet(this.requestUrl.dingdingBind.getOpenDomain, {}).then(
                (res) => {
                    if (res.code === "0") {
                        this.openDomain = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }
            );
        },
        addBindMiniProg() {
            this.isFwh = false;
            this.xcxdrawer = true;
            this.getBotInfo();
        },
        addBindAPP() {
            this.isFwh = false;
            this.APPdrawer = true;
        },

        addBindWebsite() {
            // this.showNumWeb++;
            // this.showNumWeb = sessionStorage.setItem('showNumWeb',this.showNumWeb);
            this.websitedrawer = true;
            this.isFwh = false;
            // this.getDepartmentTree();
            // this.getBindWebsiteList();
            this.getBindWebsiteListV2();
        },
        //获取绑定列表
        getBindWebsiteListV2() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "4").then((res) => {
                if (res.code === "0") {
                    res.data.forEach((item,index) => {
                        if(!item.corpId || item.corpId === '' || item.corpId === null){
                            item.corpId = this.loginModeOptions[0]&&this.loginModeOptions[0].corpId || '';
                            item.iconType = this.loginModeOptions[0]&&this.loginModeOptions[0].corpType || null;
                        } else {
                            this.loginModeOptions.forEach(v => {
                                if(v.corpId === item.corpId){
                                    item.iconType = v.corpType;
                                }
                            })
                        }
                        if(this.openDrawerType === 'WEBSITE' && this.$route.query.bindCode === item.bindCode){
                            this.setAskbotWelcomeMessage(item);
                            this.replaceUrl('bindCode')
                        }
                    })
                    this.bindWebsiteList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        addWebsiteBindV2() {
            let flag = true;
            if(this.websiteBindForm.websiteName === '' ){
                flag = false;
                this.$message.warning('请输入网站名称');
                return false;
            }
            if(this.websiteBindForm.domain === ''){
                flag = false;
                this.$message.warning('请输入网站域名');
                return false;
            }
            if(!flag) return false;
            let objData = {
                domain: this.websiteBindForm.domain,
                websiteName: this.websiteBindForm.websiteName,
                channel:0,
                corpId:this.loginModeOptions[0]&&this.loginModeOptions[0].corpId||'',
                corpName:this.loginModeOptions[0]&&this.loginModeOptions[0].corpName||''
            };
            if(this.loginModeOptions.length > 0){
                objData.channel = Number(this.loginModeOptions[0].corpType);
            } else {
                objData.channel = null
            }
            console.log(objData);
            this.FetchPost(
                this.requestUrl.bindV2.addWebsiteApp,
                objData,
                { emulateJSON: true }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("添加成功");
                    this.addWebsiteDialogVisible = false;
                    this.websiteBindForm.domain = "";
                    this.websiteBindForm.websiteName = "";
                    // this.getBindWebsiteList();
                    this.getBindWebsiteListV2();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        addWebsiteBind() {
            let objData = {
                botId: this.$route.query.id,
                domain: this.websiteBindForm.domain,
                websiteName: this.websiteBindForm.websiteName,
            };
            console.log(objData);
            this.FetchPost(
                this.requestUrl.websiteBind.addWebsiteBind,
                objData,
                { emulateJSON: true }
            ).then((res) => {
                if (res.code === "0") {
                    this.$message.success("添加成功");
                    this.addWebsiteDialogVisible = false;
                    this.websiteBindForm.domain = "";
                    this.websiteBindForm.websiteName = "";
                    // this.getBindWebsiteList();
                    this.getBindWebsiteListV2();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // Copy成功
        onCopy(e) {
            this.$message.success("内容已复制到剪切板！");
        },
        // Copy失败
        onError(e) {
            this.$message.error("抱歉，复制失败！");
        },
        // 删除绑定网站
        deleteWebsiteBind(id) {
            console.log("id:" + id);
            this.$confirm(
                "删除后，该网站将无法继续使用该机器人，是否继续?",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.FetchPost(
                        this.requestUrl.bindV2.unbindApp,
                        {bindId: id}
                    ).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("删除成功");
                            // this.getBindWebsiteList();
                            this.getBindWebsiteListV2();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 点击飞书接入面板
        getFeishuList() {
            this.feishuDialog = true;
            this.getFeishuBindList();
            // this.bindForm.agentId = "";
            // this.bindForm.bindType = "";
            // this.bindForm.corpId = "";
            // this.bindForm.corpName = "";
            // this.bindForm.secret = "";
        },

        // 点击新建绑定机器人
        addBindCompany() {
            this.dialog = true;
            this.isFwh = false;
            // this.getDepartmentTree();
            this.getBindList();
            this.bindForm.agentId = "";
            this.bindForm.bindType = "";
            this.bindForm.corpId = "";
            this.bindForm.corpName = "";
            this.bindForm.secret = "";
        },
        getAuthUrl() {
            this.dialogFormVisible = true;
            if (
                (this.bindForm.agentId,
                this.bindForm.corpId,
                this.bindForm.corpName,
                this.bindForm.secret)
            ) {
                this.FetchGet(this.requestUrl.eWechatBind.getAuthUrl, {
                    agentId: this.bindForm.agentId.trim(),
                    bindType: this.bindForm.bindType || "",
                    corpId: this.bindForm.corpId.trim(),
                    corpName: this.bindForm.corpName.trim(),
                    secret: this.bindForm.secret.trim(),
                }).then((res) => {
                    if (res.code === "0") {
                        this.authUrl = res.data;
                        this.$message.success("绑定成功");
                        this.dialogFormVisible = false;
                        this.getBindList();
                        this.bindForm.agentId = "";
                        this.bindForm.bindType = "";
                        this.bindForm.corpId = "";
                        this.bindForm.corpName = "";
                        this.bindForm.secret = "";
                        console.log(this.bindForm.corpName);
                    } else if (res.code === "0OPBE0006") {
                        this.warningVisible = true;
                    } else {
                        // this.$message.error(res.message);
                        this.$message.error("绑定失败，请检查填写内容");
                    }
                    this.loading = false;
                });
            } else {
                this.$message.error("绑定信息不能为空，请检查！");
            }
        },
        //获取绑定列表
        getBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + '0').then((res) => {
                if (res.code === "0") {
                    res.data.forEach(item => {
                        if(this.openDrawerType === 'E_WECHAT' && this.$route.query.bindCode === item.bindCode){
                            this.setAskbotWelcomeMessage(item);
                            this.replaceUrl('bindCode')
                        }
                    })
                    this.bindList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //获取绑定列表
        getFeishuBindList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + '10').then((res) => {
                if (res.code === "0") {
                    res.data.forEach(item => {
                        if(this.openDrawerType === 'FEISHU' && this.$route.query.bindCode === item.bindCode){
                            this.setAskbotWelcomeMessage(item);
                            this.replaceUrl('bindCode')
                        }
                    })
                    this.bindFeishuList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 应用解绑
        postUnbind(id) {
            console.log(id);
            this.$confirm(
                "解绑后，该自建应用将无法继续使用该机器人，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.eWechatBind.postUnbind, {
                        bindId: id,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getBindList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        //===============================================================公众号绑定===============================================================
        addWechatMp() {
            this.getOrganization();
            this.isFwh = true;
            this.wechatMpBindForm = {};
            this.wechatmpdialog = true;
            this.getBindWechatList();
        },
        // 查看绑定信息
        showWechatMpBindInfo(item) {
            this.wechatMpShowDialogVisible = true;
            this.wechatMpBindForm = item;
        },
        // 删除公众号绑定
        deleteWechatMpBind(bindId) {
            this.$confirm(
                "请前往微信微信公众号后台进行解绑，解绑后机器人将不再接收用户消息，是否继续解绑？"
            )
                .then((_) => {
                    this.FetchPost(this.requestUrl.wechatBind.unbind, {
                        bindId: bindId,
                    }).then((res) => {
                        if (res.code === "0") {
                            this.$message.success("解绑成功");
                            this.getBindWechatList();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                })
                .catch((_) => {});
        },
        selectAuthType(type) {
            if (type == "0") {
                //第三方方法
                this.wechatMpAuthDialog = false;
                // this.publicThirdAuthType=this.$refs.authTypeInfo.dataset.authtype;
                // console.log(this.publicThirdAuthType);
                this.bindWechatByThirdAuth();
            } else if (type == "1") {
                this.wechatMpAuthDialog = false;
                this.developerDialog = true;
            }
        },
        getBindWechatList() {
            this.FetchGet(this.requestUrl.bindV2.channelAccessList + "1").then((res) => {
                if (res.code === "0") {
                    res.data.forEach(item => {
                        if(this.openDrawerType === 'P_WECHAT' && this.$route.query.bindCode === item.bindCode){
                            this.setAskbotWelcomeMessage(item);
                            this.replaceUrl('bindCode')
                        }
                    })
                    this.wechatMpbindList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        bindWechatBySecret() {
            // let botId = this.$route.query.id;
            this.FetchGet(this.requestUrl.wechatBind.getAuthUrl, {
                // botId: botId,
                bindType: 1,
                appId: this.wechatMpBindForm.agentId.trim(),
                appName: this.wechatMpBindForm.agentName.trim(),
                secret: this.wechatMpBindForm.secret.trim(),
            }).then((res) => {
                if (res.code === "0") {
                    this.$message.success("绑定成功");
                    this.developerDialog = false;
                    this.getBindWechatList();
                    this.wechatMpBindForm = {};
                } else {
                    this.$message.error("绑定失败，请检查填写内容");
                }
            });
        },
        bindWechatByThirdAuth(e) {
            this.publicThirdAuthType = this.$refs.authTypeInfo.dataset.authtype;
            console.log(this.publicThirdAuthType);
            // let botId = this.$route.query.id;
            let currentUrl = window.location.href;
            if (currentUrl.indexOf("tabIndex") == -1) {
                currentUrl += "?tabIndex=bot-access";
            }
            if (currentUrl.indexOf("bindType") == -1) {
                currentUrl += "&bindType=mp";
            }
            console.log("get redirect url:", currentUrl);
            this.FetchGet(this.requestUrl.wechatBind.getAuthUrl, {
                // botId: botId,
                bindType: 0,
                currentUrl: currentUrl,
                authType: this.publicThirdAuthType, //1:公众号 2：小程序 3：公众号和小程序
            }).then((res) => {
                if (res.code === "0") {
                    if (res.data) {
                        location.href = res.data;
                        // window.open(res.data);
                    }
                } else {
                    this.$message.error("绑定失败，请检查填写内容");
                }
            });
        },
        //===============================================================================================================================================
        // 点击操作部分
        bindHandleCommand(command) {
            if (command.type === 1) {
                this.dialogVisible = true;
                this.agentId = this.bindList[command.index].agentId;
                this.agentName = this.bindList[command.index].agentName;
                this.corpName = this.bindList[command.index].corpName;
                this.corpId = this.bindList[command.index].corpName;
                this.secret = this.bindList[command.index].secret;
            }
            if (command.type === 2) {
            }
        },
        closeWelcomeMessage() {
            this.welcomeMessageDate = null
            this.welcomeMessageVisiable = false
        },
        sureWelcomeType() {
            this.welcomeMessageVisiable = false
            if(this.welcomeMessageType == 'askbot') {
                this.setAskbotWelcomeMessage(this.welcomeMessageDate)
            } else {
                this.$router.push({
                    path: '/main/knowledgeWelcomeMessage',
                    query:{
                        bindCode: this.welcomeMessageDate.bindCode,
                        agentName: this.welcomeMessageDate.agentName,
                        wechatType: this.welcomeMessageDate.wechatType
                    }
                })
            }
        },
        checkWelcomType() {
            this.$router.push({
                    path: '/main/knowledgeWelcomeMessage',
                    query:{
                        bindCode: this.welcomeMessageDate.bindCode,
                        agentName: this.welcomeMessageDate.agentName,
                        wechatType: this.welcomeMessageDate.wechatType
                    }
                })
        }
    },
    mounted() {
        this.askEntry = this.$route.query.askEntry;
        this.judgeMain()
        this.uid = localStorage.getItem('_uid')
        this.getCorps4main();
        window.addEventListener("click", () => {
            if (this.isFwh) {
                this.activeTreeIndex = null;
            }
        });
        //this.getEWeChatAuth();
        // this.getAuthUrl();
        this.$nextTick(() => {
            let bindType = this.$route.query.bindType;
            if (bindType == "mp") {
                this.wechatmpdialog = true;
                this.getBindWechatList();
            }
        });
        this.getBotList();
        this.getAuthDomain();
        this.getOpenDomain();
        this.getDepartmentTree();
        this.botVersion = localStorage.getItem("_bot_version");
        if(this.$route.query.isback && this.$route.query.isback == 1){
            this.replaceUrl('isback');
            this.addBindWebsite();
        }
        this.openDrawerType = this.$route.query.openDrawerType
        // WEBSITE网站 E_WECHAT企微   P_WECHAT公众号  DING钉钉   FEISHU飞书,  YUN_ZHI_JIA云之家
        if(this.openDrawerType && this.openDrawerType !== ''){
            this.replaceUrl('openDrawerType');
            if(this.openDrawerType === 'WEBSITE'){
                this.addBindWebsite();
            } else if (this.openDrawerType === 'P_WECHAT'){
                this.addWechatMp()
            } else if (this.openDrawerType === 'E_WECHAT'){
                this.addBindCompany()
            } else if (this.openDrawerType === 'DING'){
                this.addDingdingMp()
            } else if (this.openDrawerType === 'FEISHU'){
                this.getFeishuList()
            } else if (this.openDrawerType === 'YUN_ZHI_JIA'){
                this.adYunzhijiaMp()
            } else if (this.openDrawerType === 'WEBCLIENT') {
                setTimeout(() =>{
                    this.addAskBotClient()
                }, 500)
            }
        }
        this.getUserCompanyModule();
    },
    watch:{
        bindBotDialogVisible(n){
            if(n){
                if(this.bindBotsAppType != 'wechatGZ' && this.bindBotsAppType != 'wxServ'){
                    if(this.bindBotsAppType == 'website'){
                        this.isShowConsult = this.bindActiveAppItem.needLogin ? true : false;
                    } else {
                        this.isShowConsult = true;
                    }
                } else {
                    this.isShowConsult = false;
                }
            }
        },
    }
};
</script>
<style lang="less">
@import "./../../assets/less/botIndex/botLinkV2.less";
#botIntent .el-dialog__wrapper {
    overflow-y: auto !important;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    height: 33px;
}
.el-popover.choose-conditions-poprver{
    z-index: 3333 !important;
    padding: 0;
    .el-tree{
        overflow-y: auto;
        color: #606266;
        .el-tree-node:first-child{
            .el-tree-node__expand-icon{
                display: none;
            }
            .data-label{
                padding-left: 10px;
            }

        }
        >.el-tree-node{
            .el-tree-node__content{
                line-height: 33px;
                height: auto;
                min-height: 30px;
                display: flex;
                align-items: center;
            }
            .el-tree-node__children{
                .el-tree-node__content {
                    .el-tree-node__expand-icon{
                        display: none !important;
                    }
                    .data-label{
                        padding-left: 10px;
                    }
                }
            }
        }

        .custom-tree-node{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            line-height: 26px;
            height: auto;
            color:#606266;
            white-space: normal;
            .data-label{
                line-height: 26px;
            }
            &.disabled{
                cursor: not-allowed;
            }
        }
    }
}
.tr-mn-name-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DCDFE6;
    height: 100%;
    border-radius: 4px;
    .tr-mn-name{
        flex: auto;
        margin-left: 10px;
    }
    i {
        flex: none;
        width: 50px;
        text-align: center;
    }
}

.bind-askbot-client-bind-bot-content {
    position: relative;
    .bind-askbot-client-bind-mode {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.welcome_message_type {
    #popup-assembly-new {
        width: 500px !important;
    }
    .popup_title {
        line-height: 28px;
        text-align: left;
        font-size: 16px;
    }
    .welcome_content {
        width: 100%;
        .welcome_type {
            width: 100%;
            height: 70px;
            border-radius: 15px;
            background: #f0f4ff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 12px 0;
            text-align: left;
            .welcome_left {
                width: 48px;
                height: 48px;
                border-radius: 10px;
                overflow: hidden;
                background: #606266;
                margin: 0 16px;
            }
            .welcome_right {
                // width: calc(100% - 80px);
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 16px;
                box-sizing: border-box;
                .welcome_right_title {
                    margin: 2px 0;
                }
                .welcome_right_tip {
                    margin: 2px 0;
                    color: #A9B3C6;
                }
            }
        }
        .welcomeActive {
            border: 1px solid #366AFF;
        }
    }
}
.authority-set-popup{
  /deep/.plugin-dropdown-item{
    width: 500px!important;
  }
  .identificationCondition{
    .el-tag{
      display: flex;
      align-items: center;
      .el-select__tags-text{
        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
