import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=0bfab75e"
import script from "./Link.vue?vue&type=script&lang=js"
export * from "./Link.vue?vue&type=script&lang=js"
import style0 from "./Link.vue?vue&type=style&index=0&id=0bfab75e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports