<template>
  <div class="bind_bot">
    <template v-if="bindBotList.length">
      <div :class="['bind-askbot-client-bind-bot-item condition-bind-askbot-client-bind-bot',isChildren ? 'bind-askbot-children-content' : '']"
           v-for="(item,index) in bindBotList"
           :key="index"
      >
        <div class="bind-askbot-client-bind-bot-item-content">
          <div class="del-bind-bot">
            <i v-if="item.botType !== undefined" class="iconfont guoran-tongyichicun-16-09-shanchu2"
               @click="delBindBot(item,index,'')"></i>
            <span v-else style="margin-left: 16px"> </span>
          </div>
          <div class="choose-bind-bot">
            <span class="choose-bind-bot-plugintool-name" v-if="item.botType === undefined">
              <span>{{ item.name }}</span>
            </span>
            <el-cascader v-else :ref="'cascader'+index" v-model="item.botId" :options="appCombineNoBindBots" :props="optionProps"
                         @change="chooseBot(item,$event,index)" filterable>
              <template slot-scope="{ node, data }">
                <span @click="clickBot(data)">{{ data.name }}</span>
              </template>
            </el-cascader>
          </div>
          <!-- 新版本选择咨询范围---增加了条件 -->
          <div class="right-consultation-scope">
            <div class="right-consultation-scope-top" v-for="(conditionsItem,conditionsIndex) in item.conditions"
                 :key="conditionsIndex">
              <div class="right-consultation-scope-top-content">
                <div class="select-cell-attr select-cell-inner">
                  <el-popover
                      :ref="'condition-popover'+conditionsIndex+index"
                      placement="bottom-start"
                      width="300"
                      v-model="conditionsItem.visible"
                      popper-class="choose-conditions-poprver"
                      :visible-arrow="false">
                    <div
                        :class="['choose-user-attrible one-column-ellipsis']"
                        slot="reference">
                      <span v-if="conditionsItem.type === 'ALL'">{{$t('botLink.allPerson')}}</span>
                      <span v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{$t('botLink.staffOrDept')}}</span>
                      <span v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'label'">{{$t('botLink.label')}}</span>
                      <span
                          v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'personalJobLevel'">{{$t('botLink.personalJobLevel')}}</span>
                    </div>
                    <el-tree
                        style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                        :data="userAttributeOptions"
                        :props="{
                      children: 'children',
                      label: 'label',
                      id:'value',
                      }"
                        default-expand-all
                        node-key="id"
                        @node-click="(data) => onNodeClickCondition(data,conditionsItem,item,conditionsIndex,index)"
                        ref="userAttributeTree">
                  <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']"
                        slot-scope="{node,data}">
                    <span class="custom-tree-node-left">
                      <span class="data-label">{{ $t('botLink.' + data.value) }}</span>
                    </span>
                  </span>
                    </el-tree>
                  </el-popover>
                </div>
                <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL'">
                  <el-select
                      size="small"
                      v-model="conditionsItem.relation"
                      :placeholder="$t('flowComponent.conditionConfigPopup.selectType')"
                      @change="saveSetting">
                    <el-option
                        v-for="item in attrRelations"
                        :key="item.value"
                        :label="$t('common.' + item.value)"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <!-- 弹框选择组织架构 -->
                <div
                    @click="openStaffPopup(item, index,'bindBot',conditionsItem,conditionsIndex)"
                    class="select-cell-attrv select-cell-inner one-column-ellipsis"
                    v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL'">
                  <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
                    <template v-if="conditionsItem.expect && conditionsItem.expect.length > 0">
                  <span class="have-checked-intent" style="cursor: pointer;color:#606266;"
                        v-for="(tagCell, tagCellIndex) in conditionsItem.expect" :key="tagCellIndex">
                    <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'"
                               :openid="tagCell.name" v-if="isOpenData()"></open-data>
                    <span v-else>{{ tagCell.name }}</span>
                    <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                  </span>
                    </template>
                    <div class="placeholder-text" v-else style="cursor: pointer;color:#606266;">{{$t('common.selectPlaceholder')}}</div>
                  </template>
                  <!-- 下拉选择自定义字段的值 -->
                  <el-select
                      class="choose-tag-select"
                      v-else
                      size="small"
                      v-model="conditionsItem.expect"
                      filterable
                      multiple
                      collapse-tags
                      :placeholder="$t('botLink.inputOrSelect')"
                      @change="saveSetting">
                    <el-option
                        v-for="item in tagsOptions"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div :class="['del-ondition',isFwh ? 'ml' : '']">
                <i class="iconfont guoran-tongyichicun-16-09-shanchu2"
                   @click="delCondition(item,conditionsItem,conditionsIndex)"></i>
              </div>
            </div>
            <div class="add-condition-bot-btn">
              <el-button @click="addCondition(item,conditionsItem)" size="small" type="primary"
                         icon="iconfont guoran-tongyichicun-16-13-xinjian"></el-button>
              <span @click="addCondition(item,conditionsItem)">{{$t('botLink.addCondition')}}</span>
            </div>

          </div>
          <div v-if="!isMnDdLimit && isFwh" :class="['del-bind-bot',isFwh ? 'ml' : '']">
            <i class="iconfont guoran-tongyichicun-16-09-shanchu2" @click="delBindBot(item,index,'')"></i>
          </div>
        </div>
        <bind-bot
            v-if="item.plugins"
            :userAttributeOptions="userAttributeOptions"
            :attrRelations="attrRelations"
            :tagsOptions="tagsOptions"
            :bindBotList="item.plugins"
            :appCombineNoBindBots="appCombineNoBindBots"
            :noAskbotBind="noAskbotBind"
            :isChildren="true"
            :orgTree="orgTree"
            @saveSetting="saveSetting"
            @openStaffPopup="openStaffPopup"
        ></bind-bot>
      </div>
    </template>
    <div v-else-if="!isChildren" class="bind-askbot-client-bind-no-data">{{$t('botLink.noBindBot')}}</div>
    <!-- 选择咨询者 -->
    <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
      <div slot="popup-name">{{$t('botLink.dialogMemberTitle')}}</div>
      <div slot="popup-con" class="member-contanier">
        <div class="choose-style" v-if="staffCustomerType === 'knowledge'">
          <div>{{$t('botLink.dialogMemberTip')}}</div>
          <div class="radio-box">
            <el-radio v-model="chooseStyle" label="all">{{$t('botLink.askbotClientDrawer.all')}}</el-radio>
            <el-radio v-model="chooseStyle" label="self">{{$t('botLink.askbotClientDrawer.custom')}}</el-radio>
          </div>
        </div>
        <staffCustomer
            dataType="staff"
            v-if="chooseStyle === 'self'"
            :isMultiple="true"
            @onNodeClick="onNodeClick"
            :memberInfo4Client="staffCustomerType === 'bindBot' ? activeItem.memberInfo4Client : memberInfo4Client"
            ref="staffCustomer"></staffCustomer>
      </div>
      <div slot="dialog-footer">
        <el-button @click="cancelChooseMember" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="preserStaff" class="confirm-btn">{{$t('common.confirm')}}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import OpenData from "../../../components/openData";
import Popup from "../../../components/popup.vue";
import staffCustomer from "@/components/staffCustomer.vue";

export default {
  name: "bindBot",
  components: { staffCustomer, Popup, OpenData },
  data() {
    return {
      optionProps: { disabled: "isChecked", label: "name", value: "id", emitPath: false },
      memberInfo4Client: {
        clientInfo: [] // 选中数据
      },
      dialogMemberTree: false,
      chooseStyle: "",
      staffCustomerType: "bindBot",
      activeItem: {},
      chooseStyleOld: "all",
      activeConditionsIndex: 0,
      activeIndex: -1
    };
  },
  props: {
    userAttributeOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    attrRelations: {
      type: Array,
      default() {
        return [];
      }
    },
    tagsOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    bindBotList: {
      type: Array,
      default() {
        return [];
      }
    },
    appCombineNoBindBots: {
      type: Array,
      default() {
        return [];
      }
    },
    isChildren:{
      type: Boolean,
      default: false
    },
    noAskbotBind:{
      type: Boolean,
      default: false
    },
    orgTree:{
      type:Array,
      default(){
        return []
      }
    }
  },
  methods: {
    delBindBot(item,index) {
      this.$emit('delBindBot',item,index)
    },
    // 选择待选机器人
    handleCheckedBotsChange(value, item, type) {
      console.log(this.bindBotList,this.appCombineNoBindBots);
      console.log(value, item, type)

      let botType = "";
      if(type !== 'del'){

        // 新增 知识机器人
        this.appCombineNoBindBots.forEach(cBots => {
          cBots.children.forEach( v =>{
            if (value == v.id && !v.isChecked) {
              item.apiKey = v.apiKey || "";
              item.name = v.name;
              item.botId = value;
              item.isChecked = true;

              botType = v.botType;

              setTimeout(() => {
                this.checkedBots = [];
              }, 200);
            }
          })
        })
      }

      let ids = []
      this.bindBotList.forEach(item => {
        console.log(item);
        ids.push(item.botId)
        if(item.botId == value){
          item.botType = botType;
        }
      })
      // 新增 知识机器人
      // this.appCombineNoBindBots.forEach(cBots => {
      //   cBots.children.forEach( allItem =>{
      //     if (cBots.id == 1){
      //       if(ids.indexOf(allItem.id) !== -1){
      //         allItem.isChecked = true
      //       } else {
      //         allItem.isChecked = true
      //       }
      //     } else {
      //       if(ids.indexOf(allItem.id) !== -1){
      //         allItem.isChecked = true
      //       } else {
      //         allItem.isChecked = false
      //       }
      //     }
      //
      //   })
      // })
      console.log(this.appCombineNoBindBots);
    },
    chooseBot(item,value, index){
      let ref = 'cascader' + index;
      let getCheckedNodes = this.$refs[ref][0].getCheckedNodes();
      console.log('getCheckedNodes',getCheckedNodes)
      if (getCheckedNodes && getCheckedNodes[0]){
        let data = getCheckedNodes[0].data;
        let plugins = []
        if (data.llmBot && data.llmBot.plugins){
          data.llmBot.plugins.forEach(pluginItem => {
            pluginItem.pluginTools.forEach((pluginTools) => {
              let obj = {
                id: pluginTools._id,
                name: pluginTools.name,
                scope: 0, // 咨询范围 0：所有人 1：指定范围
                parentBotId: data.id,
                memberInfo4Client: {clientInfo: []},
                checkedList:[],
                conditions:[{
                  visible:false,
                  userAttributeTypeName:"所有人",
                  type:'ALL',
                  expect:[],
                  key:"",
                  relation:"CONTAIN_ANY",
                }]
              }
              plugins.push(obj)
            })
          })
          if (!item.plugins){
            this.$set(item,'plugins',[])
          }
          item.plugins = plugins;
        }
        item.name = data.name;
        item.botType = data.botType || 0;
        item.apiKey = data.apiKey || '';
        console.log('item',item,data)
      }
      this.saveSetting();
    },
    clickBot(bot){
      console.log('bot',bot)
      if (bot.isChecked){
        this.$message({
          message:"知识机器人只能选择一个",
          type:"warning"
        })
      }
    },
    saveSetting() {
      this.$emit("saveSetting");
    },
    openStaffPopup(item, index, type, conditionsItem, conditionsIndex) {
      console.log("conditionsIndex", item, index, type, conditionsItem, conditionsIndex);
      this.activeConditionsIndex = conditionsIndex;
      this.activeItem = JSON.parse(JSON.stringify(item));
      this.activeIndex = index;
      this.staffCustomerType = this.noAskbotBind ? 'knowledge' : 'bindBot';
      if (this.staffCustomerType === "bindBot") {
        this.activeItem.checkedList = conditionsItem.expect ? JSON.parse(JSON.stringify(conditionsItem.expect)) : [];
        this.chooseStyle = "self";
        this.activeItem.memberInfo4Client.clientInfo = this.activeItem.conditions[this.activeConditionsIndex].expect;
      } else {
        this.memberInfo4Client.clientInfo = conditionsItem.expect ? JSON.parse(JSON.stringify(conditionsItem.expect)) : [];
        this.chooseStyle = this.memberInfo4Client.clientInfo.length > 0 ? 'self' : 'all'
      }
      this.dialogMemberTree = true;
    },
    delCondition(item,conditionsItem,conditionsIndex){
      item.conditions.splice(conditionsIndex,1);
      this.saveSetting()
    },
    addCondition(item) {
      item.conditions.push({
        visible:false,
        type:'ALL',
        expect:[],
        key:"",
        relation:"CONTAIN_ANY",
      })
      this.saveSetting()
    },
    onNodeClickCondition(data, conditionsItem, item) {
      // console.log(data,conditionsItem,item);
      conditionsItem.visible = false;
      if (data.value === "STAFF") {
        conditionsItem.visible = true;
        return false;
      }
      ;
      conditionsItem.type = data.value;
      conditionsItem.key = "";
      conditionsItem.expect = [];
      if (data.value === "CUSTOMIZE") {
        conditionsItem.key = data.key;
      }
      this.saveSetting();
    },
    onNodeClick(node, type, memberInfo) {
      let obj = JSON.parse(JSON.stringify(node));
      obj.name = obj.label || obj.name;
      let AddObj = {
        id: obj.id,
        name: obj.label,
        type: obj.type,
        isWeWorkThirdData: obj.isWeWorkThirdData
      };
      if (obj.checked) {
        obj.ewechat = obj.staffType === "staff-t" ? true : false;
        if (this.staffCustomerType === "bindBot") {
          if (this.activeItem.checkedList && this.activeItem.checkedList.length > 0) {
            this.activeItem.checkedList.push(AddObj);
          } else {
            this.activeItem.checkedList = [AddObj];
          }
          this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList);
        } else {
          this.memberInfo4Client.clientInfo.push(AddObj);
          this.memberInfo4Client.clientInfo = this.handlerArr(this.memberInfo4Client.clientInfo)
        }
      } else {
        if (this.staffCustomerType === "bindBot") {
          this.activeItem.checkedList.forEach((v, i) => {
            if (obj.id == v.id) {
              this.activeItem.checkedList.splice(i, 1);
            }
          });
          this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList);
        } else {
          this.memberInfo4Client.clientInfo.forEach((v, i) => {
            if (obj.id == v.id) {
              this.memberInfo4Client.clientInfo.splice(i, 1)
            }
          })
          this.memberInfo4Client.clientInfo = this.handlerArr(this.memberInfo4Client.clientInfo)
        }
      }
      this.$refs.staffCustomer.checkMemberList = [];
    },
    handlerArr(tempArr) {
      let result = [];
      let obj = {};
      for (let i = 0; i < tempArr.length; i++) {
        if (!obj[tempArr[i].id]) {
          result.push(tempArr[i]);
          obj[tempArr[i].id] = true;
        }
        ;
      }
      ;
      return result;
    },
    cancelChooseMember() {
      this.chooseStyle = this.chooseStyleOld;
      this.dialogMemberTree = false;
    },
    preserStaff() {
      this.chooseStyleOld = this.chooseStyle;
      if (this.chooseStyle === "all") {
        this.memberInfo4Client.clientInfo = [];
      }
      // if (this.staffCustomerType === "bindBot") {
      //   this.bindBotList.forEach((item, index) => {
      //     if (index === this.activeIndex) {
      //       if (this.chooseStyle === "all") {
      //         item.memberInfo4Client = {
      //           clientInfo: []
      //         };
      //       } else {
      //         item.conditions[this.activeConditionsIndex].expect = this.activeItem.checkedList || [];
      //       }
      //     }
      //   });
      //   // }
      // } else  {
      //   this.memberInfo4Client.clientInfo = JSON.parse(JSON.stringify(this.memberInfo4Client.clientInfo)) || [];
      // }
      this.bindBotList.forEach((item, index) => {
        if (index === this.activeIndex) {
          if (this.chooseStyle === "all") {
            item.memberInfo4Client = {
              clientInfo: []
            };
            item.conditions[this.activeConditionsIndex].expect = [];
            item.conditions[this.activeConditionsIndex].type = 'ALL'
          } else {
            item.conditions[this.activeConditionsIndex].expect = this.staffCustomerType === "bindBot" ? this.activeItem.checkedList : this.memberInfo4Client.clientInfo;
          }
        }
      });
      this.dialogMemberTree = false;
      this.saveSetting();
    },
    //部门是否使用通讯录组件
    isOpenData(value){
      /* const res = /^\d+$/;
       console.debug('value',res.test(value))*/
      let bool = false
      if (this.orgTree.length > 1){
        if (this.orgTree[0].originData&&
            this.orgTree[0].originData.wechatBotDTO&&
            this.orgTree[0].originData.wechatBotDTO.bindType===0&&
            this.orgTree[0].originData.wechatBotDTO.wechatType===0){
          bool = true
        }
        else if (this.orgTree[1].originData&&
            this.orgTree[1].originData.wechatBotDTO&&
            this.orgTree[1].originData.wechatBotDTO.bindType===0&&
            this.orgTree[1].originData.wechatBotDTO.wechatType===0){
          bool = true
        }
      }
      else if (this.orgTree.length===1){
        if (this.orgTree[0].originData&&
            this.orgTree[0].originData.wechatBotDTO&&
            this.orgTree[0].originData.wechatBotDTO.bindType===0&&
            this.orgTree[0].originData.wechatBotDTO.wechatType===0){
          bool = true
        }
      }
      return bool
    },
  },
  mounted() {

  },
  watch: {
    bindBotList: {
      handler(value) {
        if (value && value.length && !this.isChildren){
          let ids = value.map(res => {
            return res.botId
          })
          this.appCombineNoBindBots.forEach(cBots => {
            if (cBots.id == 1){
              let childrenId = cBots.children.map(bots => {
                return bots.id
              })
              let bool = false;
              for (const item of ids) {
                let index = childrenId.findIndex(i => i === item)
                if (index !== -1){
                  bool = true;
                }
              }
              if (bool){
                cBots.children = cBots.children.map(bots => {
                  bots.isChecked = true
                  return bots
                })
              } else {
                cBots.children = cBots.children.map(bots => {
                  bots.isChecked = false
                  return bots
                })
              }
            } else {
              cBots.children.forEach( allItem =>{
                if(ids.indexOf(allItem.id) !== -1){
                  allItem.isChecked = true
                } else {
                  allItem.isChecked = false
                }
              })
            }
          })
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="less">
.bind_bot {
  .bind-askbot-children-content{
    align-items: center;
    position: relative;
    display: flex;
    .choose-bind-bot{
      width: 212px!important;
      margin-left: 36px;
      padding: 0 15px;
      min-height: 38px;
      height: 100%;
      border: 1px solid #DCDFE6;
      border-radius: 5px;
      display: flex;
      align-items: center;
      .choose-bind-bot-plugintool-name{
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  // 竖线
  .bind-askbot-children-content::before {
    content: "";
    height: calc(100% + 10px);
    width: 1px;
    position: absolute;
    left: 30px;
    top: -10px;
    border-width: 1px;
    border-left: 1px dashed #366aff;
  }

  // 当前层最后一个节点的竖线高度固定
  .bind-askbot-children-content:last-child::before {
    height: calc(50% + 10px); // 可以自己调节到合适数值
  }

  // 横线
  .bind-askbot-children-content::after {
    content: "";
    width: 33px;
    height: calc(50% + 10px);
    position: absolute;
    left: 30px;
    top: 50%;
    border-width: 1px;
    border-top: 1px dashed #366aff;
  }
  .bind-askbot-client-bind-bot-item{
    margin-bottom: 10px;
    .bind-askbot-client-bind-bot-item-content {
      display: flex;
      align-items: stretch;
      margin-bottom: 10px;
      width: 100%;
    }
    &.condition-bind-askbot-client-bind-bot{
      align-items: stretch;
    }
    .choose-bind-bot{
      margin-right: 12px;
      width: 280px;
      flex: none;
      &.choose-bind-bot-min{
        // flex: 0.5;
        width: 280px;
      }
      &.choose-bind-bot-max{
        flex: 1;
      }
      .el-select{
        width: 100%;
        height: 100%;
        .el-input,.el-input__inner{
          height: 100%;
          width: 100%;
          min-height: 38px;
        }
      }
      /deep/.el-cascader{
        width: 100%;
        height: 100%;
        .el-input,.el-input__inner{
          height: 100%;
          width: 100%;
          min-height: 38px;
        }
      }
    }
    .del-bind-bot{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;
      &.ml{
        margin-right: 0px;
        margin-left: 10px;
      }
      .guoran-tongyichicun-16-09-shanchu2{
        cursor: pointer;
        color: #366AFF;
      }
    }
  }
  .right-consultation-scope {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    .right-consultation-scope-top {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 6px;

      .right-consultation-scope-top-content {
        min-height: 36px;
        border: 1px solid #DCDFE6;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex: 1;
      }

      .del-ondition {
        margin-left: 10px;
        color: #366AFF;
        cursor: pointer;
      }

      .select-cell-et {
        flex: none;
        width: 200px;
      }

      .select-cell-eteq {
        flex: none;
        width: 100px;
      }

      .select-cell-etv {
        flex: auto;
      }

      .select-cell-attr {
        flex: none;
        width: 110px;
        display: flex;
        align-items: center;

        .choose-user-attrible {
          text-align: left;
          width: 100%;
          height: 100%;
          cursor: pointer;
          padding: 0 15px;
          color: #606266;

          &.empty-user-attrible {
            color: #d5d5d5;
          }
        }
      }

      .select-cell-attreq {
        flex: none;
        width: 100px;
        display: flex;
        align-items: center;

        .el-input {
          .el-input__inner {
            border: none;
            padding: 0 10px;
          }

          .el-select__caret {
            display: none;
          }
        }
      }

      .select-cell-attrv {
        flex: auto;
        // display: flex;
        // align-items: center;
        // padding: 6px;
        // width: 100%;
        line-height: 36px;

        .attr-value {
          display: block;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        .select-cell-attrv-placeholder {
          color: #c0c4cc;
          cursor: pointer;
        }

        .choose-tag-select {
          .el-input__inner {
            border: none;
          }
        }
      }
    }

    .add-condition-bot-btn {
      display: flex;
      align-items: center;

      .el-button {
        width: 30px;
        height: 30px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        padding: 5px;

        i {
          font-size: 12px;
        }
      }

      span {
        cursor: pointer;
        color: #366AFF;
      }
    }


  }

  /deep/ #popup-assembly {
    width: 800px;
  }

}
</style>