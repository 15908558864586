<template>
    <div class="app-auth-config">
        <div class="aac-title">
            <div class="authority-set-top">
                <div class="evaluate-table-switch">
                    <el-switch @click.native.prevent="changeEnableApply('switch')" :width="42" v-model="enableApply"
                        active-color="#366AFF" inactive-color="#E2E2E2">>
                    </el-switch>
                    <span class="switch-open-icon" @click="changeEnableApply('open')" v-if="enableApply">
                        <i class="iconfont guoran-a-16-17"></i>
                    </span>
                    <span class="switch-close-icon" @click="changeEnableApply('close')" v-if="!enableApply">
                        <i class="arsenal_icon arsenalcuo1"></i>
                    </span>
                </div>
                <span class="bind-askbot-client-tips-text">企业知识智能搜索</span>
                <el-button @click="configAction" size="small" type="primary" round icon="el-icon-setting">管理动作</el-button>
            </div>
        </div>
        <div class="aac-header">
            <span class="aac-header-cell aac-header-cell-m-r">应用动作</span>
            <span class="aac-header-cell">可使用范围</span>
        </div>
        <div class="aac-content" v-loading="loading">
            <div v-for="(appCell, appCellIndex) in appList" :key="appCell.id" class="aac-content-app">
                <div class="aac-content-app-header">
                    <span class="handle-visible" @click="changeActionVisible(appCellIndex, !appCell.showActionList)">
                        <span v-show="appCell.showActionList" class="handle-visible-icon el-icon-caret-bottom"></span>
                        <span v-show="!appCell.showActionList" class="el-icon-caret-right"></span>
                    </span>
                    <img v-show="appCell.logo != null && appCell.logo != ''" class="acah-logo" :src="appCell.logo"
                        alt="暂无应用LOGO" srcset="">
                    <span class="acah-name">{{ appCell.name }}</span>
                </div>
                <div class="aac-content-app-cell" v-for="(item, itemIndex) in appCell.actions" :key="itemIndex">
                    <span :class="[itemIndex == appCell.actions.length - 1 ? 'pre-line-h-50' : 'pre-line-h-100']">
                        <span class="pre-line-h"></span>
                    </span>
                    <span :style="{ 'border': itemIndex == appCell.actions.length - 1 && 'none' }" class="acac-name">{{
                        item.name }}</span>
                    <div :style="{ 'border': itemIndex == appCell.actions.length - 1 && 'none' }" class="acac-range">
                        <div class="permissions-select">
                            <div style="margin-top: 4px;" class="select-input">
                                <template v-if="permissionsObj.permissionsList.length">
                                    <div class="permission-list">
                                        <div v-for="(item, index) in permissionsObj.permissionsList" :key="index"
                                            class="permissionItem">
                                            <template v-if="item.type === 1">
                                                <div class="departAvatar">
                                                    <i class="iconfont guoran-chanpin"></i>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="avatar" v-if="item.name">
                                                    {{ (item.name).substr(0, 1) }}
                                                </div>
                                            </template>
                                            <span class="label aa" v-if="item.name">
                                                <open-data :openid="item.name"
                                                    :type="item.type == 1 ? 'departmentName' : 'userName'"
                                                    v-if="isUseOpenData"></open-data>
                                                <template v-else>
                                                    {{ item.name }}
                                                </template>
                                            </span>
                                            <!-- <span class="close" @click.stop="closePermission(index)">
                                                    <i class="iconfont guoran-shanchu"></i>
                                                </span> -->
                                        </div>
                                    </div>
                                </template>
                                <span v-else class="placeholder">未配置</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="appCell.actions.length == 0 && appCell.showActionList" class="aac-content-app-cell-empty">
                    当前应用下无动作</div>
            </div>
        </div>
        <popup @closeEvent="dialogConfigAuth = false" v-if="dialogConfigAuth" class="delbot-relation-popup"
            :haveTips="true">
            <div slot="popup-name">管理动作</div>
            <div slot="popup-tip">配置可使用动作及可咨询范围</div>
            <div slot="popup-con" class="member-contanier aac-config-contaner">
                <div class="aac-search">
                    <el-input v-model="keyword" @keyup.enter.native="searchAction" placeholder="请输入后搜索动作">
                        <el-button @click="searchAction" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="aac-header">
                    <span class="aac-header-cell-left">应用动作</span>
                    <span class="aac-header-cell-right">可使用范围</span>
                </div>
                <div class="aac-content">
                    <div class="aac-content-app" v-for="(appCell, appCellIndex) in appList" :key="appCell.id">
                        <div class="aac-content-app-header">
                            <span class="handle-visible"
                                @click="changeActionVisible(appCellIndex, !appCell.showActionList)">
                                <span v-show="appCell.showActionList"
                                    class="handle-visible-icon el-icon-caret-bottom"></span>
                                <span v-show="!appCell.showActionList" class="el-icon-caret-right"></span>
                            </span>
                            <img v-show="appCell.logo != null && appCell.logo != ''" class="acah-logo" :src="appCell.logo"
                                alt="暂无应用LOGO" srcset="">
                            <span class="acah-name">{{ appCell.name }}</span>
                        </div>
                        <div class="aac-content-app-cell-pop" v-for="(item, itemIndex) in appCell.actions" :key="itemIndex"
                            :style="{ 'border': itemIndex == appCell.actions.length - 1 && 'none' }">
                            <span :class="[itemIndex == appCell.actions.length - 1 ? 'pre-line-h-50' : 'pre-line-h-100']">
                                <span class="pre-line-h"></span>
                            </span>
                            <span :style="{ 'border': itemIndex == appCell.actions.length - 1 && 'none' }"
                                class="acac-name">{{ item.name }}</span>
                            <div :style="{ 'border': itemIndex == appCell.actions.length - 1 && 'none' }"
                                class="acac-range">
                                <div @click="openStaffPopup(item, appCellIndex, itemIndex)" class="permissions-select">
                                    <div class="select-input">
                                        <template v-if="permissionsObj.permissionsList.length">
                                            <div class="permission-list">
                                                <div v-for="(item, index) in permissionsObj.permissionsList" :key="index"
                                                    class="permissionItem">
                                                    <template v-if="item.type === 1">
                                                        <div class="departAvatar">
                                                            <i class="iconfont guoran-chanpin"></i>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="avatar" v-if="item.name">
                                                            {{ (item.name).substr(0, 1) }}
                                                        </div>
                                                    </template>
                                                    <span class="label aa" v-if="item.name">
                                                        <open-data :openid="item.name"
                                                            :type="item.type == 1 ? 'departmentName' : 'userName'"
                                                            v-if="isUseOpenData"></open-data>
                                                        <template v-else>
                                                            {{ item.name }}
                                                        </template>
                                                    </span>
                                                    <span class="close" @click.stop="closePermission(index)">
                                                        <i class="iconfont guoran-shanchu"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <span v-else class="placeholder">未配置</span>
                                    </div>
                                </div>
                                <div class="acac-range-handle">
                                    <el-tooltip class="item" effect="dark" content="使用到全部" placement="top-start">
                                        <i class="iconfont guoran-quanbu1"
                                            @click.stop="useAll('action', appCellIndex, itemIndex)"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="粘贴权限" placement="top-start">
                                        <i class="iconfont guoran-niantie"
                                            @click.stop="paste('action', appCellIndex, itemIndex)"></i>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="复制权限" placement="top-start">
                                        <i class="iconfont guoran-fuzhi"
                                            @click.stop="duplicateData('action', appCellIndex, itemIndex)"></i>
                                    </el-tooltip>
                                </div>
                            </div>
                            <!-- <div class="acac-operation">
                                <i class="iconfont guoran-quxiao" @click.stop="deleteAction(item)"></i>
                            </div> -->
                        </div>
                        <div v-show="appCell.actions.length == 0 && appCell.showActionList"
                            class="aac-content-app-cell-empty">
                            当前应用下无动作</div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button style="width: 100px" @click="dialogConfigAuth = false" plain size="small">取 消</el-button>
                <el-button style="width: 100px" type="primary" @click="dialogConfigAuthSubmit" size="small">确 定</el-button>
            </div>
        </popup>
        <!-- 选择咨询者 -->
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
            <div slot="popup-name">选择咨询者</div>
            <div slot="popup-con" class="member-contanier">
                <div class="choose-style">
                    <div>选择咨询者类型</div>
                    <div class="radio-box">
                        <el-radio v-model="chooseStyle" :label="0">全部</el-radio>
                        <el-radio v-model="chooseStyle" :label="1">自定义</el-radio>
                    </div>
                </div>
                <staffCustomer dataType="staff" v-if="chooseStyle == 1" :isMultiple="true" @onNodeClick="onNodeClick"
                    :memberInfo4Client="activeItem.memberInfo4Client" ref="staffCustomer"></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelChooseMember" plain class="cancel-btn">取 消</el-button>
                <el-button type="primary" @click="preserStaff" class="confirm-btn">确定</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import popup from "../../../components/popupNew.vue";
import staffCustomer from '../../../components/staffCustomer';
import OpenData from "@/components/openData";
export default {
    props: ["isUseOpenData"],
    components: {
        popup,
        staffCustomer,
        OpenData
    },
    data() {
        return {
            appCell: {
                memberInfo4Client: {
                    clientInfo: []
                }
            },
            enableApply: true,
            permissionsObj: {
                permissionsList: []
            },
            keyTableData: [],
            appList: [],
            configActionAuth: [],
            dialogConfigAuth: false,
            keyword: '',

            dialogMemberTree: false,
            chooseStyle: 0,
            chooseStyleOld: 0,
            memberInfo4Client: {
                clientInfo: [], // 选中数据
            },
            activeItem: {},
            duplicate: '',
            loading: true,
        }
    },
    methods: {
        deleteAction() { },
        dialogConfigAuthSubmit() { },
        useAll(type, appCellIndex, itemIndex) {
            if (type == 'action') {
                console.log(appCellIndex, this.appList[appCellIndex].actions[itemIndex].checkedList);
            }
        },
        paste(type, appCellIndex, itemIndex) {
            if (type == 'action') {
                console.log(this.duplicate);
                if (this.duplicate != undefined) {
                    if (Object.keys(this.duplicate).length == 0) {
                        this.$message({
                            message: "请先复制内容",
                            type: "warning",
                            duration: 2000
                        })
                        return
                    }
                } else {
                    this.$message({
                        message: "请先复制内容",
                        type: "warning",
                        duration: 2000
                    })
                    return
                }

                console.log(appCellIndex, this.appList[appCellIndex].actions[itemIndex].checkedList);
            }
        },
        duplicateData(type, appCellIndex, itemIndex) {
            console.log(appCellIndex, this.appList);
            if (type == 'action') {
                console.log(appCellIndex, this.appList[appCellIndex].actions[itemIndex].checkedList);
                this.duplicate = this.appList[appCellIndex].actions[itemIndex].checkedList;
                this.$message.success("权限复制成功");
            }
        },

        searchAction() {
            console.log(this.keyword);
        },

        changeActionVisible(index, val) {
            if (val) {
                this.$http.get(this.requestUrl.appStore.getConfig + this.appList[index].id).then(res => {
                    if (res.status === 200) {
                        if (res.data === "") {
                            this.appList[index].actions = [];
                        } else {
                            let actionList = res.data.params;
                            actionList.forEach(item => {
                                item.memberInfo4Client = {
                                    clientInfo: []
                                }
                                item.scope = 0;
                            })
                            this.appList[index].actions = actionList;
                        }
                        this.appList[index].showActionList = val;
                    }
                })
            } else {
                this.appList[index].actions = [];
                this.appList[index].showActionList = val;
            }
        },

        getActionList() {

        },

        configAction() {
            this.dialogConfigAuth = true;
        },
        changeEnableApply(value) {
            if (value === 'open') {
                this.enableApply = false;
            } else if (value === 'close') {
                this.enableApply = true;
            }
        },

        openStaffPopup(item, appCellIndex, itemIndex) {
            console.log(item, 'item');
            this.activeItem = JSON.parse(JSON.stringify(item));
            this.activeAppCellIndex = appCellIndex;
            this.activeIndex = itemIndex;
            this.activeItem.checkedList = JSON.parse(JSON.stringify(item.memberInfo4Client.clientInfo));
            this.chooseStyle = item.memberInfo4Client.clientInfo.length > 0 ? 1 : 0;
            this.dialogMemberTree = true;

        },
        onNodeClick(node) {
            console.log(node);
            if (node.checked) {
                node.ewechat = node.staffType === 'staff-t' ? true : false;
                if (this.activeItem.checkedList && this.activeItem.checkedList.length > 0) {
                    this.activeItem.checkedList.push(node);
                } else {
                    this.activeItem.checkedList = [node];
                }
                this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList)
            } else {
                this.activeItem.checkedList.forEach((v, i) => {
                    if (node.id == v.id) {
                        this.activeItem.checkedList.splice(i, 1)
                    }
                })
                this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList)
            }
            this.$refs.staffCustomer.checkMemberList = []
            console.log(this.activeItem.checkedList, ' this.memberInfo4Client.clientInfo点击');
            // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        cancelChooseMember() {
            this.chooseStyle = this.chooseStyleOld;
            this.dialogMemberTree = false;
        },
        preserStaff() {
            console.log(this.activeItem, 'activeItem');
            this.chooseStyleOld = this.chooseStyle;
            this.activeItem.scope = this.chooseStyle;
            if (this.chooseStyle == 0) {
                this.activeItem.checkedList = [];
            }
            console.log(this.activeItem, this.activeAppCellIndex, this.activeIndex);
            this.dialogMemberTree = false;
            // this.saveSetting();
        },
        getAppList() {
            let param = {
                page: 0,
                size: 200,
                sortByProperty: 'updateTime',
                direction: 'DESC',
                keyWord: '',
            }
            this.loading = true;
            this.FetchGet(this.requestUrl.appStore.getAppList, param).then((res) => {
                let tamparr = res.content;
                tamparr.forEach(item => {
                    item.actions = [];
                    item.showActionList = false;
                })
                this.appList = tamparr;
                this.loading = false;
            });
        },
    },
    mounted() {
        this.getAppList();
    },
}
</script>
<style lang="less">
.app-auth-config {
    margin: 0 16px;
    height: calc(100vh - 100px);
    overflow-y: auto;

    .aac-title {
        .authority-set-top {
            display: flex;
            align-items: center;

            /deep/ .evaluate-table-switch {
                display: flex;
                flex-direction: row;
                height: 25px;
                justify-content: space-between;
                cursor: pointer;
                position: relative;
                text-align: center;
                align-items: center;

                .el-switch {
                    text-align: center;

                }

                .switch-open-icon {
                    position: absolute;
                    // z-index: 999;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 3px;

                    i {
                        color: white;
                        font-size: 16px;
                    }
                }

                .switch-close-icon {
                    position: absolute;
                    // z-index: 999;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 21px;

                    i {
                        color: #6E6B7B;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                i {
                    color: #366AFF;
                    font-size: 18px;
                }

                .el-switch__core::after {
                    width: 14px;
                    height: 14px;
                    top: 2px;
                    left: 2px;
                }

                .is-checked .el-switch__core::after {
                    left: 40px;
                }

                &.disabled {

                    i,
                    .el-switch__input,
                    .el-switch__core {
                        cursor: no-drop;
                    }
                }
            }

            .bind-askbot-client-tips-text {
                margin: 0 8px;
            }
        }
    }

    .aac-header {
        display: flex;
        text-align: left;
        margin: 16px 0;

        .aac-header-cell {
            flex: 1;
            height: 38px;
            line-height: 38px;
            background: #F5F7FB;
            border-radius: 5px;
            padding: 0 12px;
        }

        .aac-header-cell-m-r {
            margin-right: 10px;
        }

    }

    .aac-content {
        min-height: 200px;
        .aac-content-app {
            margin-bottom: 8px;

            .aac-content-app-header {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 40px;
                background: #ECF1FF;
                border-radius: 5px;

                .handle-visible {
                    flex: none;
                    width: 30px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;

                    .handle-visible-icon {
                        color: #366AFF;
                        font-size: 18px;
                    }
                }

                .acah-logo {
                    height: 26px;
                    width: 26px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 6px;
                }
            }

            .aac-content-app-cell-empty {
                height: 36px;
                line-height: 36px;
                margin-top: 8px;
                text-align: center;
                color: #dcdfe6;
                font-size: 13px;
            }

            .aac-content-app-cell {
                display: flex;
                justify-content: flex-start;
                height: 42px;

                .pre-line-h-100 {
                    height: 100%;
                    width: 20px;
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    border-left: 1px dashed #366AFF;

                    .pre-line-h {
                        flex: auto;
                        height: 2px;
                        border-top: 1px dashed #366AFF;
                    }
                }

                .pre-line-h-50 {
                    height: 50%;
                    width: 20px;
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    border-left: 1px dashed #366AFF;
                    border-bottom: 1px dashed #366AFF;

                    .pre-line-h {
                        display: none;
                    }
                }

                .acac-name {
                    flex: auto;
                    height: 42px;
                    line-height: 42px;
                    border-bottom: 1px solid #E8EDF7;
                    padding: 0 10px;
                    text-align: left;
                }

                .acac-range {
                    flex: none;
                    width: 50%;
                    height: 100%;
                    line-height: 100%;
                    border-bottom: 1px solid #E8EDF7;
                }
            }
        }
    }

    .aac-config-contaner {
        .aac-title {
            .authority-set-top {
                display: flex;
                align-items: center;

                /deep/ .evaluate-table-switch {
                    display: flex;
                    flex-direction: row;
                    height: 25px;
                    justify-content: space-between;
                    cursor: pointer;
                    position: relative;
                    text-align: center;
                    align-items: center;

                    .el-switch {
                        text-align: center;

                    }

                    .switch-open-icon {
                        position: absolute;
                        // z-index: 999;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 3px;

                        i {
                            color: white;
                            font-size: 16px;
                        }
                    }

                    .switch-close-icon {
                        position: absolute;
                        // z-index: 999;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 21px;

                        i {
                            color: #6E6B7B;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    i {
                        color: #366AFF;
                        font-size: 18px;
                    }

                    .el-switch__core::after {
                        width: 14px;
                        height: 14px;
                        top: 2px;
                        left: 2px;
                    }

                    .is-checked .el-switch__core::after {
                        left: 40px;
                    }

                    &.disabled {

                        i,
                        .el-switch__input,
                        .el-switch__core {
                            cursor: no-drop;
                        }
                    }
                }

                .bind-askbot-client-tips-text {
                    margin: 0 8px;
                }
            }
        }

        .aac-header {
            display: flex;
            text-align: left;
            margin: 10px 0;

            .aac-header-cell-left {
                flex: none;
                width: calc(50% - 50px);
                height: 38px;
                line-height: 38px;
                background: #F5F7FB;
                border-radius: 5px;
                padding: 0 12px;
                margin-right: 10px;
            }

            .aac-header-cell-right {
                flex: none;
                width: calc(50% - 8px);
                height: 38px;
                line-height: 38px;
                background: #F5F7FB;
                border-radius: 5px;
                padding: 0 12px;
            }
        }

        .aac-content {
            .aac-content-app {
                .aac-content-app-header:hover {
                    .acah-oper {
                        .acac-range-handle {
                            display: flex;
                        }
                    }
                }

                .aac-content-app-header {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    border-radius: 5px;
                    background: #ECF1FF;

                    .acah-pre {
                        flex: 1;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 40px;

                        .handle-visible {
                            flex: none;
                            width: 30px;
                            text-align: center;
                            cursor: pointer;

                            .handle-visible-icon {
                                color: #366AFF;
                                font-size: 18px;
                            }
                        }

                        .acah-logo {
                            height: 26px;
                            width: 26px;
                            border-radius: 50%;
                            overflow: hidden;
                            margin-right: 6px;
                        }
                    }

                    .acah-oper {
                        flex: 1;
                        flex: none;
                        width: 50%;
                        height: 100%;
                        line-height: 100%;
                        display: flex;
                        align-items: center;
                        margin-right: 30px;

                        .permissions-select {
                            flex: auto;
                            cursor: pointer;
                        }

                        .acac-range-handle {
                            flex: none;
                            display: none;
                            width: 60px;
                            align-items: center;
                            justify-content: space-between;
                            margin-right: 10px;

                            i {
                                color: #366aff;
                                cursor: pointer;
                                margin-left: 6px;
                            }
                        }
                    }

                }

                .aac-content-app-cell-pop:hover {
                    .acac-range {
                        border-radius: 4px;
                        border: 1px solid #E8EDF7 !important;

                        .acac-range-handle {
                            display: block;
                        }
                    }
                }

                .aac-content-app-cell-pop {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 42px;
                    margin-left: 50px;
                    border-bottom: 1px solid #E8EDF7;

                    .pre-line-h-100 {
                        height: 100%;
                        width: 20px;
                        margin-left: -35px;
                        display: flex;
                        align-items: center;
                        border-left: 1px dashed #366AFF;

                        .pre-line-h {
                            flex: auto;
                            height: 2px;
                            border-top: 1px dashed #366AFF;
                        }
                    }

                    .pre-line-h-50 {
                        height: 50%;
                        width: 20px;
                        margin-left: -35px;
                        margin-top: -15px;
                        display: flex;
                        align-items: center;
                        border-left: 1px dashed #366AFF;
                        border-bottom: 1px dashed #366AFF;

                        .pre-line-h {
                            display: none;
                        }
                    }

                    .acac-name {
                        flex: auto;
                        height: 42px;
                        line-height: 42px;
                        padding: 0 10px;
                        text-align: left;
                    }

                    .acac-range {
                        flex: none;
                        width: calc(50% + 40px);
                        height: 30px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        border: none;

                        .permissions-select {
                            flex: auto;
                            cursor: pointer;
                        }

                        .acac-range-handle {
                            flex: none;
                            display: none;
                            width: 60px;
                            align-items: center;
                            justify-content: space-between;
                            margin-right: 10px;

                            i {
                                color: #366aff;
                                cursor: pointer;
                                margin-left: 6px;
                            }
                        }
                    }

                    .acac-operation {
                        width: 30px;
                        height: 42px;
                        line-height: 42px;
                        text-align: center;
                        color: #366AFF;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .permissions-select {
        background-image: none;
        // border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0);
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        padding: 0 15px;
        outline: 0;
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        width: 100%;

        .select-input {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .permission-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }

            .placeholder {
                color: #dcdfe6;
                line-height: 36px;
            }

            /deep/.el-tag {
                margin-right: 8px;
            }

            .permissionItem {
                display: flex;
                align-items: center;
                margin: 4px 8px 4px 0;
                background-color: #E8EDF7;
                border-radius: 3px;
                padding: 0px 4px;
                font-size: 12px;
                color: #010101;
                height: 26px;

                .avatar {
                    flex: none;
                    width: 21px;
                    height: 21px;
                    background: #366AFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFFFFF;
                    font-size: 12px;
                }

                .departAvatar {
                    flex: none;
                    width: 21px;
                    height: 21px;
                    background: #918EFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;

                    i {
                        color: #FFFFFF;
                        font-size: 12px;
                    }
                }

                .label {
                    padding: 0px 4px;
                    max-width: 70px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-top: 0;
                    height: 26px;
                    line-height: 26px;
                }

                iframe {
                    height: 22px;
                }
            }
        }
    }
}
</style>